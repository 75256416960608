import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

//Account Redux states
import { registerUserFailed, registerUserSuccessful } from './actions';
import { REGISTER_USER } from './actionTypes';

//Include Both Helper File with needed methods

import { registerApi } from '../../../services/auth/registerApi';

// initialize relavant method of both Auth

// Is user register successful then direct plot user in redux.
function* registerUser({ payload: user }) {
	try {
		const response = yield call(registerApi, user);
		if (response && response.success) {
			yield put(registerUserSuccessful(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(registerUserFailed(error));
	}
}

export function* watchUserRegister() {
	yield takeEvery(REGISTER_USER, registerUser);
}

function* registrationSaga() {
	yield all([fork(watchUserRegister)]);
}

export default registrationSaga;
