import {
	EDIT_PAYMENT,
	PAYMENT_ERROR,
	PAYMENT_REQUEST,
	PAYMENT_SUCCESS,
	RESET_PAYMENT_FLAG,
} from './actionTypes';

const initialState = {
	loading: null,
	error: null,
	successData: null,
};

const Billing = (state = initialState, action) => {
	switch (action.type) {
		case PAYMENT_REQUEST:
			state = {
				...state,
				loading: true,
			};
			break;
		case PAYMENT_SUCCESS:
			state = {
				...state,
				loading: false,
				successData: action.payload,
			};
			break;
		case PAYMENT_ERROR:
			state = { ...state, loading: false, error: action.payload };
			break;
		case EDIT_PAYMENT:
			state = { ...state };
			break;
		case RESET_PAYMENT_FLAG:
			state = { ...initialState };
			break;

		default:
			state = { ...state };
			break;
	}
	return state;
};

export default Billing;
