export const VIEW_ALL_ASSESSMENT_PUBLIC_REQUEST =
  "VIEW_ALL_ASSESSMENT_PUBLIC_REQUEST";
export const VIEW_ALL_ASSESSMENT_PUBLIC_SUCCESS =
  "VIEW_ALL_ASSESSMENT_PUBLIC_SUCCESS";
export const VIEW_ALL_ASSESSMENT_PUBLIC_ERROR =
  "VIEW_ALL_ASSESSMENT_PUBLIC_ERROR";
export const VIEW_ALL_ASSESSMENT_PUBLIC_RESET =
  "VIEW_ALL_ASSESSMENT_PUBLIC_RESET";

export const ASSESSMENT_ALREADY_DONE_REQUEST =
  "ASSESSMENT_ALREADY_DONE_REQUEST";
export const ASSESSMENT_ALREADY_DONE_SUCCESS =
  "ASSESSMENT_ALREADY_DONE_SUCCESS";
export const ASSESSMENT_ALREADY_DONE_ERROR = "ASSESSMENT_ALREADY_DONE_ERROR";
export const ASSESSMENT_ALREADY_DONE_RESET = "ASSESSMENT_ALREADY_DONE_RESET";
