import { call, put, takeEvery } from "redux-saga/effects";
import { shareSingleQuizApi } from "../../../../services/quiz/view-quiz/viewQuizApi";
import { shareSingleSurveyApi } from "../../../../services/surveys/view-survey/viewSurveyApi";
import { initiateAssessmentSuccess } from "../default/actions";

// Login Redux States

import {
  shareSingleAssessmentError,
  shareSingleAssessmentSuccess,
} from "./actions";

import { SHARE_SINGLE_SURVEY_REQUEST } from "./actionTypes";

function* shareSurvey({ payload: surveyID }) {
  try {
    const response = surveyID.isQuiz
      ? yield call(shareSingleQuizApi, surveyID)
      : yield call(shareSingleSurveyApi, surveyID);
    if (response && response.success) {
      let {
        survey_questionnaire,
        quiz_questionnaire,
        pre_quiz_questionnaire,
        pre_survey_questionnaire,
        ...rest
      } = response.data;

      yield put(
        initiateAssessmentSuccess({
          ...rest,
          survey_questionnaire: survey_questionnaire || quiz_questionnaire,
          pre_survey_questionnaire:
            pre_survey_questionnaire || pre_quiz_questionnaire,
        })
      );
      yield put(shareSingleAssessmentSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(shareSingleAssessmentError(error));
  }
}

function* shareSingleAssessmentSaga() {
  yield takeEvery(SHARE_SINGLE_SURVEY_REQUEST, shareSurvey);
}

export default shareSingleAssessmentSaga;
