import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import {
  createNodeApi,
  deleteNodeApi,
  editNodeApi,
  getFamilyTreeApi,
} from "../../services/family-tree/familyTreeApi.js";
import {
  addNewNodeError,
  addNewNodeSuccess,
  deleteNodeError,
  deleteNodeSuccess,
  editNodeError,
  editNodeSuccess,
  getFamilyTreeError,
  getFamilyTreeSuccess,
} from "./actions";
import {
  ADD_NEW_NODE_REQUEST,
  DELETE_NODE_REQUEST,
  EDIT_NODE_REQUEST,
  GET_FAMILY_TREE_REQUEST,
} from "./actionTypes";

//Include Both Helper File with needed methods

//If role is send successfully send mail link then dispatch redux action's are directly from here.
function* addNewNode({ payload: node }) {
  try {
    const response = yield call(createNodeApi, node);
    if (response && response.success) {
      yield put(addNewNodeSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(addNewNodeError(error));
  }
}
function* getFamilyTree({ payload }) {
  try {
    const response = yield call(getFamilyTreeApi, payload);
    if (response && response.success) {
      yield put(getFamilyTreeSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(getFamilyTreeError(error));
  }
}
function* editSingleNode({ payload: { node } }) {
  try {
    const response = yield call(editNodeApi, node);
    if (response && response.success) {
      yield put(editNodeSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(editNodeError(error));
  }
}

function* deleteSingleNode({ payload: node }) {
  try {
    const response = yield call(deleteNodeApi, node);
    if (response && response.success) {
      yield put(deleteNodeSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(deleteNodeError(error));
  }
}

function* familyTreeSaga() {
  yield takeEvery(ADD_NEW_NODE_REQUEST, addNewNode);
  yield takeEvery(GET_FAMILY_TREE_REQUEST, getFamilyTree);
  yield takeEvery(EDIT_NODE_REQUEST, editSingleNode);
  yield takeEvery(DELETE_NODE_REQUEST, deleteSingleNode);
}

export default familyTreeSaga;
