import {
	GET_INFO_CATEGORIES_ERROR,
	GET_INFO_CATEGORIES_REQUEST,
	GET_INFO_CATEGORIES_RESET,
	GET_INFO_CATEGORIES_SUCCESS,
	SEARCH_INFO_CATEGORIES_ERROR,
	SEARCH_INFO_CATEGORIES_REQUEST,
	SEARCH_INFO_CATEGORIES_RESET,
	SEARCH_INFO_CATEGORIES_SUCCESS,
} from './actionTypes';

const initialStateGetInfo = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateSearchInfo = {
	successData: null,
	error: null,
	loading: null,
};

const getInfoCategories = (state = initialStateGetInfo, action) => {
	switch (action.type) {
		case GET_INFO_CATEGORIES_REQUEST:
			state = {
				successData: null,
				error: null,
				loading: true,
			};
			break;
		case GET_INFO_CATEGORIES_SUCCESS:
			state = {
				...state,
				loading: false,
				successData: action.payload,
			};
			break;
		case GET_INFO_CATEGORIES_ERROR:
			state = {
				...state,
				loading: false,
				error: action.payload,
			};
			break;

		case GET_INFO_CATEGORIES_RESET:
			state = initialStateGetInfo;
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const searchInfoCategories = (state = initialStateSearchInfo, action) => {
	switch (action.type) {
		case SEARCH_INFO_CATEGORIES_REQUEST:
			state = {
				successData: null,
				error: null,
				loading: true,
			};
			break;
		case SEARCH_INFO_CATEGORIES_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case SEARCH_INFO_CATEGORIES_ERROR:
			state = {
				...state,
				error: action.payload,
				loading: false,
			};
			break;

		case SEARCH_INFO_CATEGORIES_RESET:
			state = initialStateGetInfo;
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export { getInfoCategories, searchInfoCategories };
