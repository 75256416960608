import {
  ADD_NEW_ASSESSMENT_USER,
  GET_SINGLE_ASSESSMENT_ANSWERS,
  RESET_ASSESSMENT_USER,
  SINGLE_ASSESSMENT_ANSWERS_RESET,
  UPDATE_ASSESSMENT_USER,
  VIEW_SINGLE_ASSESSMENT_ERROR,
  VIEW_SINGLE_ASSESSMENT_REQUEST,
  VIEW_SINGLE_ASSESSMENT_RESET,
  VIEW_SINGLE_ASSESSMENT_SUCCESS,
} from "./actionTypes";

const initialStateViewSingleAssessment = {
  successData: null,
  error: null,
  loading: null,
};

const initialStateAnswerSingleAssessment = {
  userID: "1",
  assessmentID: "1",
  assessmentName: "",
  userDetails: [],
  assessmentAnswers: [],
  respondentDetails: { id: "1", name: "random", collector: "_" },
  countdown_time: "",
};

const viewSingleAssessment = (
  state = initialStateViewSingleAssessment,
  action
) => {
  switch (action.type) {
    case VIEW_SINGLE_ASSESSMENT_REQUEST:
      state = {
        ...state,
        successData: null,
        loading: true,
      };
      break;

    case VIEW_SINGLE_ASSESSMENT_SUCCESS:
      state = {
        ...state,
        successData: action.payload,
        loading: false,
      };
      break;
    case VIEW_SINGLE_ASSESSMENT_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case VIEW_SINGLE_ASSESSMENT_RESET:
      state = { ...state, successData: null, error: null, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
const assessmentAnswers = (
  state = initialStateAnswerSingleAssessment,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SINGLE_ASSESSMENT_ANSWERS:
      state = { ...payload };
      break;

    case SINGLE_ASSESSMENT_ANSWERS_RESET:
      state = initialStateAnswerSingleAssessment;
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

const assessmentUsers = (state = [], action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_NEW_ASSESSMENT_USER:
      state = [...state, payload];
      break;
    case UPDATE_ASSESSMENT_USER: {
      let newState = [...state]; // create a copy of the array
      let index = state.findIndex(
        (el) =>
          el.respondentDetails.audience_id ===
          payload.respondentDetails.audience_id
      );
      newState[index] = payload;
      return newState;
    }

    case RESET_ASSESSMENT_USER:
      state = [];
      break;

    default:
      state = [...state];
      break;
  }
  return state;
};

export { assessmentAnswers, assessmentUsers, viewSingleAssessment };
