import {
	ADD_AUDIENCE_DUPE,
	ADD_AUDIENCE_DUPE_RESET,
	ADD_AUDIENCE_ERROR,
	ADD_AUDIENCE_NON_DUPE,
	ADD_AUDIENCE_NON_DUPE_RESET,
	ADD_AUDIENCE_REQUEST,
	ADD_AUDIENCE_RESET,
	ADD_AUDIENCE_SUCCESS,
	DELETE_AUDIENCE_ERROR,
	DELETE_AUDIENCE_REQUEST,
	DELETE_AUDIENCE_SUCCESS,
	EDIT_AUDIENCE_ERROR,
	EDIT_AUDIENCE_REQUEST,
	EDIT_AUDIENCE_RESET,
	EDIT_AUDIENCE_SUCCESS,
	GET_AUDIENCE_ERROR,
	GET_AUDIENCE_REQUEST,
	GET_AUDIENCE_RESET,
	GET_AUDIENCE_SUCCESS,
} from './actionTypes';

const initialStateAddAudience = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateAddAudienceDupCheck = {
	duplicates: null,
	noDuplicates: null,
};
const initialStateGetAudience = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateDeleteAudience = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateEditAudience = {
	successData: null,
	error: null,
	loading: null,
};

const addAudience = (state = initialStateAddAudience, action) => {
	switch (action.type) {
		case ADD_AUDIENCE_REQUEST:
			state = {
				error: null,
				successData: null,
				loading: true,
			};
			break;
		case ADD_AUDIENCE_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case ADD_AUDIENCE_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case ADD_AUDIENCE_RESET:
			state = {
				...state,
				successData: null,
				error: null,
				loading: false,
			};
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const addAudienceDupCheck = (
	state = initialStateAddAudienceDupCheck,
	action
) => {
	switch (action.type) {
		case ADD_AUDIENCE_DUPE:
			state = {
				...state,
				duplicates: action.payload,
			};
			break;
		case ADD_AUDIENCE_DUPE_RESET:
			state = {
				...state,
				duplicates: null,
			};
			break;
		case ADD_AUDIENCE_NON_DUPE:
			state = { ...state, noDuplicates: action.payload };
			break;
		case ADD_AUDIENCE_NON_DUPE_RESET:
			state = {
				...state,
				noDuplicates: null,
			};
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const getAudience = (state = initialStateGetAudience, action) => {
	switch (action.type) {
		case GET_AUDIENCE_REQUEST:
			state = {
				...state,
				loading: true,
			};
			break;
		case GET_AUDIENCE_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
				error: null,
			};
			break;
		case GET_AUDIENCE_ERROR:
			state = {
				...state,
				error: action.payload,
				loading: false,
				successData: null,
			};
			break;

		case GET_AUDIENCE_RESET:
			state = initialStateGetAudience;
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const deleteAudience = (state = initialStateDeleteAudience, action) => {
	switch (action.type) {
		case DELETE_AUDIENCE_REQUEST:
			state = {
				error: null,
				successData: null,
				loading: true,
			};
			break;
		case DELETE_AUDIENCE_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case DELETE_AUDIENCE_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const editAudience = (state = initialStateEditAudience, action) => {
	switch (action.type) {
		case EDIT_AUDIENCE_REQUEST:
			state = {
				error: null,
				successData: null,
				loading: true,
			};
			break;
		case EDIT_AUDIENCE_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case EDIT_AUDIENCE_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case EDIT_AUDIENCE_RESET:
			state = {
				...state,
				successData: null,
				error: null,
				loading: false,
			};
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export {
	addAudience,
	addAudienceDupCheck,
	deleteAudience,
	getAudience,
	editAudience,
};
