import {
  RESET_UPLOAD_IMAGE,
  UPLOAD_IMAGE_ERROR,
  UPLOAD_IMAGE_REQUEST,
  UPLOAD_IMAGE_SUCCESS,
} from "./actionTypes";

const initialStateProfileImage = {
  loading: null,
  error: null,
  success: null,
};

const uploadImage = (state = initialStateProfileImage, action) => {
  switch (action.type) {
    case UPLOAD_IMAGE_REQUEST:
      state = {
        loading: true,
        error: null,
        success: null,
      };
      break;
    case UPLOAD_IMAGE_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: action.payload,
      };
      break;
    case UPLOAD_IMAGE_ERROR:
      state = { ...state, loading: false, error: action.payload };
      break;

    case RESET_UPLOAD_IMAGE:
      state = { ...initialStateProfileImage };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export { uploadImage };
