import { create } from '../../common/http.service';

const CREATE_AUDIENCE_CLONE_URL = '/clone-people';
const GET_AUDIENCE_CLONE_URL = '/get-people-list-attributes';
const UPDATE_AUDIENCE_CLONE_URL = '/update-people-with-attributes';
const DELETE_AUDIENCE_CLONE_URL = '/remove-people';

export const createAudienceCloneApi = async (payload) => {
	const res = await create(CREATE_AUDIENCE_CLONE_URL, payload, true);

	return res;
};
// export const getAudienceCloneApi = async (payload) => {
// 	const res = await create(GET_AUDIENCE_CLONE_URL, payload, true);

// 	return res;
// };
// export const editAudienceCloneApi = async (payload) => {
// 	const res = await create(UPDATE_AUDIENCE_CLONE_URL, payload, true);

// 	return res;
// };
// export const deleteAudienceCloneApi = async (payload) => {
// 	const res = await create(DELETE_AUDIENCE_CLONE_URL, payload, true);

// 	return res;
// };
