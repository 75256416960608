const CreateWithAI = require("../assets/images/Start-Survey/paste.svg");
const ImportQuestions = require("../assets/images/Start-Survey/ImportQuestions.svg");
const StartFromScratch = require("../assets/images/Start-Survey/Startfromscratch.svg");
const UseTemplate = require("../assets/images/Start-Survey/UseATemplate.svg");
const Conversation = require("../assets/images/view-type/ConversationMode.svg");
const AllInOne = require("../assets/images/view-type/FullPagePreview.svg");
const SinglePage = require("../assets/images/view-type/SingleQuestionPreview.svg");
const Quiz = require("../assets/images/view-type/TypeQuiz.svg");
const Survey = require("../assets/images/view-type/TypeSurvey.svg");

let view_type = JSON.parse(localStorage.getItem("view_type"));

export const categoryNames = [
  { id: 1, value: "Employee Engagement", label: "Employee Engagement" },
  { id: 2, value: "Customer Satisfaction", label: "Customer Satisfaction" },
  { id: 3, value: "Product Analysis", label: "Product Analysis" },
  { id: 4, value: "Market Research", label: "Market Research" },
  { id: 5, value: "Event Feedback", label: "Event Feedback" },
  { id: 6, value: "Training Effectiveness", label: "Training Effectiveness" },
  { id: 7, value: "Website Usability", label: "Website Usability" },
  { id: 8, value: "Brand Perception", label: "Brand Perception" },
  { id: 9, value: "Health and Wellness", label: "Health and Wellness" },
  { id: 10, value: "Political Polling", label: "Political Polling" },
  { id: 11, value: "Education Feedback", label: "Education Feedback" },
  { id: 12, value: "Nonprofit Impact", label: "Nonprofit Impact" },
  { id: 13, value: "Travel and Tourism", label: "Travel and Tourism" },
  { id: 14, value: "Restaurant Feedback", label: "Restaurant Feedback" },
  { id: 15, value: "Social Media Usage", label: "Social Media Usage" },
  { id: 16, value: "Technology Adoption", label: "Technology Adoption" },
  {
    id: 17,
    value: "Housing and Real Estate",
    label: "Housing and Real Estate",
  },
  { id: 18, value: "Community Development", label: "Community Development" },
  {
    id: 19,
    value: "Training Need Analysis",
    label: "Training Need Analysis",
  },
  { id: 20, value: "Product Pricing", label: "Product Pricing" },
  { id: 961893639303, value: "Other", label: "Other" },
];

export const industryNames = [
  { id: 1, value: "Agriculture", label: "Agriculture" },
  { id: 2, value: "Automotive", label: "Automotive" },
  { id: 3, value: "Banking and Finance", label: "Banking and Finance" },
  { id: 4, value: "Construction", label: "Construction" },
  { id: 5, value: "Education", label: "Education" },
  { id: 6, value: "Energy and Utilities", label: "Energy and Utilities" },
  { id: 7, value: "Food and Beverage", label: "Food and Beverage" },
  { id: 8, value: "Healthcare and Medical", label: "Healthcare and Medical" },
  { id: 9, value: "Information Technology", label: "Information Technology" },
  { id: 10, value: "Manufacturing", label: "Manufacturing" },
  {
    id: 11,
    value: "Media and Entertainment",
    label: "Media and Entertainment",
  },
  { id: 12, value: "Retail and E-commerce", label: "Retail and E-commerce" },
  { id: 13, value: "Telecommunications", label: "Telecommunications" },
  {
    id: 14,
    value: "Tourism and Hospitality",
    label: "Tourism and Hospitality",
  },
  {
    id: 15,
    value: "Transportation and Logistics",
    label: "Transportation and Logistics",
  },
  { id: 16, value: "Real Estate", label: "Real Estate" },
  { id: 17, value: "Pharmaceutical", label: "Pharmaceutical" },
  { id: 18, value: "Environmental", label: "Environmental" },
  {
    id: 19,
    value: "Government and Public Services",
    label: "Government and Public Services",
  },
  { id: 20, value: "Non-profit and NGOs", label: "Non-profit and NGOs" },
  { id: 21, value: "Fashion and Apparel", label: "Fashion and Apparel" },
  { id: 22, value: "Sports and Recreation", label: "Sports and Recreation" },
  {
    id: 23,
    value: "Architecture and Design",
    label: "Architecture and Design",
  },
  {
    id: 24,
    value: "Consulting and Advisory",
    label: "Consulting and Advisory",
  },
  { id: 25, value: "Legal and Law Services", label: "Legal and Law Services" },
  { id: 287484043811, value: "Other", label: "Other" },
];

export const Users = [
  {
    id: 1,
    name: "user1",
    email: "user1@example.com",
    permissions: ["1", "2"],
    role: ["Data Analyst"],
  },
  {
    id: 1,
    name: "user2",
    email: "user2@example.com",
    permissions: ["1", "3"],
    role: ["Data Manager"],
  },
  {
    id: 1,
    name: "user3",
    email: "user3@example.com",
    permissions: ["3", "4"],
    role: ["Expert"],
  },
  {
    id: 1,
    name: "user4",
    email: "user4@example.com",
    permissions: ["5"],
    role: ["Rookie"],
  },
];

export const predefined_Questions_Personal = [
  {
    id: 1,
    name: "name",
    label: "Name",
    required: 0,
    addInSurvey: 0,
    type: "text",
    pattern: ".*S.*",
  },
  {
    id: 2,
    name: "email",
    label: "Email",
    required: 0,
    addInSurvey: 0,
    type: "email",
    pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
  },
  {
    id: 3,
    name: "phone#",
    label: "Phone #",
    required: 0,
    addInSurvey: 0,
    type: "tel",
    pattern: "[0-9]{3}-[0-9]{2}-[0-9]{3}",
  },
  {
    id: 4,
    name: "age",
    label: "Age",
    required: 0,
    addInSurvey: 0,
    type: "number",
    pattern: "[0-9.]+",
  },
  {
    id: 5,
    name: "gender",
    label: "Gender",
    required: 0,
    addInSurvey: 0,
    type: "radio",
    pattern: "*[MmFfOo]?*",
    subData: [
      {
        id: 5.1,
        name: "male",
        label: "Male",
        value: "male",
      },
      {
        id: 5.2,
        name: "female",
        label: "Female",
        value: "female",
      },
      {
        id: 5.3,
        name: "other",
        label: "Other",
        value: "other",
      },
    ],
  },
];

// export const predefined_Questions_Personal = [
// 	{
// 		id: 1,
// 		name: 'name',
// 		label: 'Name',
// 		required: 0,
// 		addInSurvey: 0,
// 		type: 'text',
// 		pattern: '.*S.*',
// 	},
// 	{
// 		id: 2,
// 		name: 'email',
// 		label: 'Email',
// 		required: 0,
// 		addInSurvey: 0,
// 		type: 'email',
// 		pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$',
// 	},
// 	{
// 		id: 3,
// 		name: 'phone#',
// 		label: 'Phone #',
// 		required: 0,
// 		addInSurvey: 0,
// 		type: 'tel',
// 		pattern: '[0-9]{3}-[0-9]{2}-[0-9]{3}',
// 	},
// 	{
// 		id: 4,
// 		name: 'age',
// 		label: 'Age',
// 		required: 0,
// 		addInSurvey: 0,
// 		type: 'number',
// 		pattern: '[0-9.]+',
// 	},
// 	{
// 		id: 5,
// 		name: 'gender',
// 		label: 'Gender',
// 		required: 0,
// 		addInSurvey: 0,
// 		type: 'radio',
// 		pattern: '*[MmFfOo]?*',
// 		subData: [
// 			{
// 				id: 5.1,
// 				name: 'male',
// 				label: 'Male',
// 				value: 'male',
// 			},
// 			{
// 				id: 5.2,
// 				name: 'female',
// 				label: 'Female',
// 				value: 'female',
// 			},
// 			{
// 				id: 5.3,
// 				name: 'other',
// 				label: 'Other',
// 				value: 'other',
// 			},
// 		],
// 	},
// ];
// export const predefined_Questions_Professional = [
// 	{
// 		id: 1,
// 		name: 'branch_name',
// 		label: 'Branch Name',
// 		required: 0,
// 		addInSurvey: 0,
// 	},
// 	{
// 		id: 2,
// 		name: 'service_name',
// 		label: 'Product/Service Name',
// 		required: 0,
// 		addInSurvey: 0,
// 	},
// 	{
// 		id: 3,
// 		name: 'account_number',
// 		label: 'Account Number',
// 		required: 0,
// 		addInSurvey: 0,
// 	},
// ];

export const permissionsData = ["1", "2", "3", "4", "5"];

export const surveyModalStep1 = [
  {
    id: 1,
    name: "Survey",
    icon: Survey,

    goto: "/create-survey",
  },
  {
    id: 2,
    name: "Quiz",
    icon: Quiz,
    goto: "/create-quiz",
  },
];

export const surveyModalStep2 = [
  {
    id: 1,
    name: "Start from scratch",
    icon: StartFromScratch,
    goto: ["", ""],
  },
  {
    id: 2,
    name: "Use a template",
    icon: UseTemplate,
    goto: ["/initiate-template", "/initiate-template"],
  },
  {
    id: 3,
    name: `Copy A Past ${view_type?.label}`,
    icon: CreateWithAI,
    goto: ["/initiate-survey", "/initiate-quiz"],
  },
  {
    id: 4,
    name: "Import Questions",
    icon: ImportQuestions,
    goto: [
      process.env.REACT_APP_CONTACT_REDIRECT,
      process.env.REACT_APP_CONTACT_REDIRECT,
    ],
  },
];

export const surveyModalStep3 = [
  {
    id: 1,
    name: "Classical",
    icon: AllInOne,
    goto: "",
  },
  {
    id: 2,
    name: "Single Page",
    icon: SinglePage,
    goto: "",
  },
  {
    id: 3,
    name: "Conversation",
    icon: Conversation,
    goto: "",
  },
];

export const packageCardData = [
  {
    package_id: 0,
    title: "Trial",
    subTitle: "Single User",
    price: "150",
    color: "smart",
    features: ["Feature 1", "Feature 2", "Feature 3", "Feature 4", "Feature 5"],
  },
  {
    package_id: 1,
    title: "Individual",
    subTitle: "Single User",
    price: "150",
    color: "primary",
    features: ["Feature 1", "Feature 2", "Feature 3", "Feature 4", "Feature 5"],
  },
  {
    package_id: 2,
    title: "Team",
    subTitle: "Single User",
    price: "150",
    color: "info",
    features: ["Feature 1", "Feature 2", "Feature 3", "Feature 4", "Feature 5"],
  },
  {
    package_id: 3,
    title: "Enterprise",
    subTitle: "Single User",
    price: "150",
    color: "danger",
    features: ["Feature 1", "Feature 2", "Feature 3", "Feature 4", "Feature 5"],
  },
];
export const tutorialCardData = [
  {
    title: "My Account",
    subTitle: "You can manage your account details and passwords from here",

    features: [
      {
        id: 1,
        video_link: "",
        title: "How to change the dates in Dashboard?",
        modification_date: "15 Days Ago",
      },
      {
        id: 2,
        video_link: "",
        title: "How to change the dates in Dashboard?",
        modification_date: "15 Days Ago",
      },
      {
        id: 3,
        video_link: "",
        title: "How to change the dates in Dashboard?",
        modification_date: "15 Days Ago",
      },
      {
        id: 4,
        video_link: "",
        title: "How to change the dates in Dashboard?",
        modification_date: "15 Days Ago",
      },
      {
        id: 5,
        video_link: "",
        title: "How to change the dates in Dashboard?",
        modification_date: "15 Days Ago",
      },
      {
        id: 6,
        video_link: "",
        title: "How to change the dates in Dashboard?",
        modification_date: "15 Days Ago",
      },
    ],
  },
  {
    title: "Ticket Management",
    subTitle: "You can manage your account details and passwords from here",

    features: [
      {
        id: 1,
        video_link: "",
        title: "How to change the dates in Dashboard?",
        modification_date: "15 Days Ago",
      },
      {
        id: 2,
        video_link: "",
        title: "How to change the dates in Dashboard?",
        modification_date: "15 Days Ago",
      },
      {
        id: 3,
        video_link: "",
        title: "How to change the dates in Dashboard?",
        modification_date: "15 Days Ago",
      },
      {
        id: 4,
        video_link: "",
        title: "How to change the dates in Dashboard?",
        modification_date: "15 Days Ago",
      },
      {
        id: 5,
        video_link: "",
        title: "How to change the dates in Dashboard?",
        modification_date: "15 Days Ago",
      },
      {
        id: 6,
        video_link: "",
        title: "How to change the dates in Dashboard?",
        modification_date: "15 Days Ago",
      },
    ],
  },
  {
    title: "Survey Management",
    subTitle: "You can manage your account details and passwords from here",

    features: [
      {
        id: 1,
        video_link: "",
        title: "How to change the dates in Dashboard?",
        modification_date: "15 Days Ago",
      },
      {
        id: 2,
        video_link: "",
        title: "How to change the dates in Dashboard?",
        modification_date: "15 Days Ago",
      },
      {
        id: 3,
        video_link: "",
        title: "How to change the dates in Dashboard?",
        modification_date: "15 Days Ago",
      },
      {
        id: 4,
        video_link: "",
        title: "How to change the dates in Dashboard?",
        modification_date: "15 Days Ago",
      },
      {
        id: 5,
        video_link: "",
        title: "How to change the dates in Dashboard?",
        modification_date: "15 Days Ago",
      },
      {
        id: 6,
        video_link: "",
        title: "How to change the dates in Dashboard?",
        modification_date: "15 Days Ago",
      },
    ],
  },
];

export const templateFormats = ({ channelLink, survey }) => {
  return [
    {
      id: 1,
      label: "Default",
      value: "default_format",
      innerText: `<div contenteditable="false">
     

<br />
        <h2>Hello Sir/Madam!</h2>
        <br />
        <p>
          We'd love to hear your thoughts on your recent experience
          with our customer support team.
        </p>
        <p>
          Please take this quick survey to let us know how we did.
        </p>
        <br />
        <a
          href="${channelLink}"
          target="_blank"
        >
          Click Here
        </a>
        <br />
        <br />
        <p>Thanks</p>
        <p>Team Antlere</p>
      </div>`,
    },

    {
      id: 2,
      label: "Product/Software Review",
      value: "software_review",
      innerText: `<div contenteditable="false">
              <p>
              Thanks for using (Product/Software). We'd love to hear how you feel about it in this
              quick online survey.
              </p>
            
              <br />
              <a
                href="${channelLink}"
                target="_blank"
              >
                Take the survey
              </a>
             
              <br />
              <br />
              <p>
              We will be reading every response. Your honest feedback helps us make (Product/Software) better for 
you and your team.
              </p>
             
              <br />
              <p>Thank you!</p>
             
              <br />
              <p>(Designation)</p>
              <p>(Product/Software)</p>
            </div>`,
    },
    {
      id: 3,
      label: " Research Survey",
      value: "research_survey",
      innerText: `<div contenteditable="false">
     

      <p>Hi [Name]</p>
      <br />
      <p>
      It seems like [topic of research] is becoming a hot topic these days. Has it been making an impact on you 
      too? We would love to know how you are dealing with it.
      </p>
      <br />
      <p>Whenever you can, please complete this short   <a
      href="${channelLink}"
      target="_blank"
    >
      ${survey.name}
    </a> we’re conducting so we can get as much 
      relevant data on [topic of research] as possible. We’ll inform you about the survey results as soon as 
      they are ready!</p>
      <br />
    
     
      <p>Thank you for being a great sport!</p>
      <br/>
      <p>The Team at [company]</p>
    </div>`,
    },
    {
      id: 4,
      label: "Participation Survey Email",
      value: "participation_survey",
      innerText: `<div contenteditable="false">
     

      <p>Hey [Name],</p>
      <br />
      <p>
      We hope you had a great time at the [event]! We try our best to provide the top services for all the 
      participants. But don’t let us guess – help us learn from you directly.
      </p>
      <br />
      <p>Please take only 5 minutes of your day to complete this <a
      href="${channelLink}"
      target="_blank"
    >
      ${survey.name}
    </a> we’re conducting so we can get as much  and share your thoughts. Any 
      feedback is important to us because that’s how we can improve.</p>
      <br />
      <p>Thank you for sharing your time with us, and we hope to see you again at the following [event]</p>
      
    </div>`,
    },
    {
      id: 5,
      label: "Discount Offering",
      value: "discount_offering",
      innerText: `<div contenteditable="false">
     

      <p>Hello Sir/Ma'am</p>
      <br />
      <p>
      We are interested in hearing from you and would be grateful if you could take a few minutes out of your 
      day to fill out our survey. Your feedback will help us develop better goods and services that are tailored 
      to better satisfy your requirements.
      </p>
      <br/>
      <p>We would like to provide to you a discount of 10% off your subsequent purchase as a token of our 
      appreciation for your contribution.</p>
      <br />
      <a
        href="${channelLink}"
        target="_blank"
      >
        ${survey.name}
      </a>
      <br />
    
      <p>Thank you.</p>
     
    </div>`,
    },
  ];
};
