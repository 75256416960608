import { call, put, takeEvery } from "redux-saga/effects";

import { initiateAssessmentSuccess } from "../default/actions";

// Login Redux States

import { createQuizApi } from "../../../../services/quiz/create-quiz/createQuizApi";
import { createSurveyApi } from "../../../../services/surveys/create-survey/createSurveysApi";
import { createTemplateApi } from "../../../../services/template/templateApis";
import {
  COPY_ASSESSMENT_REQUEST,
  CREATE_ASSESSMENT_REQUEST,
  CREATE_ASSESSMENT_REQUEST_OTHER,
} from "./actionTypes";
import { createAssessmentError, createAssessmentSuccess } from "./actions";

function* createAssessment({ payload: surveyPayload }) {
  let isThisQuiz = window.location.pathname.includes("quiz");

  try {
    let newData;
    const response = isThisQuiz
      ? yield call(createQuizApi, surveyPayload)
      : surveyPayload.is_template
      ? yield call(createTemplateApi, surveyPayload)
      : yield call(createSurveyApi, surveyPayload);

    if (response && response.success) {
      let { survey_id, quiz_id, ...rest } = response.message;

      if (survey_id || quiz_id) {
        newData = { ...surveyPayload, ...rest, _id: survey_id || quiz_id };
      } else {
        newData = surveyPayload;
      }

      yield put(
        initiateAssessmentSuccess({
          ...newData,
          survey_questionnaire:
            newData.survey_questionnaire || newData.quiz_questionnaire,
          pre_survey_questionnaire:
            newData.pre_survey_questionnaire || newData.pre_quiz_questionnaire,
        })
      );

      yield put(createAssessmentSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(createAssessmentError(error));
  }
}
function* createAssessmentOther({ payload: surveyPayload }) {
  try {
    const response = yield call(createSurveyApi, surveyPayload);
    if (response && response.success) {
      yield put(createAssessmentSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(createAssessmentError(error));
  }
}

function* copyAssessment({ payload: surveyPayload }) {
  try {
    const response = yield call(createSurveyApi, surveyPayload);
    if (response && response.success) {
      let newData = { ...surveyPayload, _id: response.message.survey_id };
      yield put(initiateAssessmentSuccess(newData));
      yield put(createAssessmentSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(createAssessmentError(error));
  }
}

function* createAssessmentSaga() {
  yield takeEvery(CREATE_ASSESSMENT_REQUEST, createAssessment);
  yield takeEvery(CREATE_ASSESSMENT_REQUEST_OTHER, createAssessmentOther);
  yield takeEvery(COPY_ASSESSMENT_REQUEST, copyAssessment);
}

export default createAssessmentSaga;
