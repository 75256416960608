import CryptoJS from "crypto-js";
import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { editProfileApi, profileApi } from "../../../services/auth/profileApi";
import { addImageApi } from "../../../services/surveys/create-survey/createSurveysApi";
import { updateAssessmentInStore } from "../../actions";
import {
  EDIT_PROFILE_REQUEST,
  PROFILE_IMAGE_REQUEST,
  PROFILE_REQUEST,
} from "./actionTypes";
import {
  editProfileError,
  editProfileRequest,
  editProfileSuccess,
  profileError,
  profileImageError,
  profileImageSuccess,
  profileSuccess,
} from "./actions";

//Include Both Helper File with needed methods

function* profileUser({ payload: history }) {
  try {
    let encryptUser = {};
    const userProfile = yield call(profileApi);

    if (userProfile && userProfile.success) {
      let {
        data: { user },
      } = userProfile;

      let { id, ...rest } = user;

      localStorage.setItem("userID", JSON.stringify(id));
      yield put(updateAssessmentInStore({ user_id: id, created_by: id }));
      for (const key in user) {
        if (Object.hasOwnProperty.call(user, key)) {
          encryptUser[key] = yield CryptoJS.AES.encrypt(
            String(user[key]),
            `${process.env.REACT_APP_HASH_KEY}`
          ).toString();

          // let userEncrypt = CryptoJS.AES.encrypt(
          // 	String(userProfile),
          // 	`${process.env.REACT_APP_HASH_KEY}`
          // ).toString();
          // yield put(profileSuccess(userEncrypt));
        }
      }
      userProfile.data.user = encryptUser;

      yield put(profileSuccess({ ...userProfile }));

      if (
        Number(userProfile.data.package) > 0 &&
        Number(userProfile.data.payment) == 0
      ) {
        history.push("/billing");
      }
    } else {
      const { goto, ...restResponse } = userProfile;
      history.push(goto);

      throw restResponse;
    }
  } catch (error) {
    yield put(profileError(error));
  }
}

function* editProfile({ payload: user }) {
  try {
    const response = yield call(editProfileApi, user);
    if (response && response.success) {
      yield put(editProfileSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(editProfileError(error));
  }
}

function* profileImage({ payload: imgPayload }) {
  try {
    const response = yield call(addImageApi, imgPayload);
    if (response && response.success) {
      yield put(profileImageSuccess(response));
      yield put(
        editProfileRequest({
          type: "profile_pic",
          profile_pic_url: response.data.path,
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    yield put(profileImageError(error));
  }
}

function* ProfileSaga() {
  yield takeEvery(PROFILE_REQUEST, profileUser);
  yield takeEvery(EDIT_PROFILE_REQUEST, editProfile);
  yield takeEvery(PROFILE_IMAGE_REQUEST, profileImage);
}

export default ProfileSaga;
