import {
	ADD_COMPANY_LOGO_ERROR,
	ADD_COMPANY_LOGO_REQUEST,
	ADD_COMPANY_LOGO_RESET,
	ADD_COMPANY_LOGO_SUCCESS,
	ADD_INTRO_BG_ERROR,
	ADD_INTRO_BG_REQUEST,
	ADD_INTRO_BG_RESET,
	ADD_INTRO_BG_SUCCESS,
} from './actionTypes';

const initialStateAddCompanyLogo = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateIntroBG = {
	successData: null,
	error: null,
	loading: null,
};

const addCompanyLogo = (state = initialStateAddCompanyLogo, action) => {
	switch (action.type) {
		case ADD_COMPANY_LOGO_REQUEST:
			state = {
				successData: null,
				error: null,
				loading: true,
			};
			break;
		case ADD_COMPANY_LOGO_SUCCESS:
			state = {
				...state,

				successData: action.payload,
				loading: false,
			};
			break;
		case ADD_COMPANY_LOGO_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case ADD_COMPANY_LOGO_RESET:
			state = initialStateAddCompanyLogo;
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

const addIntroBG = (state = initialStateIntroBG, action) => {
	switch (action.type) {
		case ADD_INTRO_BG_REQUEST:
			state = {
				successData: null,
				error: null,
				loading: true,
			};
			break;
		case ADD_INTRO_BG_SUCCESS:
			state = {
				...state,

				successData: action.payload,
				loading: false,
			};
			break;
		case ADD_INTRO_BG_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case ADD_INTRO_BG_RESET:
			state = initialStateIntroBG;
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export { addCompanyLogo, addIntroBG };
