import { call, put, takeEvery } from 'redux-saga/effects';
import {
	addAdminEmailApi,
	deleteAdminEmailApi,
	getAdminEmailsApi,
} from '../../../services/admin-emails/adminEmailApi';

// Login Redux States

import {
	addAdminEmailError,
	addAdminEmailSuccess,
	deleteAdminEmailError,
	deleteAdminEmailSuccess,
	getAdminEmailListError,
	getAdminEmailListSuccess,
} from './actions';
import {
	ADD_ADMIN_EMAIL_REQUEST,
	DELETE_ADMIN_EMAIL_REQUEST,
	GET_ADMIN_EMAIL_LIST_REQUEST,
} from './actionTypes';

//Include Both Helper File with needed methods

//If role is send successfully send mail link then dispatch redux action's are directly from here.
function* addAdminEmail({ payload: email }) {
	try {
		const response = yield call(addAdminEmailApi, email);
		if (response && response.success) {
			yield put(addAdminEmailSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(addAdminEmailError(error));
	}
}

function* getAdminEmailList({ payload: surveyID }) {
	try {
		const response = yield call(getAdminEmailsApi, surveyID);
		if (response && response.success) {
			yield put(getAdminEmailListSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(getAdminEmailListError(error));
	}
}
// function* editAdminEmail({ payload: attrData }) {
// 	try {
// 		const response = yield call(editAdminEmailApi, attrData);
// 		if (response && response.success) {
// 			yield put(editAdminEmailSuccess(response));
// 		} else {
// throw response;
// 		}
// 	} catch (error) {
// 			yield put(editAdminEmailError(response));
//
// 	}
// }

function* deleteAdminEmail({ payload: email }) {
	try {
		const response = yield call(deleteAdminEmailApi, email);
		if (response && response.success) {
			yield put(deleteAdminEmailSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(deleteAdminEmailError(error));
	}
}

function* adminEmailSaga() {
	yield takeEvery(ADD_ADMIN_EMAIL_REQUEST, addAdminEmail);

	yield takeEvery(GET_ADMIN_EMAIL_LIST_REQUEST, getAdminEmailList);

	yield takeEvery(DELETE_ADMIN_EMAIL_REQUEST, deleteAdminEmail);
}

export default adminEmailSaga;
