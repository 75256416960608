import {
	ADD_RECURRENCE_ERROR,
	ADD_RECURRENCE_REQUEST,
	ADD_RECURRENCE_RESET,
	ADD_RECURRENCE_SUCCESS,
	GET_RECURRENCES_ERROR,
	GET_RECURRENCES_REQUEST,
	GET_RECURRENCES_RESET,
	GET_RECURRENCES_SUCCESS,
} from './actionTypes';

// Invite Member with email

export const addRecurrenceRequest = (segment) => {
	return {
		type: ADD_RECURRENCE_REQUEST,
		payload: segment,
	};
};

export const addRecurrenceSuccess = (message) => {
	return {
		type: ADD_RECURRENCE_SUCCESS,
		payload: message,
	};
};

export const addRecurrenceError = (message) => {
	return {
		type: ADD_RECURRENCE_ERROR,
		payload: message,
	};
};

export const addRecurrenceReset = () => {
	return {
		type: ADD_RECURRENCE_RESET,
	};
};

export const getRecurrencesRequest = (surveyID) => {
	return {
		type: GET_RECURRENCES_REQUEST,
		payload: surveyID,
	};
};

export const getRecurrencesSuccess = (message) => {
	return {
		type: GET_RECURRENCES_SUCCESS,
		payload: message,
	};
};

export const getRecurrencesError = (message) => {
	return {
		type: GET_RECURRENCES_ERROR,
		payload: message,
	};
};

export const getRecurrencesReset = (message) => {
	return {
		type: GET_RECURRENCES_RESET,
	};
};
