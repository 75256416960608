import { create, get } from "../../common/http.service";

const CREATE_ROLE_PERMISSIONS_URL = "/assign-role-permission";
const GET_ROLE_PERMISSIONS_URL = "/get-role-permissions";
const UPDATE_ROLE_PERMISSIONS_URL = "/update-role-permissions";

export const createRolePermissionsApi = async (payload) => {
  const res = await create(CREATE_ROLE_PERMISSIONS_URL, payload, true);

  return res;
};
export const getRolePermissionsApi = async (payload) => {
  const res = await get(GET_ROLE_PERMISSIONS_URL, payload, true);

  return res;
};
export const editRolePermissionsApi = async (payload) => {
  const res = await create(UPDATE_ROLE_PERMISSIONS_URL, payload, true);

  return res;
};
