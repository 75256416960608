import { create } from '../../common/http.service';

const VIEW_CHART_DASHBOARD = '/get-survey-dashboard-list';
const CREATE_CHART_DASHBOARD = '/create-survey-dashboard';
const DELETE_CHART_DASHBOARD = '/delete-survey-dashboard';
const UPDATE_CHART_DASHBOARD = '/update-survey-dashboard';
const SINGLE_CHART_DASHBOARD = '/get-survey-dashboard';
const SINGLE_PUBLIC_DASHBOARD = '/get-public-survey-dashboard';

export const viewChartApi = async (payload) => {
	const res = await create(VIEW_CHART_DASHBOARD, payload, true);

	return res;
};
export const createChartApi = async (payload) => {
	const res = await create(CREATE_CHART_DASHBOARD, payload, true);

	return res;
};

export const deleteChartApi = async (payload) => {
	const res = await create(DELETE_CHART_DASHBOARD, payload, true);

	return res;
};
export const updateChartApi = async (payload) => {
	const res = await create(UPDATE_CHART_DASHBOARD, payload, true);

	return res;
};
export const singleChartApi = async (payload) => {
	const res = await create(SINGLE_CHART_DASHBOARD, payload, true);

	return res;
};

export const publicChartApi = async (payload) => {
	const res = await create(SINGLE_PUBLIC_DASHBOARD, payload, true);

	return res;
};
