export const ADD_QUESTION_TITLE = 'ADD_QUESTION_TITLE';
export const EDIT_QUESTION = 'EDIT_QUESTION';
export const EDIT_QUESTION_DATA = 'EDIT_QUESTION_DATA';
export const EDIT_QUESTION_DATA_RESET = 'EDIT_QUESTION_DATA_RESET';
export const REQUIRED_QUESTION = 'REQUIRED_QUESTION';
export const CANCEL_QUESTION = 'CANCEL_QUESTION';
export const DELETE_QUESTION = 'DELETE_QUESTION';

export const ALL_QUESTION_SURVEY = 'ALL_QUESTION_SURVEY';
export const ALL_QUESTION_COMPLETE_VIEW = 'ALL_QUESTION_COMPLETE_VIEW';
export const ALL_QUESTION_COMPLETE_VIEW_RESET =
	'ALL_QUESTION_COMPLETE_VIEW_RESET';
export const ADD_QUESTION_AT_END = 'ADD_QUESTION_AT_END';
export const ADD_QUESTION_IN_BETWEEN = 'ADD_QUESTION_IN_BETWEEN';

export const QUESTION_SURVEY_RESET = 'QUESTION_SURVEY_RESET';
