import {
	ADD_ROLE_PERMISSIONS_ERROR,
	ADD_ROLE_PERMISSIONS_REQUEST,
	ADD_ROLE_PERMISSIONS_RESET,
	ADD_ROLE_PERMISSIONS_SUCCESS,
	DELETE_ROLE_PERMISSIONS_ERROR,
	DELETE_ROLE_PERMISSIONS_REQUEST,
	DELETE_ROLE_PERMISSIONS_SUCCESS,
	EDIT_ROLE_PERMISSIONS_ERROR,
	EDIT_ROLE_PERMISSIONS_REQUEST,
	EDIT_ROLE_PERMISSIONS_RESET,
	EDIT_ROLE_PERMISSIONS_SUCCESS,
	GET_ROLE_PERMISSIONS_ERROR,
	GET_ROLE_PERMISSIONS_REQUEST,
	GET_ROLE_PERMISSIONS_RESET,
	GET_ROLE_PERMISSIONS_SUCCESS,
} from './actionTypes';

// Create RolePermissions
export const addRolePermissionsRequest = ({ user, history }) => {
	return {
		type: ADD_ROLE_PERMISSIONS_REQUEST,
		payload: { user, history },
	};
};

export const addRolePermissionsSuccess = (message) => {
	return {
		type: ADD_ROLE_PERMISSIONS_SUCCESS,
		payload: message,
	};
};

export const addRolePermissionsError = (message) => {
	return {
		type: ADD_ROLE_PERMISSIONS_ERROR,
		payload: message,
	};
};

export const addRolePermissionsReset = () => {
	return {
		type: ADD_ROLE_PERMISSIONS_RESET,
	};
};

// Get RolePermissions
export const getRolePermissionsRequest = () => {
	return {
		type: GET_ROLE_PERMISSIONS_REQUEST,
	};
};

export const getRolePermissionsSuccess = (message) => {
	return {
		type: GET_ROLE_PERMISSIONS_SUCCESS,
		payload: message,
	};
};

export const getRolePermissionsError = (message) => {
	return {
		type: GET_ROLE_PERMISSIONS_ERROR,
		payload: message,
	};
};

export const getRolePermissionsReset = (message) => {
	return {
		type: GET_ROLE_PERMISSIONS_RESET,
	};
};

// Delete RolePermissions
export const deleteRolePermissionsRequest = (role, history) => {
	return {
		type: DELETE_ROLE_PERMISSIONS_REQUEST,
		payload: { role, history },
	};
};

export const deleteRolePermissionsSuccess = (message) => {
	return {
		type: DELETE_ROLE_PERMISSIONS_SUCCESS,
		payload: message,
	};
};

export const deleteRolePermissionsError = (message) => {
	return {
		type: DELETE_ROLE_PERMISSIONS_ERROR,
		payload: message,
	};
};

// Delete RolePermissions
export const editRolePermissionsRequest = (role, history) => {
	return {
		type: EDIT_ROLE_PERMISSIONS_REQUEST,
		payload: { role, history },
	};
};

export const editRolePermissionsSuccess = (message) => {
	return {
		type: EDIT_ROLE_PERMISSIONS_SUCCESS,
		payload: message,
	};
};

export const editRolePermissionsError = (message) => {
	return {
		type: EDIT_ROLE_PERMISSIONS_ERROR,
		payload: message,
	};
};
export const editRolePermissionsReset = () => {
	return {
		type: EDIT_ROLE_PERMISSIONS_RESET,
	};
};
