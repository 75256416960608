import { call, put, takeEvery } from 'redux-saga/effects';

// Login Redux States
import {
	createAudienceApi,
	deleteAudienceApi,
	editAudienceApi,
	getAudienceApi,
} from '../../services/surveys/audience/audienceApi';
import {
	addAudienceError,
	addAudienceSuccess,
	deleteAudienceError,
	deleteAudienceSuccess,
	editAudienceError,
	editAudienceSuccess,
	getAudienceError,
	getAudienceSuccess,
} from './actions';
import {
	ADD_AUDIENCE_REQUEST,
	DELETE_AUDIENCE_REQUEST,
	EDIT_AUDIENCE_REQUEST,
	GET_AUDIENCE_REQUEST,
} from './actionTypes';

//Include Both Helper File with needed methods

//If role is send successfully send mail link then dispatch redux action's are directly from here.
function* addAudiences({ payload: audience }) {
	try {
		const response = yield call(createAudienceApi, audience);
		if (response && response.success) {
			yield put(addAudienceSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(addAudienceError(error));
	}
}
function* getAudiences({ payload: audience }) {
	try {
		const response = yield call(getAudienceApi, audience);
		if (response && response.success) {
			yield put(getAudienceSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(getAudienceError(error));
	}
}
function* editAudiences({ payload: audience }) {
	try {
		const response = yield call(editAudienceApi, audience);
		if (response && response.success) {
			yield put(editAudienceSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(editAudienceError(error));
	}
}

function* deleteAudiences({ payload: role }) {
	try {
		const response = yield call(deleteAudienceApi, role);
		if (response && response.success) {
			yield put(deleteAudienceSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(deleteAudienceError(error));
	}
}

function* audienceSaga() {
	yield takeEvery(ADD_AUDIENCE_REQUEST, addAudiences);
	yield takeEvery(GET_AUDIENCE_REQUEST, getAudiences);
	yield takeEvery(EDIT_AUDIENCE_REQUEST, editAudiences);
	yield takeEvery(DELETE_AUDIENCE_REQUEST, deleteAudiences);
}

export default audienceSaga;
