import {
	ADD_DIS_BG_ERROR,
	ADD_DIS_BG_REQUEST,
	ADD_DIS_BG_RESET,
	ADD_DIS_BG_SUCCESS,
} from './actionTypes';

const initialStateDisBG = {
	successData: null,
	error: null,
	loading: null,
};

const addDisBG = (state = initialStateDisBG, action) => {
	switch (action.type) {
		case ADD_DIS_BG_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case ADD_DIS_BG_SUCCESS:
			state = {
				...state,
				error: null,
				successData: action.payload,
				loading: false,
			};
			break;
		case ADD_DIS_BG_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case ADD_DIS_BG_RESET:
			state = { ...state, successData: null, error: null, loading: false };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export { addDisBG };
