import {
  ASSESSMENT_SUCCESS_ERROR,
  ASSESSMENT_SUCCESS_REQUEST,
  ASSESSMENT_SUCCESS_RESET,
  ASSESSMENT_SUCCESS_SUCCESS,
} from "./actionTypes";

const initialStateSurveySuccess = {
  successData: null,
  error: null,
  loading: null,
};

const assessmentSuccess = (state = initialStateSurveySuccess, action) => {
  switch (action.type) {
    case ASSESSMENT_SUCCESS_REQUEST:
      state = {
        successData: null,
        loading: true,
        error: null,
      };
      break;

    case ASSESSMENT_SUCCESS_SUCCESS:
      state = {
        ...state,
        successData: action.payload,
        loading: false,
      };
      break;
    case ASSESSMENT_SUCCESS_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case ASSESSMENT_SUCCESS_RESET:
      state = initialStateSurveySuccess;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { assessmentSuccess };
