import {
	ADD_ATTRIBUTES_ERROR,
	ADD_ATTRIBUTES_REQUEST,
	ADD_ATTRIBUTES_RESET,
	ADD_ATTRIBUTES_SUCCESS,
	DELETE_ATTRIBUTES_ERROR,
	DELETE_ATTRIBUTES_REQUEST,
	DELETE_ATTRIBUTES_SUCCESS,
	EDIT_ATTRIBUTES_ERROR,
	EDIT_ATTRIBUTES_REQUEST,
	EDIT_ATTRIBUTES_RESET,
	EDIT_ATTRIBUTES_SUCCESS,
	GET_ATTRIBUTES_ERROR,
	GET_ATTRIBUTES_REQUEST,
	GET_ATTRIBUTES_RESET,
	GET_ATTRIBUTES_SUCCESS,
} from './actionTypes';

const initialStateAddAttributes = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateGetAttributes = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateDeleteAttributes = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateEditAttributes = {
	successData: null,
	error: null,
	loading: null,
};

const addAttributes = (state = initialStateAddAttributes, action) => {
	switch (action.type) {
		case ADD_ATTRIBUTES_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case ADD_ATTRIBUTES_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case ADD_ATTRIBUTES_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case ADD_ATTRIBUTES_RESET:
			state = {
				...state,
				successData: null,
				error: null,
				loading: false,
			};
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const getAttributes = (state = initialStateGetAttributes, action) => {
	switch (action.type) {
		case GET_ATTRIBUTES_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case GET_ATTRIBUTES_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case GET_ATTRIBUTES_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;

		case GET_ATTRIBUTES_RESET:
			state = initialStateGetAttributes;
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const deleteAttributes = (state = initialStateDeleteAttributes, action) => {
	switch (action.type) {
		case DELETE_ATTRIBUTES_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case DELETE_ATTRIBUTES_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case DELETE_ATTRIBUTES_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const editAttributes = (state = initialStateEditAttributes, action) => {
	switch (action.type) {
		case EDIT_ATTRIBUTES_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case EDIT_ATTRIBUTES_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case EDIT_ATTRIBUTES_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case EDIT_ATTRIBUTES_RESET:
			state = {
				...state,
				successData: null,
				error: null,
				loading: false,
			};
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export { addAttributes, deleteAttributes, getAttributes, editAttributes };
