import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
//import logo
const logoSm = require("../../assets/images/antlere-white.png");
const logoBg = require("../../assets/images/dashboard-icons/Antlere_White_Logo.svg");

//Import Components
import {
  RiEqualizerLine,
  RiRecordCircleLine,
  RiSurveyFill,
} from "react-icons/ri";
import { useSelector } from "react-redux";
import { Container, Progress } from "reactstrap";
import DecryptedUser from "../../common/DecryptedUser";
import SidebarContent from "./SidebarContent";
const Sidebar = ({ layoutType, isSidebarChanged }) => {
  const [responsesCollected, setResponsesCollected] = useState(0);
  const [allowedResponses, setAllowedResponses] = useState(0);

  const [progressValue, setProgressValue] = useState(0);

  const { userProfile } = DecryptedUser();
  const { data: userData } = userProfile || {};
  const { config } = userData || {};

  const { successData } = useSelector((state) => state.viewAllAssessmentStats);
  const { data } = successData || {};

  useEffect(() => {
    var verticalOverlay = document.getElementsByClassName("vertical-overlay");
    if (verticalOverlay) {
      verticalOverlay[0].addEventListener("click", function () {
        document.body.classList.remove("vertical-sidebar-enable");
      });
    }
  });

  const addEventListenerOnSmHoverMenu = () => {
    // add listener Sidebar Hover icon on change layout from setting
    if (
      document.documentElement.getAttribute("data-sidebar-size") === "sm-hover"
    ) {
      document.documentElement.setAttribute(
        "data-sidebar-size",
        "sm-hover-active"
      );
    } else if (
      document.documentElement.getAttribute("data-sidebar-size") ===
      "sm-hover-active"
    ) {
      document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
    } else {
      document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
    }
  };

  useEffect(() => {
    setAllowedResponses(config?.allowed_total_responses || 0);

    if (data) {
      setResponsesCollected(data.total_response);

      setProgressValue(
        (data.total_response / config?.allowed_total_responses) * 100
      );
    }
  }, [data, userData]);

  return (
    <React.Fragment>
      <div className="app-menu navbar-menu">
        <div className="navbar-brand-box">
          <Link
            to="/"
            className="logo logo-dark"
            onClick={() => localStorage.setItem("activeLink", "dashboard")}
          >
            <span className="logo-sm">
              <img src={logoSm} alt="" height="30" />
            </span>
            <span className="logo-lg">
              <img src={logoBg} alt="" height="40" />
            </span>
          </Link>

          <Link
            to="/"
            className="logo logo-light"
            onClick={() => localStorage.setItem("activeLink", "dashboard")}
          >
            <span className="logo-sm">
              <img src={logoSm} alt="" height="30" />
            </span>
            <span className="logo-lg">
              <img src={logoBg} alt="" height="40" />
            </span>
          </Link>
          <button
            onClick={addEventListenerOnSmHoverMenu}
            type="button"
            className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <RiRecordCircleLine />
          </button>
        </div>

        <SimpleBar
          id="scrollbar"
          className="h-100 mt-1"
          style={{ maxHeight: "67vh" }}
          forceVisible="y"
          autoHide={false}
        >
          <Container fluid>
            <div id="two-column-menu"></div>
            <ul className="navbar-nav" id="navbar-nav">
              <SidebarContent layoutType={layoutType} isSide />
            </ul>
          </Container>
        </SimpleBar>

        {isSidebarChanged == "lg" ? (
          <ul className="navbar-nav info-navbar" id="navbar-nav">
            <li className="nav-item">
              <div className={`nav-link menu-link`} data-bs-toggle="collapse">
                <RiEqualizerLine
                  size={22}
                  className=" text-white align-self-end"
                />
                <div className="progress_div_nav ms-3 " data-key="t-apps">
                  {" "}
                  <div>
                    <p className=" text-white mb-1">Response Collected</p>
                    <Progress
                      value={progressValue}
                      color="light"
                      className=" mb-1"
                    />
                    <p className=" text-white">
                      {responsesCollected} / {allowedResponses}
                    </p>
                  </div>
                  <Link
                    className="text-white d-flex justify-content-between align-items-center"
                    to={"/profile/4"}
                  >
                    <p className="mb-0">Increase Response Limit</p>
                    <RiSurveyFill className="ms-2" />
                  </Link>
                </div>
              </div>
            </li>
          </ul>
        ) : (
          <>
            {" "}
            <div className=" p-3 info-navbar">
              <p className=" text-white mb-1">Response Collected</p>
              <Progress value={progressValue} color="light" className=" mb-1" />
              <p className=" text-white">
                {responsesCollected} / {allowedResponses}
              </p>

              {/* <p className=" text-white">
            Renews on {data?.package_expire_date}
          </p> */}
            </div>
            <Link
              className="  info-navbar-bottom nav-link menu-link text-white d-flex justify-content-between align-items-center"
              to={"/profile/4"}
            >
              <span>Increase Response Limit</span>
              <RiSurveyFill className="ms-2" />
            </Link>
          </>
        )}
      </div>
      <div className="vertical-overlay"></div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  isSidebarChanged: PropTypes.string,
  layoutType: PropTypes.string,
};

export default Sidebar;
