import { create } from "../../common/http.service";

const CREATE_QUESTION_BANK_URL = "/create-question-bank";
const GET_QUESTION_BANK_URL = "/list-question-bank";
const SINGLE_QUESTION_BANK_URL = "/get-question-bank";
const SEARCH_QUESTION_BANK_URL = "/search-question-bank";

export const createQuestionBankApi = async (payload) => {
  const res = await create(CREATE_QUESTION_BANK_URL, payload, true);

  return res;
};
export const getCompleteQuestionBankApi = async (payload) => {
  const res = await create(GET_QUESTION_BANK_URL, payload, true);

  return res;
};
export const getOneQuestionBankApi = async (payload) => {
  const res = await create(SINGLE_QUESTION_BANK_URL, payload, true);

  return res;
};

export const searchFromQuestionBankApi = async (payload) => {
  const res = await create(SEARCH_QUESTION_BANK_URL, payload, true);

  return res;
};
