import { call, put, takeEvery } from 'redux-saga/effects';

// Login Redux States
import {
	createAttributesApi,
	deleteAttributesApi,
	editAttributesApi,
	getAttributesApi,
} from '../../../services/surveys/audience/attributesApi';
import {
	addAttributesError,
	addAttributesSuccess,
	deleteAttributesError,
	deleteAttributesSuccess,
	editAttributesError,
	editAttributesSuccess,
	getAttributesError,
	getAttributesRequest,
	getAttributesSuccess,
} from './actions';
import {
	ADD_ATTRIBUTES_REQUEST,
	DELETE_ATTRIBUTES_REQUEST,
	EDIT_ATTRIBUTES_REQUEST,
	GET_ATTRIBUTES_REQUEST,
} from './actionTypes';

//Include Both Helper File with needed methods

//If role is send successfully send mail link then dispatch redux action's are directly from here.
function* addAttributes({ payload: attributes }) {
	try {
		const response = yield call(createAttributesApi, attributes);
		if (response && response.success) {
			yield put(addAttributesSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(addAttributesError(error));
	}
}
function* getAttributes({ payload: surveyID }) {
	try {
		const response = yield call(getAttributesApi, surveyID);
		if (response && response.success) {
			yield put(getAttributesSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(getAttributesError(error));
	}
}
function* editAttributes({ payload: attrData }) {
	try {
		const response = yield call(editAttributesApi, attrData);
		if (response && response.success) {
			yield put(editAttributesSuccess(response));
			yield put(getAttributesRequest({ survey_id: attrData.survey_id }));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(editAttributesError(error));
	}
}

function* deleteAttributes({ payload: role }) {
	try {
		const response = yield call(deleteAttributesApi, role);
		if (response && response.success) {
			yield put(deleteAttributesSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(deleteAttributesError(error));
	}
}

function* attributesSaga() {
	yield takeEvery(ADD_ATTRIBUTES_REQUEST, addAttributes);
	yield takeEvery(GET_ATTRIBUTES_REQUEST, getAttributes);
	yield takeEvery(EDIT_ATTRIBUTES_REQUEST, editAttributes);
	yield takeEvery(DELETE_ATTRIBUTES_REQUEST, deleteAttributes);
}

export default attributesSaga;
