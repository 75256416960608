import { call, put, takeEvery } from 'redux-saga/effects';

// Login Redux States
import {
	createAudienceAttributesApi,
	deleteAudienceAttributesApi,
	editAudienceAttributesApi,
	getAudienceAttributesApi,
} from '../../../services/surveys/audience/audienceAttributesApi';
import {
	addAudienceAttributesError,
	addAudienceAttributesSuccess,
	deleteAudienceAttributesError,
	deleteAudienceAttributesSuccess,
	editAudienceAttributesError,
	editAudienceAttributesSuccess,
	getAudienceAttributesError,
	getAudienceAttributesSuccess,
} from './actions';
import {
	ADD_AUDIENCE_ATTRIBUTES_REQUEST,
	DELETE_AUDIENCE_ATTRIBUTES_REQUEST,
	EDIT_AUDIENCE_ATTRIBUTES_REQUEST,
	GET_AUDIENCE_ATTRIBUTES_REQUEST,
} from './actionTypes';

//Include Both Helper File with needed methods

//If role is send successfully send mail link then dispatch redux action's are directly from here.
function* addAudienceAttributes({ payload: attributes }) {
	try {
		const response = yield call(createAudienceAttributesApi, attributes);
		if (response && response.success) {
			yield put(addAudienceAttributesSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(addAudienceAttributesError(error));
	}
}
function* getAudienceAttributes({ payload: surveyID }) {
	try {
		const response = yield call(getAudienceAttributesApi, surveyID);
		if (response && response.success) {
			yield put(getAudienceAttributesSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(getAudienceAttributesError(error));
	}
}
function* editAudienceAttributes({ payload: { role, history } }) {
	try {
		const response = yield call(editAudienceAttributesApi, role);
		if (response && response.success) {
			yield put(editAudienceAttributesSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(editAudienceAttributesError(error));
	}
}

function* deleteAudienceAttributes({ payload: role }) {
	try {
		const response = yield call(deleteAudienceAttributesApi, role);
		if (response && response.success) {
			yield put(deleteAudienceAttributesSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(deleteAudienceAttributesError(error));
	}
}

function* attributesSaga() {
	yield takeEvery(ADD_AUDIENCE_ATTRIBUTES_REQUEST, addAudienceAttributes);
	yield takeEvery(GET_AUDIENCE_ATTRIBUTES_REQUEST, getAudienceAttributes);
	yield takeEvery(EDIT_AUDIENCE_ATTRIBUTES_REQUEST, editAudienceAttributes);
	yield takeEvery(DELETE_AUDIENCE_ATTRIBUTES_REQUEST, deleteAudienceAttributes);
}

export default attributesSaga;
