import {
	ADD_AUDIENCE_ATTRIBUTES_ERROR,
	ADD_AUDIENCE_ATTRIBUTES_REQUEST,
	ADD_AUDIENCE_ATTRIBUTES_RESET,
	ADD_AUDIENCE_ATTRIBUTES_SUCCESS,
	DELETE_AUDIENCE_ATTRIBUTES_ERROR,
	DELETE_AUDIENCE_ATTRIBUTES_REQUEST,
	DELETE_AUDIENCE_ATTRIBUTES_SUCCESS,
	EDIT_AUDIENCE_ATTRIBUTES_ERROR,
	EDIT_AUDIENCE_ATTRIBUTES_REQUEST,
	EDIT_AUDIENCE_ATTRIBUTES_RESET,
	EDIT_AUDIENCE_ATTRIBUTES_SUCCESS,
	GET_AUDIENCE_ATTRIBUTES_ERROR,
	GET_AUDIENCE_ATTRIBUTES_REQUEST,
	GET_AUDIENCE_ATTRIBUTES_RESET,
	GET_AUDIENCE_ATTRIBUTES_SUCCESS,
} from './actionTypes';

const initialStateAddAudienceAttributes = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateGetAudienceAttributes = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateDeleteAudienceAttributes = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateEditAudienceAttributes = {
	successData: null,
	error: null,
	loading: null,
};

const addAudienceAttributes = (
	state = initialStateAddAudienceAttributes,
	action
) => {
	switch (action.type) {
		case ADD_AUDIENCE_ATTRIBUTES_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case ADD_AUDIENCE_ATTRIBUTES_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case ADD_AUDIENCE_ATTRIBUTES_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case ADD_AUDIENCE_ATTRIBUTES_RESET:
			state = {
				...state,
				successData: null,
				error: null,
				loading: false,
			};
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const getAudienceAttributes = (
	state = initialStateGetAudienceAttributes,
	action
) => {
	switch (action.type) {
		case GET_AUDIENCE_ATTRIBUTES_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case GET_AUDIENCE_ATTRIBUTES_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case GET_AUDIENCE_ATTRIBUTES_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;

		case GET_AUDIENCE_ATTRIBUTES_RESET:
			state = initialStateGetAudienceAttributes;
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const deleteAudienceAttributes = (
	state = initialStateDeleteAudienceAttributes,
	action
) => {
	switch (action.type) {
		case DELETE_AUDIENCE_ATTRIBUTES_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case DELETE_AUDIENCE_ATTRIBUTES_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case DELETE_AUDIENCE_ATTRIBUTES_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const editAudienceAttributes = (
	state = initialStateEditAudienceAttributes,
	action
) => {
	switch (action.type) {
		case EDIT_AUDIENCE_ATTRIBUTES_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case EDIT_AUDIENCE_ATTRIBUTES_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case EDIT_AUDIENCE_ATTRIBUTES_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case EDIT_AUDIENCE_ATTRIBUTES_RESET:
			state = {
				...state,
				successData: null,
				error: null,
				loading: false,
			};
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export {
	addAudienceAttributes,
	deleteAudienceAttributes,
	getAudienceAttributes,
	editAudienceAttributes,
};
