export const CREATE_QUESTION_BANK_REQUEST = "CREATE_QUESTION_BANK_REQUEST";
export const CREATE_QUESTION_BANK_SUCCESS = "CREATE_QUESTION_BANK_SUCCESS";
export const CREATE_QUESTION_BANK_ERROR = "CREATE_QUESTION_BANK_ERROR";
export const CREATE_QUESTION_BANK_RESET = "CREATE_QUESTION_BANK_RESET";

export const GET_ALL_QUESTION_BANK_REQUEST = "GET_ALL_QUESTION_BANK_REQUEST";
export const GET_ALL_QUESTION_BANK_SUCCESS = "GET_ALL_QUESTION_BANK_SUCCESS";
export const GET_ALL_QUESTION_BANK_ERROR = "GET_ALL_QUESTION_BANK_ERROR";
export const GET_ALL_QUESTION_BANK_RESET = "GET_ALL_QUESTION_BANK_RESET";

export const GET_ONE_QUESTION_BANK_REQUEST = "GET_ONE_QUESTION_BANK_REQUEST";
export const GET_ONE_QUESTION_BANK_SUCCESS = "GET_ONE_QUESTION_BANK_SUCCESS";
export const GET_ONE_QUESTION_BANK_ERROR = "GET_ONE_QUESTION_BANK_ERROR";
export const GET_ONE_QUESTION_BANK_RESET = "GET_ONE_QUESTION_BANK_RESET";

export const SEARCH_QUESTION_BANK_REQUEST = "SEARCH_QUESTION_BANK_REQUEST";
export const SEARCH_QUESTION_BANK_SUCCESS = "SEARCH_QUESTION_BANK_SUCCESS";
export const SEARCH_QUESTION_BANK_ERROR = "SEARCH_QUESTION_BANK_ERROR";
export const SEARCH_QUESTION_BANK_RESET = "SEARCH_QUESTION_BANK_RESET";
