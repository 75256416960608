import PropTypes from "prop-types";
import React from "react";
import { Redirect, Route } from "react-router-dom";

import { useDispatch } from "react-redux";
import { useProfile } from "../components/Hooks/UserHooks";
import { logoutUser } from "../store/auth/login/actions";

//redirect user to login if no user info found
const AuthProtected = (props) => {
  const dispatch = useDispatch();
  const { userProfile, loading } = useProfile();

  /*
    redirect is un-auth access protected routes via url
    */
  if (!userProfile && loading) {
    dispatch(logoutUser());
    return (
      <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }

  return <>{props.children}</>;
};

AuthProtected.propTypes = {
  children: PropTypes.any,
  location: PropTypes.any,
};

// only logged in user or specified user can access route
const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {" "}
            <Component {...props} />{" "}
          </>
        );
      }}
    />
  );
};

AccessRoute.propTypes = {
  component: PropTypes.any,
};

export { AccessRoute, AuthProtected };
