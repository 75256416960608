import {
  ADD_IN_CHARTS_DATA,
  GET_ALL_CHARTS_DATA,
  UPDATE_CHARTS_DATA,
  UPDATE_CHARTS_DATA_DEFAULT,
} from "./actionTypes";

const allChartsViewData = (state = [], action) => {
  switch (action.type) {
    case GET_ALL_CHARTS_DATA:
      state = action.payload;
      break;
    case UPDATE_CHARTS_DATA_DEFAULT:
      var isIndex = state.findIndex(
        (item) => Number(item.question_id) == Number(action.payload.index)
      );

      state[isIndex] = {
        ...state[isIndex],
        graph_type: action.payload.type,
        graph_type_default: action.payload.type,
        sub_graph_type: action.payload.subType,
        chartData: action.payload.axisData,
      };
      state = [...state];
      break;
    case UPDATE_CHARTS_DATA:
      var isIndex1 = state.findIndex(
        (item) => Number(item.question_id) == Number(action.payload.index)
      );
      state[isIndex1] = {
        ...state[isIndex1],
        graph_type: action.payload.type,
        sub_graph_type: action.payload.subType,
      };
      state = [...state];
      break;
    case ADD_IN_CHARTS_DATA:
      var questionAvailable = state.find(
        (item) => Number(item.question_id) == Number(action.payload.index)
      );

      state = [
        ...state,
        {
          question_id: action.payload.index,
          question_type: questionAvailable.question_type,
          graph_type_default: action.payload.type,
          graph_type: action.payload.type,
          sub_graph_type: action.payload.subType,

          filter: 0,
          compare: 0,
          chartData: "",
        },
      ];
      break;

    default:
      state = [...state];
      break;
  }
  return state;
};

// Question Sentiments

export { allChartsViewData };
