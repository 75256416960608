import {
	CHANNEL_DETAILS_ERROR,
	CHANNEL_DETAILS_REQUEST,
	CHANNEL_DETAILS_RESET,
	CHANNEL_DETAILS_SUCCESS,
} from './actionTypes';

const initialStateChannelDetails = {
	successData: null,
	error: null,
	loading: null,
};

const channelDetails = (state = initialStateChannelDetails, action) => {
	switch (action.type) {
		case CHANNEL_DETAILS_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;

		case CHANNEL_DETAILS_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case CHANNEL_DETAILS_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case CHANNEL_DETAILS_RESET:
			state = initialStateChannelDetails;
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export { channelDetails };
