/* MESSAGES */
export const GET_MESSAGES_REQUEST = "GET_MESSAGES_REQUEST";
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";
export const GET_MESSAGES_FAIL = "GET_MESSAGES_FAIL";

// Add Message
export const ADD_MESSAGE = "ADD_MESSAGE";

// Add New Message and Update Previous Message
export const ADD_AND_UPDATE_MESSAGE = "ADD_AND_UPDATE_MESSAGE";

// Edit Message
export const EDIT_MESSAGE = "EDIT_MESSAGE";

// Edit Message
export const RESET_CHAT = "RESET_CHAT";
