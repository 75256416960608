import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocation, withRouter } from "react-router-dom";
//import Components
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";

//import actions
import {
  changeLayout,
  changeLayoutMode,
  changeLayoutPosition,
  changeLayoutWidth,
  changeLeftsidebarSizeType,
  changeLeftsidebarViewType,
  changeSidebarTheme,
  changeTopbarTheme,
  profileRequest,
} from "../../store/actions";

//redux
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import DecryptedUser from "../../common/DecryptedUser";
import OverlayLoader from "../../components/Common/OverlayLoader";

const Layout = (props) => {
  const history = props.history;
  const location = useLocation();
  const isLocation =
    // location.pathname.includes("profile") ||
    location.pathname.includes("dashboard");

  const [headerClass, setHeaderClass] = useState("");
  const [isSidebarChanged, setIsSidebarChanged] = useState("sm");
  const dispatch = useDispatch();

  const { loading, success } = DecryptedUser();

  const {
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
  } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
    leftSidebarType: state.Layout.leftSidebarType,
    layoutModeType: state.Layout.layoutModeType,
    layoutWidthType: state.Layout.layoutWidthType,
    layoutPositionType: state.Layout.layoutPositionType,
    topbarThemeType: state.Layout.topbarThemeType,
    leftsidbarSizeType: state.Layout.leftsidbarSizeType,
    leftSidebarViewType: state.Layout.leftSidebarViewType,
  }));

  /*
    layout settings
    */
  useEffect(() => {
    if (
      layoutType ||
      leftSidebarType ||
      layoutModeType ||
      layoutWidthType ||
      layoutPositionType ||
      topbarThemeType ||
      leftsidbarSizeType ||
      leftSidebarViewType
    ) {
      dispatch(changeLeftsidebarViewType(leftSidebarViewType));
      dispatch(changeLeftsidebarSizeType(leftsidbarSizeType));
      dispatch(changeSidebarTheme(leftSidebarType));
      dispatch(changeLayoutMode(layoutModeType));
      dispatch(changeLayoutWidth(layoutWidthType));
      dispatch(changeLayoutPosition(layoutPositionType));
      dispatch(changeTopbarTheme(topbarThemeType));
      dispatch(changeLayout(layoutType));
    }
  }, [
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    dispatch,
  ]);
  /*
    call dark/light mode
    */
  const onChangeLayoutMode = (value) => {
    if (changeLayoutMode) {
      dispatch(changeLayoutMode(value));
    }
  };

  // class add remove in header
  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });

  // useEffect(() => {
  // 	if (windowSize <= 767) {
  // 		dispatch(changeLayout('vertical'));
  // 	}
  // 	if (windowSize > 1025) {
  // 		dispatch(changeLayout('twocolumn'));
  // 	}
  // }, [windowSize]);

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setHeaderClass("topbar-shadow");
    } else {
      setHeaderClass("");
    }
  }
  useEffect(() => {
    if (!success) dispatch(profileRequest(history));
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem("activeLink", "dashboard");
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title>{process.env.REACT_APP_TAG_NAME} | Dashboard</title>
      </MetaTags>
      {loading && isLocation && <OverlayLoader />}

      <div id="layout-wrapper">
        <Header
          headerClass={headerClass}
          layoutModeType={layoutModeType}
          onChangeLayoutMode={onChangeLayoutMode}
          setIsSidebarChanged={setIsSidebarChanged}
        />
        <Sidebar layoutType={layoutType} isSidebarChanged={isSidebarChanged} />
        <div className="main-content">
          {props.children}
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.object,
  history: PropTypes.any,
};

export default withRouter(Layout);
