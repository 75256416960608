import { call, put, takeEvery } from 'redux-saga/effects';

// Login Redux States
import { userResetPasswordError, userResetPasswordSuccess } from './actions';
import { RESET_PASSWORD } from './actionTypes';

//Include Both Helper File with needed methods

import { resetPasswordApi } from '../../../services/auth/resetPasswordApi';

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* resetPassword({ payload: { user, history } }) {
	try {
		const response = yield call(resetPasswordApi, user);
		if (response && response.success) {
			yield put(userResetPasswordSuccess(response));
			history.push('/login');
		} else {
			throw response;
		}
	} catch (error) {
		yield put(userResetPasswordError(error));
	}
}

function* resetPasswordSaga() {
	yield takeEvery(RESET_PASSWORD, resetPassword);
}

export default resetPasswordSaga;
