import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import {
  createCriteriaApi,
  deleteCriteriaApi,
  editCriteriaApi,
  getCriteriaApi,
  getCriteriaListApi,
} from "../../../services/grading-criteria/criteriaApi";
import {
  addCriteriaError,
  addCriteriaSuccess,
  deleteCriteriaError,
  deleteCriteriaSuccess,
  editCriteriaError,
  editCriteriaSuccess,
  getCriteriaError,
  getCriteriaListError,
  getCriteriaListSuccess,
  getCriteriaSuccess,
} from "./actions";
import {
  ADD_CRITERIA_REQUEST,
  DELETE_CRITERIA_REQUEST,
  EDIT_CRITERIA_REQUEST,
  GET_CRITERIA_LIST_REQUEST,
  GET_CRITERIA_REQUEST,
} from "./actionTypes";

//Include Both Helper File with needed methods

//If role is send successfully send mail link then dispatch redux action's are directly from here.
function* addCriteria({ payload: criteria }) {
  try {
    const response = yield call(createCriteriaApi, criteria);
    if (response && response.success) {
      yield put(addCriteriaSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(addCriteriaError(error));
  }
}
function* getCriteria({ payload: criteria }) {
  try {
    const response = yield call(getCriteriaApi, criteria);
    if (response && response.success) {
      yield put(getCriteriaSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(getCriteriaError(error));
  }
}
function* getCriteriaList({ payload: criteria }) {
  try {
    const response = yield call(getCriteriaListApi, criteria);
    if (response && response.success) {
      yield put(getCriteriaListSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(getCriteriaListError(error));
  }
}
function* editCriteria({ payload: criteria }) {
  try {
    const response = yield call(editCriteriaApi, criteria);
    if (response && response.success) {
      yield put(editCriteriaSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(editCriteriaError(error));
  }
}

function* deleteCriteria({ payload: role }) {
  try {
    const response = yield call(deleteCriteriaApi, role);
    if (response && response.success) {
      yield put(deleteCriteriaSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(deleteCriteriaError(error));
  }
}

function* criteriaSaga() {
  yield takeEvery(ADD_CRITERIA_REQUEST, addCriteria);
  yield takeEvery(GET_CRITERIA_REQUEST, getCriteria);
  yield takeEvery(GET_CRITERIA_LIST_REQUEST, getCriteriaList);
  yield takeEvery(EDIT_CRITERIA_REQUEST, editCriteria);
  yield takeEvery(DELETE_CRITERIA_REQUEST, deleteCriteria);
}

export default criteriaSaga;
