import { create, get } from "../../common/http.service";

const CREATE_USER_PERMISSIONS_URL = "/assign-user-permission";
const GET_USER_PERMISSIONS_URL = "/get-user-permissions";
const UPDATE_USER_PERMISSIONS_URL = "/update-user-permissions";

export const createUserPermissionsApi = async (payload) => {
  const res = await create(CREATE_USER_PERMISSIONS_URL, payload, true);

  return res;
};
export const getUserPermissionsApi = async (payload) => {
  const res = await get(GET_USER_PERMISSIONS_URL, payload, true);

  return res;
};
export const editUserPermissionsApi = async (payload) => {
  const res = await create(UPDATE_USER_PERMISSIONS_URL, payload, true);

  return res;
};
