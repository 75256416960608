import {
  ASSESSMENT_ALREADY_DONE_ERROR,
  ASSESSMENT_ALREADY_DONE_REQUEST,
  ASSESSMENT_ALREADY_DONE_RESET,
  ASSESSMENT_ALREADY_DONE_SUCCESS,
  VIEW_ALL_ASSESSMENT_PUBLIC_ERROR,
  VIEW_ALL_ASSESSMENT_PUBLIC_REQUEST,
  VIEW_ALL_ASSESSMENT_PUBLIC_RESET,
  VIEW_ALL_ASSESSMENT_PUBLIC_SUCCESS,
} from "./actionTypes";

// Invite Member with email
export const viewAllAssessmentPublicRequest = () => {
  return {
    type: VIEW_ALL_ASSESSMENT_PUBLIC_REQUEST,
  };
};

export const viewAllAssessmentPublicSuccess = (message) => {
  return {
    type: VIEW_ALL_ASSESSMENT_PUBLIC_SUCCESS,
    payload: message,
  };
};

export const viewAllAssessmentPublicError = (message) => {
  return {
    type: VIEW_ALL_ASSESSMENT_PUBLIC_ERROR,
    payload: message,
  };
};

export const viewAllAssessmentPublicReset = () => {
  return {
    type: VIEW_ALL_ASSESSMENT_PUBLIC_RESET,
  };
};

// Invite Member with email
export const isAssessmentAlreadyDoneRequest = (surveyInfo) => {
  return {
    type: ASSESSMENT_ALREADY_DONE_REQUEST,
    payload: surveyInfo,
  };
};

export const isAssessmentAlreadyDoneSuccess = (message) => {
  return {
    type: ASSESSMENT_ALREADY_DONE_SUCCESS,
    payload: message,
  };
};

export const isAssessmentAlreadyDoneError = (message) => {
  return {
    type: ASSESSMENT_ALREADY_DONE_ERROR,
    payload: message,
  };
};

export const isAssessmentAlreadyDoneReset = () => {
  return {
    type: ASSESSMENT_ALREADY_DONE_RESET,
  };
};
