import { create, get } from "../../common/http.service";

const CREATE_ROLE_URL = "/create-role";
const GET_ROLE_URL = "/get-role";
const UPDATE_ROLE_URL = "/update-role";

export const createRoleApi = async (payload) => {
  const res = await create(CREATE_ROLE_URL, payload, true);

  return res;
};
export const getRoleApi = async (payload) => {
  const res = await get(GET_ROLE_URL, payload, true);

  return res;
};
export const editRoleApi = async (payload) => {
  const res = await create(UPDATE_ROLE_URL, payload, true);

  return res;
};
