import { call, put, takeEvery } from 'redux-saga/effects';

// Login Redux States
import {
	addChannelsApi,
	getChannelApi,
} from '../../services/channel/channelApis';
import {
	addChannelError,
	addChannelSuccess,
	getChannelError,
	getChannelSuccess,
} from './actions';
import { ADD_CHANNEL_REQUEST, GET_CHANNEL_REQUEST } from './actionTypes';

//If role is send successfully send mail link then dispatch redux action's are directly from here.

function* addChannel({ payload: channel }) {
	try {
		const response = yield call(addChannelsApi, channel);

		if (response && response.success) {
			yield put(addChannelSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(addChannelError(error));
	}
}
function* getChannel({ payload: channel }) {
	try {
		const response = yield call(getChannelApi, channel);
		if (response && response.success) {
			yield put(getChannelSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(getChannelError(error));
	}
}

function* channelSaga() {
	yield takeEvery(ADD_CHANNEL_REQUEST, addChannel);
	yield takeEvery(GET_CHANNEL_REQUEST, getChannel);
}

export default channelSaga;
