import {
  VIEW_ALL_ASSESSMENT_ERROR,
  VIEW_ALL_ASSESSMENT_REQUEST,
  VIEW_ALL_ASSESSMENT_RESET,
  VIEW_ALL_ASSESSMENT_STATS_ERROR,
  VIEW_ALL_ASSESSMENT_STATS_REQUEST,
  VIEW_ALL_ASSESSMENT_STATS_RESET,
  VIEW_ALL_ASSESSMENT_STATS_SUCCESS,
  VIEW_ALL_ASSESSMENT_SUCCESS,
} from "./actionTypes";

// Invite Member with email
export const viewAllAssessmentRequest = (viewType) => {
  return {
    type: VIEW_ALL_ASSESSMENT_REQUEST,
    payload: viewType,
  };
};

export const viewAllAssessmentSuccess = (message) => {
  return {
    type: VIEW_ALL_ASSESSMENT_SUCCESS,
    payload: message,
  };
};

export const viewAllAssessmentError = (message) => {
  return {
    type: VIEW_ALL_ASSESSMENT_ERROR,
    payload: message,
  };
};

export const viewAllAssessmentReset = () => {
  return {
    type: VIEW_ALL_ASSESSMENT_RESET,
  };
};

// Invite Member with email
export const viewAllAssessmentStatsRequest = () => {
  return {
    type: VIEW_ALL_ASSESSMENT_STATS_REQUEST,
  };
};

export const viewAllAssessmentStatsSuccess = (message) => {
  return {
    type: VIEW_ALL_ASSESSMENT_STATS_SUCCESS,
    payload: message,
  };
};

export const viewAllAssessmentStatsError = (message) => {
  return {
    type: VIEW_ALL_ASSESSMENT_STATS_ERROR,
    payload: message,
  };
};

export const viewAllAssessmentStatsReset = () => {
  return {
    type: VIEW_ALL_ASSESSMENT_STATS_RESET,
  };
};
