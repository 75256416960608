export const ADD_AUDIENCE_REQUEST = 'ADD_AUDIENCE_REQUEST';
export const ADD_AUDIENCE_SUCCESS = 'ADD_AUDIENCE_SUCCESS';
export const ADD_AUDIENCE_ERROR = 'ADD_AUDIENCE_ERROR';
export const ADD_AUDIENCE_RESET = 'ADD_AUDIENCE_RESET';
export const ADD_AUDIENCE_DUPE = 'ADD_AUDIENCE_DUPE';
export const ADD_AUDIENCE_DUPE_RESET = 'ADD_AUDIENCE_DUPE_RESET';
export const ADD_AUDIENCE_NON_DUPE = 'ADD_AUDIENCE_NON_DUPE';
export const ADD_AUDIENCE_NON_DUPE_RESET = 'ADD_AUDIENCE_NON_DUPE_RESET';

export const GET_AUDIENCE_REQUEST = 'GET_AUDIENCE_REQUEST';
export const GET_AUDIENCE_SUCCESS = 'GET_AUDIENCE_SUCCESS';
export const GET_AUDIENCE_ERROR = 'GET_AUDIENCE_ERROR';
export const GET_AUDIENCE_RESET = 'GET_AUDIENCE_RESET';

export const DELETE_AUDIENCE_REQUEST = 'DELETE_AUDIENCE_REQUEST';
export const DELETE_AUDIENCE_SUCCESS = 'DELETE_AUDIENCE_SUCCESS';
export const DELETE_AUDIENCE_ERROR = 'DELETE_AUDIENCE_ERROR';
export const DELETE_AUDIENCE_RESET = 'DELETE_AUDIENCE_RESET';

export const EDIT_AUDIENCE_REQUEST = 'EDIT_AUDIENCE_REQUEST';
export const EDIT_AUDIENCE_SUCCESS = 'EDIT_AUDIENCE_SUCCESS';
export const EDIT_AUDIENCE_ERROR = 'EDIT_AUDIENCE_ERROR';
export const EDIT_AUDIENCE_RESET = 'EDIT_AUDIENCE_RESET';
