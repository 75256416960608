export const CREATE_ASSESSMENT_REQUEST = "CREATE_ASSESSMENT_REQUEST";
export const CREATE_ASSESSMENT_REQUEST_OTHER =
  "CREATE_ASSESSMENT_REQUEST_OTHER";
export const CREATE_ASSESSMENT_LOCAL_STORAGE =
  "CREATE_ASSESSMENT_LOCAL_STORAGE";
export const CREATE_ASSESSMENT_SUCCESS = "CREATE_ASSESSMENT_SUCCESS";
export const CREATE_ASSESSMENT_ERROR = "CREATE_ASSESSMENT_ERROR";
export const CREATE_ASSESSMENT_RESET = "CREATE_ASSESSMENT_RESET";

export const COPY_ASSESSMENT_REQUEST = "COPY_ASSESSMENT_REQUEST";
export const COPY_ASSESSMENT_SUCCESS = "COPY_ASSESSMENT_SUCCESS";
export const COPY_ASSESSMENT_ERROR = "COPY_ASSESSMENT_ERROR";
export const COPY_ASSESSMENT_RESET = "COPY_ASSESSMENT_RESET";
