import {
	FORGET_PASSWORD,
	FORGET_PASSWORD_ERROR,
	FORGET_PASSWORD_SUCCESS,
} from './actionTypes';

const initialState = {
	successData: null,
	error: null,
	loading: null,
};

const forgetPassword = (state = initialState, action) => {
	switch (action.type) {
		case FORGET_PASSWORD:
			state = {
				...state,

				loading: true,
			};
			break;
		case FORGET_PASSWORD_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case FORGET_PASSWORD_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default forgetPassword;
