import {
	ADD_DIS_BG_ERROR,
	ADD_DIS_BG_REQUEST,
	ADD_DIS_BG_RESET,
	ADD_DIS_BG_SUCCESS,
} from './actionTypes';

// Invite Member with email

export const addDisBGRequest = (imgPayload) => {
	return {
		type: ADD_DIS_BG_REQUEST,
		payload: imgPayload,
	};
};

export const addDisBGSuccess = (message) => {
	return {
		type: ADD_DIS_BG_SUCCESS,
		payload: message,
	};
};

export const addDisBGError = (message) => {
	return {
		type: ADD_DIS_BG_ERROR,
		payload: message,
	};
};

export const addDisBGReset = () => {
	return {
		type: ADD_DIS_BG_RESET,
	};
};
