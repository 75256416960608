import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import {
  createSegmentApi,
  viewSegmentApi,
} from "../../../../../services/surveys/create-survey/createSegmentsApi";
import {
  addSegmentError,
  addSegmentSuccess,
  getSegmentsError,
  getSegmentsSuccess,
} from "./actions";
import { ADD_SEGMENT_REQUEST, GET_SEGMENTS_REQUEST } from "./actionTypes";

function* addSegment({ payload: segment }) {
  try {
    const response = yield call(createSegmentApi, segment);

    if (response && response.success) {
      yield put(addSegmentSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(addSegmentError(error));
  }
}

function* getSegments() {
  try {
    const response = yield call(viewSegmentApi, "");
    if (response && response.success) {
      yield put(getSegmentsSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(getSegmentsError(error));
  }
}

function* segmentSaga() {
  yield takeEvery(ADD_SEGMENT_REQUEST, addSegment);
  yield takeEvery(GET_SEGMENTS_REQUEST, getSegments);
}

export default segmentSaga;
