import {
  ASSESSMENT_ALREADY_DONE_ERROR,
  ASSESSMENT_ALREADY_DONE_REQUEST,
  ASSESSMENT_ALREADY_DONE_RESET,
  ASSESSMENT_ALREADY_DONE_SUCCESS,
  VIEW_ALL_ASSESSMENT_PUBLIC_ERROR,
  VIEW_ALL_ASSESSMENT_PUBLIC_REQUEST,
  VIEW_ALL_ASSESSMENT_PUBLIC_RESET,
  VIEW_ALL_ASSESSMENT_PUBLIC_SUCCESS,
} from "./actionTypes";

const initialStateViewAllAssessmentPublic = {
  successData: null,
  error: null,
  loading: null,
};

const initialStateAssessmentAlreadyDone = {
  email: null,
  successData: null,
  error: null,
  loading: null,
};

const viewAllAssessmentPublic = (
  state = initialStateViewAllAssessmentPublic,
  action
) => {
  switch (action.type) {
    case VIEW_ALL_ASSESSMENT_PUBLIC_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case VIEW_ALL_ASSESSMENT_PUBLIC_SUCCESS:
      state = {
        ...state,
        successData: action.payload,
        loading: false,
      };
      break;
    case VIEW_ALL_ASSESSMENT_PUBLIC_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case VIEW_ALL_ASSESSMENT_PUBLIC_RESET:
      state = { ...state, successData: null, error: null, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
const assessmentDoneAlready = (
  state = initialStateAssessmentAlreadyDone,
  action
) => {
  switch (action.type) {
    case ASSESSMENT_ALREADY_DONE_REQUEST:
      state = {
        ...state,
        loading: true,
        email: action.payload.email,
      };
      break;
    case ASSESSMENT_ALREADY_DONE_SUCCESS:
      state = {
        ...state,
        successData: action.payload,
        loading: false,
      };
      break;
    case ASSESSMENT_ALREADY_DONE_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case ASSESSMENT_ALREADY_DONE_RESET:
      state = initialStateAssessmentAlreadyDone;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { assessmentDoneAlready, viewAllAssessmentPublic };
