import { create } from '../common/http.service';

const REGISTER_URL = '/register';

export const registerApi = async (payload) => {
	const res = await create(REGISTER_URL, payload, false);

	return res;
};

// External client register on sp after clicking the link in his email
