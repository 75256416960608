import {
	ADD_ATTRIBUTES_ERROR,
	ADD_ATTRIBUTES_REQUEST,
	ADD_ATTRIBUTES_RESET,
	ADD_ATTRIBUTES_SUCCESS,
	DELETE_ATTRIBUTES_ERROR,
	DELETE_ATTRIBUTES_REQUEST,
	DELETE_ATTRIBUTES_SUCCESS,
	EDIT_ATTRIBUTES_ERROR,
	EDIT_ATTRIBUTES_REQUEST,
	EDIT_ATTRIBUTES_RESET,
	EDIT_ATTRIBUTES_SUCCESS,
	GET_ATTRIBUTES_ERROR,
	GET_ATTRIBUTES_REQUEST,
	GET_ATTRIBUTES_RESET,
	GET_ATTRIBUTES_SUCCESS,
} from './actionTypes';

// Create Attributes
export const addAttributesRequest = (attributes) => {
	return {
		type: ADD_ATTRIBUTES_REQUEST,
		payload: attributes,
	};
};

export const addAttributesSuccess = (message) => {
	return {
		type: ADD_ATTRIBUTES_SUCCESS,
		payload: message,
	};
};

export const addAttributesError = (message) => {
	return {
		type: ADD_ATTRIBUTES_ERROR,
		payload: message,
	};
};

export const addAttributesReset = () => {
	return {
		type: ADD_ATTRIBUTES_RESET,
	};
};

// Get Attributes
export const getAttributesRequest = (surveyID) => {
	return {
		type: GET_ATTRIBUTES_REQUEST,
		payload: surveyID,
	};
};

export const getAttributesSuccess = (message) => {
	return {
		type: GET_ATTRIBUTES_SUCCESS,
		payload: message,
	};
};

export const getAttributesError = (message) => {
	return {
		type: GET_ATTRIBUTES_ERROR,
		payload: message,
	};
};

export const getAttributesReset = (message) => {
	return {
		type: GET_ATTRIBUTES_RESET,
	};
};

// Delete Attributes
export const deleteAttributesRequest = (role) => {
	return {
		type: DELETE_ATTRIBUTES_REQUEST,
		payload: role,
	};
};

export const deleteAttributesSuccess = (message) => {
	return {
		type: DELETE_ATTRIBUTES_SUCCESS,
		payload: message,
	};
};

export const deleteAttributesError = (message) => {
	return {
		type: DELETE_ATTRIBUTES_ERROR,
		payload: message,
	};
};

// Delete Attributes
export const editAttributesRequest = (attrData) => {
	return {
		type: EDIT_ATTRIBUTES_REQUEST,
		payload: attrData,
	};
};

export const editAttributesSuccess = (message) => {
	return {
		type: EDIT_ATTRIBUTES_SUCCESS,
		payload: message,
	};
};

export const editAttributesError = (message) => {
	return {
		type: EDIT_ATTRIBUTES_ERROR,
		payload: message,
	};
};
export const editAttributesReset = () => {
	return {
		type: EDIT_ATTRIBUTES_RESET,
	};
};
