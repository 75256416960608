import {
  UPDATE_ASSESSMENT_ERROR,
  UPDATE_ASSESSMENT_REQUEST,
  UPDATE_ASSESSMENT_RESET,
  UPDATE_ASSESSMENT_SUCCESS,
} from "./actionTypes";

// Invite Member with email
export const updateAssessmentRequest = (surveyPayload) => {
  return {
    type: UPDATE_ASSESSMENT_REQUEST,
    payload: surveyPayload,
  };
};

export const updateAssessmentSuccess = (message) => {
  return {
    type: UPDATE_ASSESSMENT_SUCCESS,
    payload: message,
  };
};

export const updateAssessmentError = (message) => {
  return {
    type: UPDATE_ASSESSMENT_ERROR,
    payload: message,
  };
};

export const updateAssessmentReset = () => {
  return {
    type: UPDATE_ASSESSMENT_RESET,
  };
};
