export const ADD_CRITERIA_REQUEST = "ADD_CRITERIA_REQUEST";
export const ADD_CRITERIA_SUCCESS = "ADD_CRITERIA_SUCCESS";
export const ADD_CRITERIA_ERROR = "ADD_CRITERIA_ERROR";
export const ADD_CRITERIA_RESET = "ADD_CRITERIA_RESET";
export const ADD_CRITERIA_DUPE = "ADD_CRITERIA_DUPE";
export const ADD_CRITERIA_DUPE_RESET = "ADD_CRITERIA_DUPE_RESET";
export const ADD_CRITERIA_NON_DUPE = "ADD_CRITERIA_NON_DUPE";
export const ADD_CRITERIA_NON_DUPE_RESET = "ADD_CRITERIA_NON_DUPE_RESET";

export const GET_CRITERIA_REQUEST = "GET_CRITERIA_REQUEST";
export const GET_CRITERIA_SUCCESS = "GET_CRITERIA_SUCCESS";
export const GET_CRITERIA_ERROR = "GET_CRITERIA_ERROR";
export const GET_CRITERIA_RESET = "GET_CRITERIA_RESET";

export const GET_CRITERIA_LIST_REQUEST = "GET_CRITERIA_LIST_REQUEST";
export const GET_CRITERIA_LIST_SUCCESS = "GET_CRITERIA_LIST_SUCCESS";
export const GET_CRITERIA_LIST_ERROR = "GET_CRITERIA_LIST_ERROR";
export const GET_CRITERIA_LIST_RESET = "GET_CRITERIA_LIST_RESET";

export const DELETE_CRITERIA_REQUEST = "DELETE_CRITERIA_REQUEST";
export const DELETE_CRITERIA_SUCCESS = "DELETE_CRITERIA_SUCCESS";
export const DELETE_CRITERIA_ERROR = "DELETE_CRITERIA_ERROR";
export const DELETE_CRITERIA_RESET = "DELETE_CRITERIA_RESET";

export const EDIT_CRITERIA_REQUEST = "EDIT_CRITERIA_REQUEST";
export const EDIT_CRITERIA_SUCCESS = "EDIT_CRITERIA_SUCCESS";
export const EDIT_CRITERIA_ERROR = "EDIT_CRITERIA_ERROR";
export const EDIT_CRITERIA_RESET = "EDIT_CRITERIA_RESET";
