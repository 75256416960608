import {
	CLONE_AUDIENCE_ERROR,
	CLONE_AUDIENCE_REQUEST,
	CLONE_AUDIENCE_RESET,
	CLONE_AUDIENCE_SUCCESS,
} from './actionTypes';

const initialStateCloneAudience = {
	successData: null,
	error: null,
	loading: null,
};
// const initialStateGetAudienceAttributes = {
// 	successData: null,
// 	error: null,
// 	loading: null,
// };
// const initialStateDeleteAudienceAttributes = {
// 	successData: null,
// 	error: null,
// 	loading: null,
// };
// const initialStateEditAudienceAttributes = {
// 	successData: null,
// 	error: null,
// 	loading: null,
// };

const cloneAudience = (state = initialStateCloneAudience, action) => {
	switch (action.type) {
		case CLONE_AUDIENCE_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case CLONE_AUDIENCE_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case CLONE_AUDIENCE_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case CLONE_AUDIENCE_RESET:
			state = {
				...state,
				successData: null,
				error: null,
				loading: false,
			};
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
// const getAudienceAttributes = (
// 	state = initialStateGetAudienceAttributes,
// 	action
// ) => {
// 	switch (action.type) {
// 		case GET_AUDIENCE_ATTRIBUTES_REQUEST:
// 			state = {
// 				...state,
// 				successData: null,
// 				loading: true,
// 			};
// 			break;
// 		case GET_AUDIENCE_ATTRIBUTES_SUCCESS:
// 			state = {
// 				...state,
// 				successData: action.payload,
// 				loading: false,
// 			};
// 			break;
// 		case GET_AUDIENCE_ATTRIBUTES_ERROR:
// 			state = { ...state, error: action.payload, loading: false };
// 			break;

// 		case GET_AUDIENCE_ATTRIBUTES_RESET:
// 			state = initialStateGetAudienceAttributes;
// 			break;
// 		default:
// 			state = { ...state };
// 			break;
// 	}
// 	return state;
// };
// const deleteAudienceAttributes = (
// 	state = initialStateDeleteAudienceAttributes,
// 	action
// ) => {
// 	switch (action.type) {
// 		case DELETE_AUDIENCE_ATTRIBUTES_REQUEST:
// 			state = {
// 				...state,
// 				successData: null,
// 				loading: true,
// 			};
// 			break;
// 		case DELETE_AUDIENCE_ATTRIBUTES_SUCCESS:
// 			state = {
// 				...state,
// 				successData: action.payload,
// 				loading: false,
// 			};
// 			break;
// 		case DELETE_AUDIENCE_ATTRIBUTES_ERROR:
// 			state = { ...state, error: action.payload, loading: false };
// 			break;
// 		default:
// 			state = { ...state };
// 			break;
// 	}
// 	return state;
// };
// const editAudienceAttributes = (
// 	state = initialStateEditAudienceAttributes,
// 	action
// ) => {
// 	switch (action.type) {
// 		case EDIT_AUDIENCE_ATTRIBUTES_REQUEST:
// 			state = {
// 				...state,
// 				successData: null,
// 				loading: true,
// 			};
// 			break;
// 		case EDIT_AUDIENCE_ATTRIBUTES_SUCCESS:
// 			state = {
// 				...state,
// 				successData: action.payload,
// 				loading: false,
// 			};
// 			break;
// 		case EDIT_AUDIENCE_ATTRIBUTES_ERROR:
// 			state = { ...state, error: action.payload, loading: false };
// 			break;
// 		case EDIT_AUDIENCE_ATTRIBUTES_RESET:
// 			state = {
// 				...state,
// 				successData: null,
// 				error: null,
// 				loading: false,
// 			};
// 			break;
// 		default:
// 			state = { ...state };
// 			break;
// 	}
// 	return state;
// };

export {
	cloneAudience,
	// deleteAudienceAttributes,
	// getAudienceAttributes,
	// editAudienceAttributes,
};
