import { call, put, takeEvery } from 'redux-saga/effects';
import { getChannelDetailsApi } from '../../../../../services/surveys/view-survey/viewSurveyApi';

// Login Redux States

import { channelDetailsError, channelDetailsSuccess } from './actions';

import { CHANNEL_DETAILS_REQUEST } from './actionTypes';

function* channelDetails({ payload: channelID }) {
	try {
		const response = yield call(getChannelDetailsApi, channelID);
		if (response && response.success) {
			yield put(channelDetailsSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(channelDetailsError(error));
	}
}

function* channelDetailsSaga() {
	yield takeEvery(CHANNEL_DETAILS_REQUEST, channelDetails);
}

export default channelDetailsSaga;
