import {
  RESET_UPLOAD_IMAGE,
  UPLOAD_IMAGE_ERROR,
  UPLOAD_IMAGE_REQUEST,
  UPLOAD_IMAGE_SUCCESS,
} from "./actionTypes";

// Profile Image
export const uploadImageRequest = (imgPayload) => {
  return {
    type: UPLOAD_IMAGE_REQUEST,
    payload: imgPayload,
  };
};
export const uploadImageSuccess = (user) => {
  return {
    type: UPLOAD_IMAGE_SUCCESS,
    payload: user,
  };
};

export const uploadImageError = (error) => {
  return {
    type: UPLOAD_IMAGE_ERROR,
    payload: error,
  };
};

export const resetUploadImage = () => {
  return {
    type: RESET_UPLOAD_IMAGE,
  };
};
