export const CLONE_AUDIENCE_REQUEST = 'CLONE_AUDIENCE_REQUEST';
export const CLONE_AUDIENCE_SUCCESS = 'CLONE_AUDIENCE_SUCCESS';
export const CLONE_AUDIENCE_ERROR = 'CLONE_AUDIENCE_ERROR';
export const CLONE_AUDIENCE_RESET = 'CLONE_AUDIENCE_RESET';

// export const GET_AUDIENCE_ATTRIBUTES_REQUEST =
// 	'GET_AUDIENCE_ATTRIBUTES_REQUEST';
// export const GET_AUDIENCE_ATTRIBUTES_SUCCESS =
// 	'GET_AUDIENCE_ATTRIBUTES_SUCCESS';
// export const GET_AUDIENCE_ATTRIBUTES_ERROR = 'GET_AUDIENCE_ATTRIBUTES_ERROR';
// export const GET_AUDIENCE_ATTRIBUTES_RESET = 'GET_AUDIENCE_ATTRIBUTES_RESET';

// export const DELETE_AUDIENCE_ATTRIBUTES_REQUEST =
// 	'DELETE_AUDIENCE_ATTRIBUTES_REQUEST';
// export const DELETE_AUDIENCE_ATTRIBUTES_SUCCESS =
// 	'DELETE_AUDIENCE_ATTRIBUTES_SUCCESS';
// export const DELETE_AUDIENCE_ATTRIBUTES_ERROR =
// 	'DELETE_AUDIENCE_ATTRIBUTES_ERROR';
// export const DELETE_AUDIENCE_ATTRIBUTES_RESET =
// 	'DELETE_AUDIENCE_ATTRIBUTES_RESET';

// export const EDIT_AUDIENCE_ATTRIBUTES_REQUEST =
// 	'EDIT_AUDIENCE_ATTRIBUTES_REQUEST';
// export const EDIT_AUDIENCE_ATTRIBUTES_SUCCESS =
// 	'EDIT_AUDIENCE_ATTRIBUTES_SUCCESS';
// export const EDIT_AUDIENCE_ATTRIBUTES_ERROR = 'EDIT_AUDIENCE_ATTRIBUTES_ERROR';
// export const EDIT_AUDIENCE_ATTRIBUTES_RESET = 'EDIT_AUDIENCE_ATTRIBUTES_RESET';
