import {
	ADD_ROLE_PERMISSIONS_ERROR,
	ADD_ROLE_PERMISSIONS_REQUEST,
	ADD_ROLE_PERMISSIONS_RESET,
	ADD_ROLE_PERMISSIONS_SUCCESS,
	DELETE_ROLE_PERMISSIONS_ERROR,
	DELETE_ROLE_PERMISSIONS_REQUEST,
	DELETE_ROLE_PERMISSIONS_SUCCESS,
	EDIT_ROLE_PERMISSIONS_ERROR,
	EDIT_ROLE_PERMISSIONS_REQUEST,
	EDIT_ROLE_PERMISSIONS_RESET,
	EDIT_ROLE_PERMISSIONS_SUCCESS,
	GET_ROLE_PERMISSIONS_ERROR,
	GET_ROLE_PERMISSIONS_REQUEST,
	GET_ROLE_PERMISSIONS_RESET,
	GET_ROLE_PERMISSIONS_SUCCESS,
} from './actionTypes';

const initialStateAddRolePermissions = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateGetRolePermissions = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateDeleteRolePermissions = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateEditRolePermissions = {
	successData: null,
	error: null,
	loading: null,
};

const addRolePermissions = (state = initialStateAddRolePermissions, action) => {
	switch (action.type) {
		case ADD_ROLE_PERMISSIONS_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case ADD_ROLE_PERMISSIONS_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case ADD_ROLE_PERMISSIONS_ERROR:
			state = {
				...state,
				error: action.payload,
				loading: false,
			};
			break;
		case ADD_ROLE_PERMISSIONS_RESET:
			state = {
				...state,
				successData: null,
				error: null,
				loading: false,
			};
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const getRolePermissions = (state = initialStateGetRolePermissions, action) => {
	switch (action.type) {
		case GET_ROLE_PERMISSIONS_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case GET_ROLE_PERMISSIONS_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case GET_ROLE_PERMISSIONS_ERROR:
			state = {
				...state,
				error: action.payload,
				loading: false,
			};
			break;

		case GET_ROLE_PERMISSIONS_RESET:
			state = initialStateGetRolePermissions;
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const deleteRolePermissions = (
	state = initialStateDeleteRolePermissions,
	action
) => {
	switch (action.type) {
		case DELETE_ROLE_PERMISSIONS_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case DELETE_ROLE_PERMISSIONS_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case DELETE_ROLE_PERMISSIONS_ERROR:
			state = {
				...state,
				error: action.payload,
				loading: false,
			};
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const editRolePermissions = (
	state = initialStateEditRolePermissions,
	action
) => {
	switch (action.type) {
		case EDIT_ROLE_PERMISSIONS_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case EDIT_ROLE_PERMISSIONS_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case EDIT_ROLE_PERMISSIONS_ERROR:
			state = {
				...state,
				error: action.payload,
				loading: false,
			};
			break;
		case EDIT_ROLE_PERMISSIONS_RESET:
			state = {
				...state,
				successData: null,
				error: null,
				loading: false,
			};
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export {
	addRolePermissions,
	deleteRolePermissions,
	getRolePermissions,
	editRolePermissions,
};
