import { create } from '../../common/http.service';

const ADD_IMAGE_URL = '/upload-image';
const CREATE_SURVEY_URL = '/create-campaign';

export const addImageApi = async (payload) => {
	const res = await create(ADD_IMAGE_URL, payload, true);

	return res;
};

export const createSurveyApi = async (payload) => {
	const res = await create(CREATE_SURVEY_URL, payload, true);

	return res;
};
