import {
  GET_ASSESSMENT_ANALYSIS_SUMMARY_ERROR,
  GET_ASSESSMENT_ANALYSIS_SUMMARY_REQUEST,
  GET_ASSESSMENT_ANALYSIS_SUMMARY_RESET,
  GET_ASSESSMENT_ANALYSIS_SUMMARY_SUCCESS,
} from "./actionTypes";

const initialStateAssessmentAnalysisSummary = {
  successData: null,
  error: null,
  loading: null,
};

const assessmentAnalysisSummary = (
  state = initialStateAssessmentAnalysisSummary,
  action
) => {
  switch (action.type) {
    case GET_ASSESSMENT_ANALYSIS_SUMMARY_REQUEST:
      state = {
        ...state,
        successData: null,
        loading: true,
      };
      break;

    case GET_ASSESSMENT_ANALYSIS_SUMMARY_SUCCESS:
      state = {
        ...state,
        successData: action.payload,
        loading: false,
      };
      break;
    case GET_ASSESSMENT_ANALYSIS_SUMMARY_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case GET_ASSESSMENT_ANALYSIS_SUMMARY_RESET:
      state = initialStateAssessmentAnalysisSummary;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { assessmentAnalysisSummary };
