import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { addImageApi } from "../../../../../services/surveys/create-survey/createSurveysApi";
import {
  addCompanyLogoError,
  addCompanyLogoSuccess,
  addIntroBGError,
  addIntroBGSuccess,
} from "./actions";
import { ADD_COMPANY_LOGO_REQUEST, ADD_INTRO_BG_REQUEST } from "./actionTypes";

function* addCompanyLogo({ payload: imgPayload }) {
  try {
    const response = yield call(addImageApi, imgPayload);
    if (response && response.success) {
      yield put(addCompanyLogoSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(addCompanyLogoError(error));
  }
}

function* addIntroBG({ payload: imgPayload }) {
  try {
    const response = yield call(addImageApi, imgPayload);
    if (response && response.success) {
      yield put(addIntroBGSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(addIntroBGError(error));
  }
}

function* introSaga() {
  yield takeEvery(ADD_COMPANY_LOGO_REQUEST, addCompanyLogo);
  yield takeEvery(ADD_INTRO_BG_REQUEST, addIntroBG);
}

export default introSaga;
