import { create } from "../../common/http.service";

const ADD_IMAGE_URL = "/upload-image";
const CREATE_QUIZ_URL = "/create-quiz";

export const addImageApi = async (payload) => {
  const res = await create(ADD_IMAGE_URL, payload, true);

  return res;
};

export const createQuizApi = async (payload) => {
  const res = await create(CREATE_QUIZ_URL, payload, true);

  return res;
};
