import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "reactstrap";

// Import Data
import { withTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import navdata from "./LayoutMenuData";
//i18n
const SidebarContent = ({ t }) => {
  const location = useLocation();
  const [linkID, setLinkID] = useState(null);
  const navData = navdata().props.children;

  useEffect(() => {
    let activeLink = localStorage.getItem("activeLink");
    if (activeLink) setLinkID(activeLink);
  }, [location]);

  return (
    <React.Fragment>
      {/* menu Items */}
      {(navData || []).map((item, key) => {
        return (
          <React.Fragment key={key}>
            {/* Main Header */}
            {item["isHeader"] ? (
              <li className="menu-title">
                <span data-key="t-menu">{t(item.label)}</span>
              </li>
            ) : item.subItems ? (
              <li className="nav-item">
                <Link
                  onClick={(e) => {
                    item.click(e);
                    setLinkID(item.id);
                    localStorage.setItem("activeLink", item.id);
                  }}
                  className={`nav-link menu-link ${
                    item.id.includes(linkID) ? "active" : ""
                  } ${item.stateVariables ? "nav_indicator" : ""}`}
                  to={item.link ? item.link : "/#"}
                  data-bs-toggle="collapse"
                >
                  <img
                    src={item.icon_fill}
                    alt={item.label}
                    width={19}
                    height={19}
                  />
                  <span className="ms-3" data-key="t-apps">
                    {t(item.label)}
                  </span>
                </Link>
                <Collapse
                  className="menu-dropdown"
                  isOpen={item.stateVariables}
                  id="sidebarApps"
                >
                  <ul className="nav nav-sm flex-column test">
                    {/* subItms  */}
                    {item.subItems &&
                      (item.subItems || []).map((subItem, key) => (
                        <React.Fragment key={key}>
                          {!subItem.isChildItem ? (
                            <li className="nav-item">
                              <Link
                                to={subItem.link ? subItem.link : "/#"}
                                className="nav-link"
                              >
                                {t(subItem.label)}
                              </Link>
                            </li>
                          ) : (
                            <li className="nav-item">
                              <Link
                                onClick={subItem.click}
                                className={`nav-link ${
                                  subItem.stateVariables ? "nav_indicator" : ""
                                }`}
                                to="/#"
                                data-bs-toggle="collapse"
                              >
                                {" "}
                                {t(subItem.label)}
                              </Link>
                              <Collapse
                                className="menu-dropdown"
                                isOpen={subItem.stateVariables}
                                id="sidebarEcommerce"
                              >
                                <ul className="nav nav-sm flex-column">
                                  {/* child subItms  */}
                                  {subItem.childItems &&
                                    (subItem.childItems || []).map(
                                      (childItem, key) => (
                                        <li className="nav-item" key={key}>
                                          <Link
                                            to={
                                              childItem.link
                                                ? childItem.link
                                                : "/#"
                                            }
                                            className="nav-link"
                                          >
                                            {t(childItem.label)}
                                          </Link>
                                        </li>
                                      )
                                    )}
                                </ul>
                              </Collapse>
                            </li>
                          )}
                        </React.Fragment>
                      ))}
                  </ul>
                </Collapse>
              </li>
            ) : (
              <li className="nav-item">
                <Link
                  className={`nav-link menu-link ${
                    item.id.includes(linkID) ? " active" : ""
                  }`}
                  to={item.link ? item.link : "/#"}
                  onClick={() => {
                    setLinkID(item.id);
                    localStorage.setItem("activeLink", item.id);
                  }}
                  target={item.link.pathname ? "_blank" : "_self"}
                  rel="noreferrer"
                >
                  <img
                    src={item.icon_fill}
                    alt={item.label}
                    width={19}
                    height={19}
                  />{" "}
                  <span className="ms-3" data-key="t-apps">
                    {t(item.label)}
                  </span>
                </Link>
              </li>
            )}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  layoutType: PropTypes.string,
  location: PropTypes.object,
};

export default withRouter(withTranslation()(SidebarContent));
