export const VIEW_ALL_ASSESSMENT_REQUEST = "VIEW_ALL_ASSESSMENT_REQUEST";
export const VIEW_ALL_ASSESSMENT_SUCCESS = "VIEW_ALL_ASSESSMENT_SUCCESS";
export const VIEW_ALL_ASSESSMENT_ERROR = "VIEW_ALL_ASSESSMENT_ERROR";
export const VIEW_ALL_ASSESSMENT_RESET = "VIEW_ALL_ASSESSMENT_RESET";

export const VIEW_ALL_ASSESSMENT_STATS_REQUEST =
  "VIEW_ALL_ASSESSMENT_STATS_REQUEST";
export const VIEW_ALL_ASSESSMENT_STATS_SUCCESS =
  "VIEW_ALL_ASSESSMENT_STATS_SUCCESS";
export const VIEW_ALL_ASSESSMENT_STATS_ERROR =
  "VIEW_ALL_ASSESSMENT_STATS_ERROR";
export const VIEW_ALL_ASSESSMENT_STATS_RESET =
  "VIEW_ALL_ASSESSMENT_STATS_RESET";
