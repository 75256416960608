import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const Footer = () => {
	return (
		<React.Fragment>
			<footer className='footer d-flex justify-content-center align-items-center'>
				<Container fluid>
					<Row className=' justify-content-center align-items-center'>
						<Col sm={6}>
							<div className='text-sm-start d-none d-sm-block'>
								{new Date().getFullYear()} © Antlere.{' '}
								<span className='ms-2 text-white'>
									{process.env.REACT_APP_VERSION_CONTROL}
								</span>{' '}
							</div>
						</Col>
						<Col sm={6}>
							<div className='text-sm-end d-none d-sm-block'>
								Powered By Abacus Consulting Technology Pvt Ltd.
							</div>
						</Col>
					</Row>
				</Container>
			</footer>
		</React.Fragment>
	);
};

export default Footer;
