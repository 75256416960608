import { create } from '../../common/http.service';

const ADD_RECURRENCE_URL = '/create-recurrence';
const UPDATE_RECURRENCE_URL = '/update-question-segments ';
const VIEW_RECURRENCE_URL = '/get-recurrence ';

export const createRecurrenceApi = async (payload) => {
	const res = await create(ADD_RECURRENCE_URL, payload, true);
	return res;
};
export const updateRecurrenceApi = async (payload) => {
	const res = await create(UPDATE_RECURRENCE_URL, payload, true);
	return res;
};
export const viewRecurrenceApi = async (payload) => {
	const res = await create(VIEW_RECURRENCE_URL, payload, true);
	return res;
};
