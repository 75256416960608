import {
	CLONE_AUDIENCE_ERROR,
	CLONE_AUDIENCE_REQUEST,
	CLONE_AUDIENCE_RESET,
	CLONE_AUDIENCE_SUCCESS,
} from './actionTypes';

// Create AudienceAttributes
export const cloneAudienceRequest = (attributes) => {
	return {
		type: CLONE_AUDIENCE_REQUEST,
		payload: attributes,
	};
};

export const cloneAudienceSuccess = (message) => {
	return {
		type: CLONE_AUDIENCE_SUCCESS,
		payload: message,
	};
};

export const cloneAudienceError = (message) => {
	return {
		type: CLONE_AUDIENCE_ERROR,
		payload: message,
	};
};

export const cloneAudienceReset = () => {
	return {
		type: CLONE_AUDIENCE_RESET,
	};
};

// Get AudienceAttributes
// export const getAudienceAttributesRequest = (surveyID) => {
// 	return {
// 		type: GET_AUDIENCE_ATTRIBUTES_REQUEST,
// 		payload: surveyID,
// 	};
// };

// export const getAudienceAttributesSuccess = (message) => {
// 	return {
// 		type: GET_AUDIENCE_ATTRIBUTES_SUCCESS,
// 		payload: message,
// 	};
// };

// export const getAudienceAttributesError = (message) => {
// 	return {
// 		type: GET_AUDIENCE_ATTRIBUTES_ERROR,
// 		payload: message,
// 	};
// };

// export const getAudienceAttributesReset = (message) => {
// 	return {
// 		type: GET_AUDIENCE_ATTRIBUTES_RESET,
// 	};
// };

// // Delete AudienceAttributes
// export const deleteAudienceAttributesRequest = (role) => {
// 	return {
// 		type: DELETE_AUDIENCE_ATTRIBUTES_REQUEST,
// 		payload: role,
// 	};
// };

// export const deleteAudienceAttributesSuccess = (message) => {
// 	return {
// 		type: DELETE_AUDIENCE_ATTRIBUTES_SUCCESS,
// 		payload: message,
// 	};
// };

// export const deleteAudienceAttributesError = (message) => {
// 	return {
// 		type: DELETE_AUDIENCE_ATTRIBUTES_ERROR,
// 		payload: message,
// 	};
// };

// // Delete AudienceAttributes
// export const editAudienceAttributesRequest = (role, history) => {
// 	return {
// 		type: EDIT_AUDIENCE_ATTRIBUTES_REQUEST,
// 		payload: { role, history },
// 	};
// };

// export const editAudienceAttributesSuccess = (message) => {
// 	return {
// 		type: EDIT_AUDIENCE_ATTRIBUTES_SUCCESS,
// 		payload: message,
// 	};
// };

// export const editAudienceAttributesError = (message) => {
// 	return {
// 		type: EDIT_AUDIENCE_ATTRIBUTES_ERROR,
// 		payload: message,
// 	};
// };
// export const editAudienceAttributesReset = () => {
// 	return {
// 		type: EDIT_AUDIENCE_ATTRIBUTES_RESET,
// 	};
// };
