import {
	REGISTER_USER,
	REGISTER_USER_FAILED,
	REGISTER_USER_RESET,
	REGISTER_USER_SUCCESSFUL,
} from './actionTypes';

const initialState = {
	error: null,
	message: null,
	loading: null,
	user: null,
};

const userRegistration = (state = initialState, action) => {
	switch (action.type) {
		case REGISTER_USER:
			state = {
				...state,
				loading: true,
				user: null,
			};

			break;
		case REGISTER_USER_SUCCESSFUL:
			state = {
				...state,
				loading: false,
				user: action.payload,
			};
			break;
		case REGISTER_USER_FAILED:
			state = {
				...state,

				loading: false,
				error: action.payload,
			};
			break;
		case REGISTER_USER_RESET:
			state = initialState;
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default userRegistration;
