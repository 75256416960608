import { ASSIGN_USER_PERMISSIONS } from "./actionTypes";

const initialState = {
  View_Profile: false,
  Update_Profile: false,
  View_Settings: false,
  Change_Password: false,
  View_Roles: false,
  Add_New_Role: false,
  Edit_Role: false,
  Assign_Role_Permissions: false,
  View_Team_List: false,
  Manage_Team_Members: false,
  View_Survey: false,
  Create_New_Survey: false,
  Edit_New_Survey: false,
  Preview_Survey: false,
  Share_Survey: false,
  Publish_Unpublish_Survey: false,
  View_Audience: false,
  Create_Audience: false,
  Import_Audience: false,
  Create_Audience_Attributes: false,
  Edit_Audience_Attributes: false,
  View_Email_Templates: false,
  Create_Email_Templates: false,
  Edit_Email_Templates: false,
  Send_Email_Template: false,
  Edit_Audience: false,
  View_Survey_Analysis: false,
  View_Survey_Individual_Responses: false,
  View_Survey_Insights_Data_Trends: false,
  View_Survey_Question_Summaries: false,
  Edit_Survey_Responses: false,
  Delete_Survey_Responses: false,
  Export_Survey_Responses: false,
  Print_Survey_Analysis: false,
  View_Sending_Emails: false,
  Create_Sending_Emails: false,
  Delete_Sending_Emails: false,
  Upload_Survey_Logo: false,
  Upload_Survey_Background_Image: false,
  Create_Recurrence: false,
  Edit_Recurrence: false,
  View_Survey_Channels: false,
  Create_Survey_Channels: false,
  Manage_Survey_Skipping: false,
  Manage_Survey_Piping: false,
  Manage_Survey_Word_Cloud: false,
  Export_Survey_Results: false,
  Create_Multi_language_Survey: false,
  Compare_Survey_Results: false,
  Filter_Survey_Results: false,
  Create_Survey_Sections: false,
  Hide_Abacus_Footer: false,
  Survey_Complete_Redirect_URL: false,
  Survey_Channel_QR: false,
  Sentiment_Analysis: false,
  Question_Segments: false,
};

const assignPermissions = (state = initialState, { type, payload }) => {
  switch (type) {
    case ASSIGN_USER_PERMISSIONS:
      payload.forEach((item) => {
        if (item.id == 1) {
          state = { ...state, View_Profile: true };
        }
        if (item.id == 2) {
          state = { ...state, Update_Profile: true };
        }
        if (item.id == 3) {
          state = { ...state, View_Settings: true };
        }
        if (item.id == 4) {
          state = { ...state, Change_Password: true };
        }
        if (item.id == 5) {
          state = { ...state, View_Roles: true };
        }
        if (item.id == 6) {
          state = { ...state, Add_New_Role: true };
        }
        if (item.id == 7) {
          state = { ...state, Edit_Role: true };
        }
        if (item.id == 8) {
          state = { ...state, Assign_Role_Permissions: true };
        }
        if (item.id == 9) {
          state = { ...state, View_Team_List: true };
        }
        if (item.id == 10) {
          state = { ...state, Manage_Team_Members: true };
        }
        if (item.id == 11) {
          state = { ...state, View_Survey: true };
        }
        if (item.id == 12) {
          state = { ...state, Create_New_Survey: true };
        }
        if (item.id == 13) {
          state = { ...state, Edit_New_Survey: true };
        }
        if (item.id == 14) {
          state = { ...state, Preview_Survey: true };
        }
        if (item.id == 15) {
          state = { ...state, Share_Survey: true };
        }
        if (item.id == 16) {
          state = { ...state, Publish_Unpublish_Survey: true };
        }
        if (item.id == 17) {
          state = { ...state, View_Audience: true };
        }
        if (item.id == 18) {
          state = { ...state, Create_Audience: true };
        }
        if (item.id == 19) {
          state = { ...state, Import_Audience: true };
        }
        if (item.id == 20) {
          state = { ...state, Create_Audience_Attributes: true };
        }
        if (item.id == 21) {
          state = { ...state, Edit_Audience_Attributes: true };
        }
        if (item.id == 22) {
          state = { ...state, View_Email_Templates: true };
        }
        if (item.id == 23) {
          state = { ...state, Create_Email_Templates: true };
        }
        if (item.id == 24) {
          state = { ...state, Edit_Email_Templates: true };
        }
        if (item.id == 25) {
          state = { ...state, Send_Email_Template: true };
        }
        if (item.id == 26) {
          state = { ...state, Edit_Audience: true };
        }
        if (item.id == 27) {
          state = { ...state };
        }
        if (item.id == 28) {
          state = { ...state };
        }
        if (item.id == 29) {
          state = { ...state, View_Survey_Analysis: true };
        }
        if (item.id == 30) {
          state = { ...state, View_Survey_Individual_Responses: true };
        }
        if (item.id == 31) {
          state = { ...state, View_Survey_Insights_Data_Trends: true };
        }
        if (item.id == 32) {
          state = { ...state, View_Survey_Question_Summaries: true };
        }
        if (item.id == 33) {
          state = { ...state, Edit_Survey_Responses: true };
        }
        if (item.id == 34) {
          state = { ...state, Delete_Survey_Responses: true };
        }
        if (item.id == 35) {
          state = { ...state, Export_Survey_Responses: true };
        }
        if (item.id == 36) {
          state = { ...state, Print_Survey_Analysis: true };
        }
        if (item.id == 37) {
          state = { ...state, View_Sending_Emails: true };
        }
        if (item.id == 38) {
          state = { ...state, Create_Sending_Emails: true };
        }
        if (item.id == 39) {
          state = { ...state, Delete_Sending_Emails: true };
        }
        if (item.id == 40) {
          state = { ...state, Upload_Survey_Logo: true };
        }
        if (item.id == 41) {
          state = { ...state, Upload_Survey_Background_Image: true };
        }
        if (item.id == 42) {
          state = { ...state };
        }
        if (item.id == 43) {
          state = { ...state };
        }
        if (item.id == 44) {
          state = { ...state, Create_Recurrence: true };
        }
        if (item.id == 45) {
          state = { ...state, Edit_Recurrence: true };
        }
        if (item.id == 46) {
          state = { ...state, View_Survey_Channels: true };
        }
        if (item.id == 47) {
          state = { ...state, Create_Survey_Channels: true };
        }
        if (item.id == 48) {
          state = { ...state, Manage_Survey_Skipping: true };
        }
        if (item.id == 49) {
          state = { ...state, Manage_Survey_Piping: true };
        }
        if (item.id == 50) {
          state = { ...state, Manage_Survey_Word_Cloud: true };
        }
        if (item.id == 51) {
          state = { ...state, Export_Survey_Results: true };
        }
        if (item.id == 52) {
          state = { ...state, Create_Multi_language_Survey: true };
        }
        if (item.id == 53) {
          state = { ...state, Compare_Survey_Results: true };
        }
        if (item.id == 54) {
          state = { ...state, Filter_Survey_Results: true };
        }
        if (item.id == 55) {
          state = { ...state, Create_Survey_Sections: true };
        }
        if (item.id == 56) {
          state = { ...state, Hide_Abacus_Footer: true };
        }
        if (item.id == 57) {
          state = { ...state, Survey_Complete_Redirect_URL: true };
        }
        if (item.id == 58) {
          state = { ...state, Survey_Channel_QR: true };
        }
        if (item.id == 59) {
          state = { ...state, Sentiment_Analysis: true };
        }
        if (item.id == 60) {
          state = { ...state, Question_Segments: true };
        }
      });
      // payload.package_permissions.forEach((item) => {
      //   if (item.id == 1) {
      //     state = { ...state, View_Profile: true };
      //   }
      //   if (item.id == 2) {
      //     state = { ...state, Update_Profile: true };
      //   }
      //   if (item.id == 3) {
      //     state = { ...state, View_Settings: true };
      //   }
      //   if (item.id == 4) {
      //     state = { ...state, Change_Password: true };
      //   }
      //   if (item.id == 5) {
      //     state = { ...state, View_Roles: true };
      //   }
      //   if (item.id == 6) {
      //     state = { ...state, Add_New_Role: true };
      //   }
      //   if (item.id == 7) {
      //     state = { ...state, Edit_Role: true };
      //   }
      //   if (item.id == 8) {
      //     state = { ...state, Assign_Role_Permissions: true };
      //   }
      //   if (item.id == 9) {
      //     state = { ...state, View_Team_List: true };
      //   }
      //   if (item.id == 10) {
      //     state = { ...state, Manage_Team_Members: true };
      //   }
      //   if (item.id == 11) {
      //     state = { ...state, View_Survey: true };
      //   }
      //   if (item.id == 12) {
      //     state = { ...state, Create_New_Survey: true };
      //   }
      //   if (item.id == 13) {
      //     state = { ...state, Edit_New_Survey: true };
      //   }
      //   if (item.id == 14) {
      //     state = { ...state, Preview_Survey: true };
      //   }
      //   if (item.id == 15) {
      //     state = { ...state, Share_Survey: true };
      //   }
      //   if (item.id == 16) {
      //     state = { ...state, Publish_Unpublish_Survey: true };
      //   }
      //   if (item.id == 17) {
      //     state = { ...state, View_Audience: true };
      //   }
      //   if (item.id == 18) {
      //     state = { ...state, Create_Audience: true };
      //   }
      //   if (item.id == 19) {
      //     state = { ...state, Import_Audience: true };
      //   }
      //   if (item.id == 20) {
      //     state = { ...state, Create_Audience_Attributes: true };
      //   }
      //   if (item.id == 21) {
      //     state = { ...state, Edit_Audience_Attributes: true };
      //   }
      //   if (item.id == 22) {
      //     state = { ...state, View_Email_Templates: true };
      //   }
      //   if (item.id == 23) {
      //     state = { ...state, Create_Email_Templates: true };
      //   }
      //   if (item.id == 24) {
      //     state = { ...state, Edit_Email_Templates: true };
      //   }
      //   if (item.id == 25) {
      //     state = { ...state, Send_Email_Template: true };
      //   }
      //   if (item.id == 26) {
      //     state = { ...state, Edit_Audience: true };
      //   }
      //   if (item.id == 27) {
      //     state = { ...state };
      //   }
      //   if (item.id == 28) {
      //     state = { ...state };
      //   }
      //   if (item.id == 29) {
      //     state = { ...state, View_Survey_Analysis: true };
      //   }
      //   if (item.id == 30) {
      //     state = { ...state, View_Survey_Individual_Responses: true };
      //   }
      //   if (item.id == 31) {
      //     state = { ...state, View_Survey_Insights_Data_Trends: true };
      //   }
      //   if (item.id == 32) {
      //     state = { ...state, View_Survey_Question_Summaries: true };
      //   }
      //   if (item.id == 33) {
      //     state = { ...state, Edit_Survey_Responses: true };
      //   }
      //   if (item.id == 34) {
      //     state = { ...state, Delete_Survey_Responses: true };
      //   }
      //   if (item.id == 35) {
      //     state = { ...state, Export_Survey_Responses: true };
      //   }
      //   if (item.id == 36) {
      //     state = { ...state, Print_Survey_Analysis: true };
      //   }
      //   if (item.id == 37) {
      //     state = { ...state, View_Sending_Emails: true };
      //   }
      //   if (item.id == 38) {
      //     state = { ...state, Create_Sending_Emails: true };
      //   }
      //   if (item.id == 39) {
      //     state = { ...state, Delete_Sending_Emails: true };
      //   }
      //   if (item.id == 40) {
      //     state = { ...state, Upload_Survey_Logo: true };
      //   }
      //   if (item.id == 41) {
      //     state = { ...state, Upload_Survey_Background_Image: true };
      //   }
      //   if (item.id == 42) {
      //     state = { ...state };
      //   }
      //   if (item.id == 43) {
      //     state = { ...state };
      //   }
      //   if (item.id == 44) {
      //     state = { ...state, Create_Recurrence: true };
      //   }
      //   if (item.id == 45) {
      //     state = { ...state, Edit_Recurrence: true };
      //   }
      //   if (item.id == 46) {
      //     state = { ...state, View_Survey_Channels: true };
      //   }
      //   if (item.id == 47) {
      //     state = { ...state, Create_Survey_Channels: true };
      //   }
      //   if (item.id == 48) {
      //     state = { ...state, Manage_Survey_Skipping: true };
      //   }
      //   if (item.id == 49) {
      //     state = { ...state, Manage_Survey_Piping: true };
      //   }
      //   if (item.id == 50) {
      //     state = { ...state, Manage_Survey_Word_Cloud: true };
      //   }
      //   if (item.id == 51) {
      //     state = { ...state, Export_Survey_Results: true };
      //   }
      //   if (item.id == 52) {
      //     state = { ...state, Create_Multi_language_Survey: true };
      //   }
      //   if (item.id == 53) {
      //     state = { ...state, Compare_Survey_Results: true };
      //   }
      //   if (item.id == 54) {
      //     state = { ...state, Filter_Survey_Results: true };
      //   }
      //   if (item.id == 55) {
      //     state = { ...state, Create_Survey_Sections: true };
      //   }
      //   if (item.id == 56) {
      //     state = { ...state, Hide_Abacus_Footer: true };
      //   }
      //   if (item.id == 57) {
      //     state = { ...state, Survey_Complete_Redirect_URL: true };
      //   }
      //   if (item.id == 58) {
      //     state = { ...state, Survey_Channel_QR: true };
      //   }
      //   if (item.id == 59) {
      //     state = { ...state, Sentiment_Analysis: true };
      //   }
      // });

      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default assignPermissions;
