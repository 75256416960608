import { create, get } from '../../common/http.service';

const CREATE_AUDIENCE_URL = '/create-people';
const GET_AUDIENCE_URL = '/get-people-list-attributes';
const UPDATE_AUDIENCE_URL = '/update-people-with-attributes';
const DELETE_AUDIENCE_URL = '/remove-people';

export const createAudienceApi = async (payload) => {
	const res = await create(CREATE_AUDIENCE_URL, payload, true);

	return res;
};
export const getAudienceApi = async (payload) => {
	const res = await create(GET_AUDIENCE_URL, payload, true);

	return res;
};
export const editAudienceApi = async (payload) => {
	const res = await create(UPDATE_AUDIENCE_URL, payload, true);

	return res;
};
export const deleteAudienceApi = async (payload) => {
	const res = await create(DELETE_AUDIENCE_URL, payload, true);

	return res;
};
