import {
  GET_QUESTION_SENTIMENTS_ERROR,
  GET_QUESTION_SENTIMENTS_REQUEST,
  GET_QUESTION_SENTIMENTS_RESET,
  GET_QUESTION_SENTIMENTS_SUCCESS,
} from "./actionTypes";

// Question Sentiments

export const getQuestionSentimentsRequest = (surveyPayload) => {
  return {
    type: GET_QUESTION_SENTIMENTS_REQUEST,
    payload: surveyPayload,
  };
};

export const getQuestionSentimentsSuccess = (message) => {
  return {
    type: GET_QUESTION_SENTIMENTS_SUCCESS,
    payload: message,
  };
};

export const getQuestionSentimentsError = (message) => {
  return {
    type: GET_QUESTION_SENTIMENTS_ERROR,
    payload: message,
  };
};

export const getQuestionSentimentsReset = () => {
  return {
    type: GET_QUESTION_SENTIMENTS_RESET,
  };
};
