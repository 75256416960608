import {
	ADD_EMAIL_TEMPLATE_ERROR,
	ADD_EMAIL_TEMPLATE_REQUEST,
	ADD_EMAIL_TEMPLATE_RESET,
	ADD_EMAIL_TEMPLATE_SUCCESS,
	DELETE_EMAIL_TEMPLATE_ERROR,
	DELETE_EMAIL_TEMPLATE_REQUEST,
	DELETE_EMAIL_TEMPLATE_SUCCESS,
	EDIT_EMAIL_TEMPLATE_ERROR,
	EDIT_EMAIL_TEMPLATE_REQUEST,
	EDIT_EMAIL_TEMPLATE_RESET,
	EDIT_EMAIL_TEMPLATE_SUCCESS,
	EMAIL_TEMPLATE_SHARE_ERROR,
	EMAIL_TEMPLATE_SHARE_REQUEST,
	EMAIL_TEMPLATE_SHARE_RESET,
	EMAIL_TEMPLATE_SHARE_SUCCESS,
	GET_EMAIL_TEMPLATE_ERROR,
	GET_EMAIL_TEMPLATE_LIST_ERROR,
	GET_EMAIL_TEMPLATE_LIST_REQUEST,
	GET_EMAIL_TEMPLATE_LIST_RESET,
	GET_EMAIL_TEMPLATE_LIST_SUCCESS,
	GET_EMAIL_TEMPLATE_REQUEST,
	GET_EMAIL_TEMPLATE_RESET,
	GET_EMAIL_TEMPLATE_SUCCESS,
} from './actionTypes';

// Create EmailTemplate
export const addEmailTemplateRequest = (emailTemplate) => {
	return {
		type: ADD_EMAIL_TEMPLATE_REQUEST,
		payload: emailTemplate,
	};
};

export const addEmailTemplateSuccess = (message) => {
	return {
		type: ADD_EMAIL_TEMPLATE_SUCCESS,
		payload: message,
	};
};

export const addEmailTemplateError = (message) => {
	return {
		type: ADD_EMAIL_TEMPLATE_ERROR,
		payload: message,
	};
};

export const addEmailTemplateReset = () => {
	return {
		type: ADD_EMAIL_TEMPLATE_RESET,
	};
};

// Get EmailTemplate
export const getEmailTemplateRequest = (surveyID) => {
	return {
		type: GET_EMAIL_TEMPLATE_REQUEST,
		payload: surveyID,
	};
};

export const getEmailTemplateSuccess = (message) => {
	return {
		type: GET_EMAIL_TEMPLATE_SUCCESS,
		payload: message,
	};
};

export const getEmailTemplateError = (message) => {
	return {
		type: GET_EMAIL_TEMPLATE_ERROR,
		payload: message,
	};
};

export const getEmailTemplateReset = (message) => {
	return {
		type: GET_EMAIL_TEMPLATE_RESET,
	};
};

// Get EmailTemplate
export const getEmailTemplateListRequest = (surveyID) => {
	return {
		type: GET_EMAIL_TEMPLATE_LIST_REQUEST,
		payload: surveyID,
	};
};

export const getEmailTemplateListSuccess = (message) => {
	return {
		type: GET_EMAIL_TEMPLATE_LIST_SUCCESS,
		payload: message,
	};
};

export const getEmailTemplateListError = (message) => {
	return {
		type: GET_EMAIL_TEMPLATE_LIST_ERROR,
		payload: message,
	};
};

export const getEmailTemplateListReset = (message) => {
	return {
		type: GET_EMAIL_TEMPLATE_LIST_RESET,
	};
};

// Delete EmailTemplate
export const deleteEmailTemplateRequest = (role) => {
	return {
		type: DELETE_EMAIL_TEMPLATE_REQUEST,
		payload: role,
	};
};

export const deleteEmailTemplateSuccess = (message) => {
	return {
		type: DELETE_EMAIL_TEMPLATE_SUCCESS,
		payload: message,
	};
};

export const deleteEmailTemplateError = (message) => {
	return {
		type: DELETE_EMAIL_TEMPLATE_ERROR,
		payload: message,
	};
};

// Delete EmailTemplate
export const editEmailTemplateRequest = (attrData) => {
	return {
		type: EDIT_EMAIL_TEMPLATE_REQUEST,
		payload: attrData,
	};
};

export const editEmailTemplateSuccess = (message) => {
	return {
		type: EDIT_EMAIL_TEMPLATE_SUCCESS,
		payload: message,
	};
};

export const editEmailTemplateError = (message) => {
	return {
		type: EDIT_EMAIL_TEMPLATE_ERROR,
		payload: message,
	};
};
export const editEmailTemplateReset = () => {
	return {
		type: EDIT_EMAIL_TEMPLATE_RESET,
	};
};

// Create EmailTemplate
export const emailTemplateShareRequest = (emailTemplate) => {
	return {
		type: EMAIL_TEMPLATE_SHARE_REQUEST,
		payload: emailTemplate,
	};
};

export const emailTemplateShareSuccess = (message) => {
	return {
		type: EMAIL_TEMPLATE_SHARE_SUCCESS,
		payload: message,
	};
};

export const emailTemplateShareError = (message) => {
	return {
		type: EMAIL_TEMPLATE_SHARE_ERROR,
		payload: message,
	};
};

export const emailTemplateShareReset = () => {
	return {
		type: EMAIL_TEMPLATE_SHARE_RESET,
	};
};
