import { call, put, takeEvery } from 'redux-saga/effects';

// Login Redux States
import {
	addPermissionsError,
	addPermissionsSuccess,
	editPermissionsError,
	editPermissionsSuccess,
	getPermissionsError,
	getPermissionsSuccess,
} from './actions';
import {
	ADD_PERMISSION_REQUEST,
	EDIT_PERMISSION_REQUEST,
	GET_PERMISSION_REQUEST,
} from './actionTypes';

//Include Both Helper File with needed methods

import {
	createPermissionApi,
	editPermissionApi,
	getPermissionApi,
} from '../../services/auth/permission/permissionApi.js';

//If role is send successfully send mail link then dispatch redux action's are directly from here.
function* addPermissions({ payload: { permission, history } }) {
	try {
		const response = yield call(createPermissionApi, permission);
		if (response && response.success) {
			yield put(addPermissionsSuccess(response));
			history.push('/view-permissions');
		} else {
			throw response;
		}
	} catch (error) {
		yield put(addPermissionsError(error));
	}
}
function* getPermissions() {
	try {
		const response = yield call(getPermissionApi);
		if (response && response.success) {
			yield put(getPermissionsSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(getPermissionsError(error));
	}
}
function* editPermissions({ payload: { permission, history } }) {
	try {
		const response = yield call(editPermissionApi, permission);
		if (response && response.success) {
			yield put(editPermissionsSuccess(response));
			history.push('/view-permissions');
		} else {
			throw response;
		}
	} catch (error) {
		yield put(editPermissionsError(error));
	}
}

function* permissionsSaga() {
	yield takeEvery(ADD_PERMISSION_REQUEST, addPermissions);
	yield takeEvery(GET_PERMISSION_REQUEST, getPermissions);
	yield takeEvery(EDIT_PERMISSION_REQUEST, editPermissions);
}

export default permissionsSaga;
