import CryptoJS from "crypto-js";
import { useSelector } from "react-redux";

const DecryptedUser = () => {
  const { userProfile, loading, error, success } = useSelector(
    (state) => state.profile
  );
  const { data } = userProfile || {};
  const { user } = data || {};

  let decryptUser = {};

  for (const key in user) {
    if (Object.hasOwnProperty.call(user, key)) {
      decryptUser[key] = CryptoJS.AES.decrypt(
        `${user[key]}`,
        `${process.env.REACT_APP_HASH_KEY}`
      ).toString(CryptoJS.enc.Utf8);
    }
  }

  return { decryptUser, userProfile, loading, error, success };
};

export default DecryptedUser;
