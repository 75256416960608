import { create, get } from "../common/http.service";

const PROFILE_URL = "/user";
const PROFILE_UPDATE_URL = "/update-profile";

export const profileApi = async () => {
  const res = await get(PROFILE_URL, true, null);

  return res;
};

export const editProfileApi = async (payload) => {
  const res = await create(PROFILE_UPDATE_URL, payload, true);
  return res;
};

// External client register on sp after clicking the link in his email
