import { create } from '../../common/http.service';

const ADD_SEGMENT_URL = '/create-question-segments';
const UPDATE_SEGMENT_URL = '/update-question-segments ';
const VIEW_SEGMENT_URL = '/get-question-segments-list ';

export const createSegmentApi = async (payload) => {
	const res = await create(ADD_SEGMENT_URL, payload, true);
	return res;
};
export const updateSegmentApi = async (payload) => {
	const res = await create(UPDATE_SEGMENT_URL, payload, true);
	return res;
};
export const viewSegmentApi = async (payload) => {
	const res = await create(VIEW_SEGMENT_URL, payload, true);
	return res;
};
