import {
  ADD_TEMPLATE_ERROR,
  ADD_TEMPLATE_REQUEST,
  ADD_TEMPLATE_RESET,
  ADD_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_ERROR,
  DELETE_TEMPLATE_REQUEST,
  DELETE_TEMPLATE_SUCCESS,
  GET_TEMPLATE_ERROR,
  GET_TEMPLATE_REQUEST,
  GET_TEMPLATE_RESET,
  GET_TEMPLATE_SUCCESS,
  VIEW_ALL_TEMPLATE_ERROR,
  VIEW_ALL_TEMPLATE_REQUEST,
  VIEW_ALL_TEMPLATE_RESET,
  VIEW_ALL_TEMPLATE_SUCCESS,
} from "./actionTypes";

const initialStateAddTemplate = {
  successData: null,
  error: null,
  loading: null,
};
const initialStateGetTemplate = {
  successData: null,
  error: null,
  loading: null,
};

const initialStateDeleteTemplate = {
  successData: null,
  error: null,
  loading: null,
};

const initialStateViewAllTemplate = {
  successData: null,
  error: null,
  loading: null,
};

const addTemplate = (state = initialStateAddTemplate, action) => {
  switch (action.type) {
    case ADD_TEMPLATE_REQUEST:
      state = {
        successData: null,
        error: null,
        loading: true,
      };
      break;
    case ADD_TEMPLATE_SUCCESS:
      state = {
        ...state,
        successData: action.payload,
        loading: false,
      };
      break;
    case ADD_TEMPLATE_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case ADD_TEMPLATE_RESET:
      state = { ...state, successData: null, error: null, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
const getTemplate = (state = initialStateGetTemplate, action) => {
  switch (action.type) {
    case GET_TEMPLATE_REQUEST:
      state = {
        ...state,
        loading: true,
        error: null,
      };
      break;
    case GET_TEMPLATE_SUCCESS:
      state = {
        ...state,
        successData: action.payload,
        loading: false,
      };
      break;
    case GET_TEMPLATE_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_TEMPLATE_RESET:
      state = initialStateGetTemplate;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

const deleteTemplate = (state = initialStateDeleteTemplate, action) => {
  switch (action.type) {
    case DELETE_TEMPLATE_REQUEST:
      state = {
        successData: null,
        error: null,
        loading: true,
      };
      break;
    case DELETE_TEMPLATE_SUCCESS:
      state = {
        ...state,
        successData: action.payload,
        loading: false,
      };
      break;
    case DELETE_TEMPLATE_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

const viewAllTemplate = (state = initialStateViewAllTemplate, action) => {
  const { keepState } = action.payload || {};

  switch (action.type) {
    case VIEW_ALL_TEMPLATE_REQUEST:
      state = keepState
        ? {
            ...state,
            error: null,
            loading: true,
          }
        : {
            successData: null,
            error: null,
            loading: true,
          };
      break;
    case VIEW_ALL_TEMPLATE_SUCCESS:
      state = {
        error: null,
        successData: action.payload,
        loading: false,
      };
      break;
    case VIEW_ALL_TEMPLATE_ERROR:
      state = { successData: null, error: action.payload, loading: false };
      break;
    case VIEW_ALL_TEMPLATE_RESET:
      state = { ...state, successData: null, error: null, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { addTemplate, deleteTemplate, getTemplate, viewAllTemplate };
