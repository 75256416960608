import {
	ADD_ROLE_ERROR,
	ADD_ROLE_REQUEST,
	ADD_ROLE_RESET,
	ADD_ROLE_SUCCESS,
	DELETE_ROLE_ERROR,
	DELETE_ROLE_REQUEST,
	DELETE_ROLE_SUCCESS,
	EDIT_ROLE_ERROR,
	EDIT_ROLE_REQUEST,
	EDIT_ROLE_RESET,
	EDIT_ROLE_SUCCESS,
	GET_ROLE_ERROR,
	GET_ROLE_REQUEST,
	GET_ROLE_RESET,
	GET_ROLE_SUCCESS,
} from './actionTypes';

const initialStateAddRole = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateGetRole = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateDeleteRole = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateEditRole = {
	successData: null,
	error: null,
	loading: null,
};

const addRole = (state = initialStateAddRole, action) => {
	switch (action.type) {
		case ADD_ROLE_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case ADD_ROLE_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case ADD_ROLE_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case ADD_ROLE_RESET:
			state = { ...state, successData: null, error: null, loading: false };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const getRole = (state = initialStateGetRole, action) => {
	switch (action.type) {
		case GET_ROLE_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case GET_ROLE_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case GET_ROLE_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;

		case GET_ROLE_RESET:
			state = initialStateGetRole;
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const deleteRole = (state = initialStateDeleteRole, action) => {
	switch (action.type) {
		case DELETE_ROLE_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case DELETE_ROLE_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case DELETE_ROLE_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const editRole = (state = initialStateEditRole, action) => {
	switch (action.type) {
		case EDIT_ROLE_REQUEST:
			state = {
				...state,
				successData: {},
				loading: true,
			};
			break;
		case EDIT_ROLE_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case EDIT_ROLE_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case EDIT_ROLE_RESET:
			state = { ...state, successData: null, error: null, loading: false };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export { addRole, deleteRole, getRole, editRole };
