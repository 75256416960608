import {
  ADD_CRITERIA_DUPE,
  ADD_CRITERIA_DUPE_RESET,
  ADD_CRITERIA_ERROR,
  ADD_CRITERIA_NON_DUPE,
  ADD_CRITERIA_NON_DUPE_RESET,
  ADD_CRITERIA_REQUEST,
  ADD_CRITERIA_RESET,
  ADD_CRITERIA_SUCCESS,
  DELETE_CRITERIA_ERROR,
  DELETE_CRITERIA_REQUEST,
  DELETE_CRITERIA_SUCCESS,
  EDIT_CRITERIA_ERROR,
  EDIT_CRITERIA_REQUEST,
  EDIT_CRITERIA_RESET,
  EDIT_CRITERIA_SUCCESS,
  GET_CRITERIA_ERROR,
  GET_CRITERIA_LIST_ERROR,
  GET_CRITERIA_LIST_REQUEST,
  GET_CRITERIA_LIST_RESET,
  GET_CRITERIA_LIST_SUCCESS,
  GET_CRITERIA_REQUEST,
  GET_CRITERIA_RESET,
  GET_CRITERIA_SUCCESS,
} from "./actionTypes";

const initialStateAddCriteria = {
  successData: null,
  error: null,
  loading: null,
};
const initialStateAddCriteriaDupCheck = {
  duplicates: null,
  noDuplicates: null,
};
const initialStateGetCriteria = {
  successData: null,
  error: null,
  loading: null,
};
const initialStateGetCriteriaList = {
  successData: null,
  error: null,
  loading: null,
};
const initialStateDeleteCriteria = {
  successData: null,
  error: null,
  loading: null,
};
const initialStateEditCriteria = {
  successData: null,
  error: null,
  loading: null,
};

const addCriteria = (state = initialStateAddCriteria, action) => {
  switch (action.type) {
    case ADD_CRITERIA_REQUEST:
      state = {
        error: null,
        successData: null,
        loading: true,
      };
      break;
    case ADD_CRITERIA_SUCCESS:
      state = {
        ...state,
        successData: action.payload,
        loading: false,
      };
      break;
    case ADD_CRITERIA_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case ADD_CRITERIA_RESET:
      state = {
        ...state,
        successData: null,
        error: null,
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
const addCriteriaDupCheck = (
  state = initialStateAddCriteriaDupCheck,
  action
) => {
  switch (action.type) {
    case ADD_CRITERIA_DUPE:
      state = {
        ...state,
        duplicates: action.payload,
      };
      break;
    case ADD_CRITERIA_DUPE_RESET:
      state = {
        ...state,
        duplicates: null,
      };
      break;
    case ADD_CRITERIA_NON_DUPE:
      state = { ...state, noDuplicates: action.payload };
      break;
    case ADD_CRITERIA_NON_DUPE_RESET:
      state = {
        ...state,
        noDuplicates: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
const getCriteria = (state = initialStateGetCriteria, action) => {
  switch (action.type) {
    case GET_CRITERIA_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_CRITERIA_SUCCESS:
      state = {
        ...state,
        successData: action.payload,
        loading: false,
        error: null,
      };
      break;
    case GET_CRITERIA_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        successData: null,
      };
      break;

    case GET_CRITERIA_RESET:
      state = initialStateGetCriteria;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
const getCriteriaList = (state = initialStateGetCriteriaList, action) => {
  switch (action.type) {
    case GET_CRITERIA_LIST_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_CRITERIA_LIST_SUCCESS:
      state = {
        ...state,
        successData: action.payload,
        loading: false,
        error: null,
      };
      break;
    case GET_CRITERIA_LIST_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        successData: null,
      };
      break;

    case GET_CRITERIA_LIST_RESET:
      state = initialStateGetCriteria;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
const deleteCriteria = (state = initialStateDeleteCriteria, action) => {
  switch (action.type) {
    case DELETE_CRITERIA_REQUEST:
      state = {
        error: null,
        successData: null,
        loading: true,
      };
      break;
    case DELETE_CRITERIA_SUCCESS:
      state = {
        ...state,
        successData: action.payload,
        loading: false,
      };
      break;
    case DELETE_CRITERIA_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
const editCriteria = (state = initialStateEditCriteria, action) => {
  switch (action.type) {
    case EDIT_CRITERIA_REQUEST:
      state = {
        error: null,
        successData: null,
        loading: true,
      };
      break;
    case EDIT_CRITERIA_SUCCESS:
      state = {
        ...state,
        successData: action.payload,
        loading: false,
      };
      break;
    case EDIT_CRITERIA_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case EDIT_CRITERIA_RESET:
      state = {
        ...state,
        successData: null,
        error: null,
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export {
  addCriteria,
  addCriteriaDupCheck,
  deleteCriteria,
  editCriteria,
  getCriteria,
  getCriteriaList,
};
