import { create } from "../common/http.service";

const GET_CHANNEL = "/get-channel";
const ADD_CHANNEL = "/create-channel";

export const addChannelsApi = async (payload) => {
  const res = await create(ADD_CHANNEL, payload, true);
  return res;
};

export const getChannelApi = async (payload) => {
  const res = await create(GET_CHANNEL, payload, true);
  return res;
};
