import { RESET_NPI_CSAT_STATS, UPDATE_NPI_CSAT_STATS } from "./actionTypes";

const npiCsatInitState = [
  { name: "NPI", questionCount: 0, value: 0 },
  { name: "CSAT", questionCount: 0, value: 0 },
];

const npiCSATStats = (state = npiCsatInitState, action) => {
  switch (action.type) {
    case UPDATE_NPI_CSAT_STATS:
      state = action.payload;
      break;

    case RESET_NPI_CSAT_STATS:
      state = npiCsatInitState;
      break;

    default:
      state = [...state];
      break;
  }
  return state;
};

// Question Sentiments

export { npiCSATStats };
