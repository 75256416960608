import { call, put, takeEvery } from 'redux-saga/effects';

// Login Redux States
import { createAudienceCloneApi } from '../../../services/surveys/audience/cloneAudienceApi';
import { cloneAudienceError, cloneAudienceSuccess } from './actions';
import { CLONE_AUDIENCE_REQUEST } from './actionTypes';

//Include Both Helper File with needed methods

//If role is send successfully send mail link then dispatch redux action's are directly from here.
function* cloneAudienceSaga({ payload: attributes }) {
	try {
		const response = yield call(createAudienceCloneApi, attributes);
		if (response && response.success) {
			yield put(cloneAudienceSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(cloneAudienceError(error));
	}
}

function* audienceCloneSaga() {
	yield takeEvery(CLONE_AUDIENCE_REQUEST, cloneAudienceSaga);
}

export default audienceCloneSaga;
