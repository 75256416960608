import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States

import { viewAllSurveyPublicApi } from "../../../../services/surveys/view-survey/viewSurveyApi";
import { VIEW_ALL_ASSESSMENT_PUBLIC_REQUEST } from "./actionTypes";
import {
  viewAllAssessmentPublicError,
  viewAllAssessmentPublicSuccess,
} from "./actions";

function* viewAllAssessmentPublic() {
  try {
    const response = yield call(viewAllSurveyPublicApi);
    if (response && response.success) {
      yield put(viewAllAssessmentPublicSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(viewAllAssessmentPublicError(error));
  }
}
// function* checkAssessmentDoneAlready({ payload: surveyInfo }) {
// 	try {
// 		const response = yield call(surveyAlreadyDoneApi, surveyInfo);
// 		if (response && response.success) {
// 			yield put(isAssessmentAlreadyDoneSuccess(response));
// 		} else {
// 			throw response;
// 		}
// 	} catch (error) {
// 		yield put(isAssessmentAlreadyDoneError(error));
// 	}
// }

function* viewAllAssessmentPublicSaga() {
  yield takeEvery(VIEW_ALL_ASSESSMENT_PUBLIC_REQUEST, viewAllAssessmentPublic);
}

export default viewAllAssessmentPublicSaga;
