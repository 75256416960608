export const ADD_EMAIL_TEMPLATE_REQUEST = 'ADD_EMAIL_TEMPLATE_REQUEST';
export const ADD_EMAIL_TEMPLATE_SUCCESS = 'ADD_EMAIL_TEMPLATE_SUCCESS';
export const ADD_EMAIL_TEMPLATE_ERROR = 'ADD_EMAIL_TEMPLATE_ERROR';
export const ADD_EMAIL_TEMPLATE_RESET = 'ADD_EMAIL_TEMPLATE_RESET';

export const GET_EMAIL_TEMPLATE_REQUEST = 'GET_EMAIL_TEMPLATE_REQUEST';
export const GET_EMAIL_TEMPLATE_SUCCESS = 'GET_EMAIL_TEMPLATE_SUCCESS';
export const GET_EMAIL_TEMPLATE_ERROR = 'GET_EMAIL_TEMPLATE_ERROR';
export const GET_EMAIL_TEMPLATE_RESET = 'GET_EMAIL_TEMPLATE_RESET';

export const GET_EMAIL_TEMPLATE_LIST_REQUEST =
	'GET_EMAIL_TEMPLATE_LIST_REQUEST';
export const GET_EMAIL_TEMPLATE_LIST_SUCCESS =
	'GET_EMAIL_TEMPLATE_LIST_SUCCESS';
export const GET_EMAIL_TEMPLATE_LIST_ERROR = 'GET_EMAIL_TEMPLATE_LIST_ERROR';
export const GET_EMAIL_TEMPLATE_LIST_RESET = 'GET_EMAIL_TEMPLATE_LIST_RESET';

export const DELETE_EMAIL_TEMPLATE_REQUEST = 'DELETE_EMAIL_TEMPLATE_REQUEST';
export const DELETE_EMAIL_TEMPLATE_SUCCESS = 'DELETE_EMAIL_TEMPLATE_SUCCESS';
export const DELETE_EMAIL_TEMPLATE_ERROR = 'DELETE_EMAIL_TEMPLATE_ERROR';
export const DELETE_EMAIL_TEMPLATE_RESET = 'DELETE_EMAIL_TEMPLATE_RESET';

export const EDIT_EMAIL_TEMPLATE_REQUEST = 'EDIT_EMAIL_TEMPLATE_REQUEST';
export const EDIT_EMAIL_TEMPLATE_SUCCESS = 'EDIT_EMAIL_TEMPLATE_SUCCESS';
export const EDIT_EMAIL_TEMPLATE_ERROR = 'EDIT_EMAIL_TEMPLATE_ERROR';
export const EDIT_EMAIL_TEMPLATE_RESET = 'EDIT_EMAIL_TEMPLATE_RESET';

export const EMAIL_TEMPLATE_SHARE_REQUEST = 'EMAIL_TEMPLATE_SHARE_REQUEST';
export const EMAIL_TEMPLATE_SHARE_SUCCESS = 'EMAIL_TEMPLATE_SHARE_SUCCESS';
export const EMAIL_TEMPLATE_SHARE_ERROR = 'EMAIL_TEMPLATE_SHARE_ERROR';
export const EMAIL_TEMPLATE_SHARE_RESET = 'EMAIL_TEMPLATE_SHARE_RESET';
