import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { loginApi } from "../../../services/auth/loginApi";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";

//Include Both Helper File with needed methods

function* loginUser({ payload: { values, history } }) {
  try {
    const response = yield call(loginApi, values);

    if (response && response.success) {
      localStorage.setItem("authUser", JSON.stringify(response.user));
      yield put(loginSuccess(response));

      history.push("/dashboard");
    } else {
      throw response;
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser() {
  try {
    yield put(logoutUserSuccess(LOGOUT_USER, true));
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
