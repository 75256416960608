import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { changePasswordError, changePasswordSuccess } from "./actions";
import { CHANGE_PASSWORD } from "./actionTypes";

//Include Both Helper File with needed methods

import { changePasswordApi } from "../../../services/auth/changePasswordApi";

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* changePassword({ payload: { user, history } }) {
  try {
    const response = yield call(changePasswordApi, user);
    if (response && response.success) {
      yield put(changePasswordSuccess(response));
      history.push("/profile/2");
    } else {
      throw response;
    }
  } catch (error) {
    yield put(changePasswordError(error));
  }
}

function* changePasswordSaga() {
  yield takeEvery(CHANGE_PASSWORD, changePassword);
}

export default changePasswordSaga;
