export const ADD_NEW_NODE_REQUEST = "ADD_NEW_NODE_REQUEST";
export const ADD_NEW_NODE_SUCCESS = "ADD_NEW_NODE_SUCCESS";
export const ADD_NEW_NODE_ERROR = "ADD_NEW_NODE_ERROR";
export const ADD_NEW_NODE_RESET = "ADD_NEW_NODE_RESET";

export const GET_FAMILY_TREE_REQUEST = "GET_FAMILY_TREE_REQUEST";
export const GET_FAMILY_TREE_SUCCESS = "GET_FAMILY_TREE_SUCCESS";
export const GET_FAMILY_TREE_ERROR = "GET_FAMILY_TREE_ERROR";
export const GET_FAMILY_TREE_RESET = "GET_FAMILY_TREE_RESET";

export const DELETE_NODE_REQUEST = "DELETE_NODE_REQUEST";
export const DELETE_NODE_SUCCESS = "DELETE_NODE_SUCCESS";
export const DELETE_NODE_ERROR = "DELETE_NODE_ERROR";
export const DELETE_NODE_RESET = "DELETE_NODE_RESET";

export const EDIT_NODE_REQUEST = "EDIT_NODE_REQUEST";
export const EDIT_NODE_SUCCESS = "EDIT_NODE_SUCCESS";
export const EDIT_NODE_ERROR = "EDIT_NODE_ERROR";
export const EDIT_NODE_RESET = "EDIT_NODE_RESET";
