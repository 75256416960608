import {
  GET_FEEDBACK_REPORTS_ERROR,
  GET_FEEDBACK_REPORTS_REQUEST,
  GET_FEEDBACK_REPORTS_RESET,
  GET_FEEDBACK_REPORTS_SUCCESS,
} from "./actionTypes";

// Question Sentiments

export const getFeedbackReportsRequest = (surveyPayload) => {
  return {
    type: GET_FEEDBACK_REPORTS_REQUEST,
    payload: surveyPayload,
  };
};

export const getFeedbackReportsSuccess = (message) => {
  return {
    type: GET_FEEDBACK_REPORTS_SUCCESS,
    payload: message,
  };
};

export const getFeedbackReportsError = (message) => {
  return {
    type: GET_FEEDBACK_REPORTS_ERROR,
    payload: message,
  };
};

export const getFeedbackReportsReset = () => {
  return {
    type: GET_FEEDBACK_REPORTS_RESET,
  };
};
