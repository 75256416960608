import { call, put, takeEvery } from 'redux-saga/effects';

// Login Redux States
import {
	createEmailTemplateApi,
	deleteEmailTemplateApi,
	editEmailTemplateApi,
	getEmailTemplateApi,
	getEmailTemplateListApi,
	shareEmailTemplateApi,
} from '../../../services/surveys/audience/emailTemplateApi';
import {
	addEmailTemplateError,
	addEmailTemplateSuccess,
	deleteEmailTemplateError,
	deleteEmailTemplateSuccess,
	editEmailTemplateError,
	editEmailTemplateSuccess,
	emailTemplateShareError,
	emailTemplateShareSuccess,
	getEmailTemplateError,
	getEmailTemplateListError,
	getEmailTemplateListSuccess,
	getEmailTemplateSuccess,
} from './actions';
import {
	ADD_EMAIL_TEMPLATE_REQUEST,
	DELETE_EMAIL_TEMPLATE_REQUEST,
	EDIT_EMAIL_TEMPLATE_REQUEST,
	EMAIL_TEMPLATE_SHARE_REQUEST,
	GET_EMAIL_TEMPLATE_LIST_REQUEST,
	GET_EMAIL_TEMPLATE_REQUEST,
} from './actionTypes';

//Include Both Helper File with needed methods

//If role is send successfully send mail link then dispatch redux action's are directly from here.
function* addEmailTemplate({ payload: emailTemplate }) {
	try {
		const response = yield call(createEmailTemplateApi, emailTemplate);
		if (response && response.success) {
			yield put(addEmailTemplateSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(addEmailTemplateError(error));
	}
}
function* getEmailTemplate({ payload: surveyID }) {
	try {
		const response = yield call(getEmailTemplateApi, surveyID);
		if (response && response.success) {
			yield put(getEmailTemplateSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(getEmailTemplateError(error));
	}
}

function* getEmailTemplateList({ payload: surveyID }) {
	try {
		const response = yield call(getEmailTemplateListApi, surveyID);
		if (response && response.success) {
			yield put(getEmailTemplateListSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(getEmailTemplateListError(error));
	}
}
function* editEmailTemplate({ payload: attrData }) {
	try {
		const response = yield call(editEmailTemplateApi, attrData);
		if (response && response.success) {
			yield put(editEmailTemplateSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(editEmailTemplateError(error));
	}
}

function* deleteEmailTemplate({ payload: role }) {
	try {
		const response = yield call(deleteEmailTemplateApi, role);
		if (response && response.success) {
			yield put(deleteEmailTemplateSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(deleteEmailTemplateError(error));
	}
}

function* emailTemplateShare({ payload: emailTemplate }) {
	try {
		const response = yield call(shareEmailTemplateApi, emailTemplate);
		if (response && response.success) {
			yield put(emailTemplateShareSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(emailTemplateShareError(error));
	}
}

function* emailTemplateSaga() {
	yield takeEvery(ADD_EMAIL_TEMPLATE_REQUEST, addEmailTemplate);
	yield takeEvery(GET_EMAIL_TEMPLATE_REQUEST, getEmailTemplate);
	yield takeEvery(GET_EMAIL_TEMPLATE_LIST_REQUEST, getEmailTemplateList);
	yield takeEvery(EDIT_EMAIL_TEMPLATE_REQUEST, editEmailTemplate);
	yield takeEvery(DELETE_EMAIL_TEMPLATE_REQUEST, deleteEmailTemplate);
	yield takeEvery(EMAIL_TEMPLATE_SHARE_REQUEST, emailTemplateShare);
}

export default emailTemplateSaga;
