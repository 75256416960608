export const VIEW_CHART_DASHBOARD_REQUEST = 'VIEW_CHART_DASHBOARD_REQUEST';
export const VIEW_CHART_DASHBOARD_SUCCESS = 'VIEW_CHART_DASHBOARD_SUCCESS';
export const VIEW_CHART_DASHBOARD_ERROR = 'VIEW_CHART_DASHBOARD_ERROR';
export const VIEW_CHART_DASHBOARD_RESET = 'VIEW_CHART_DASHBOARD_RESET';

export const VIEW_SINGLE_CHART_DASHBOARD_REQUEST =
	'VIEW_SINGLE_CHART_DASHBOARD_REQUEST';
export const VIEW_SINGLE_CHART_DASHBOARD_SUCCESS =
	'VIEW_SINGLE_CHART_DASHBOARD_SUCCESS';
export const VIEW_SINGLE_CHART_DASHBOARD_ERROR =
	'VIEW_SINGLE_CHART_DASHBOARD_ERROR';
export const VIEW_SINGLE_CHART_DASHBOARD_RESET =
	'VIEW_SINGLE_CHART_DASHBOARD_RESET';

export const CREATE_CHART_DASHBOARD_REQUEST = 'CREATE_CHART_DASHBOARD_REQUEST';
export const CREATE_CHART_DASHBOARD_SUCCESS = 'CREATE_CHART_DASHBOARD_SUCCESS';
export const CREATE_CHART_DASHBOARD_ERROR = 'CREATE_CHART_DASHBOARD_ERROR';
export const CREATE_CHART_DASHBOARD_RESET = 'CREATE_CHART_DASHBOARD_RESET';

export const UPDATE_CHART_DASHBOARD_REQUEST = 'UPDATE_CHART_DASHBOARD_REQUEST';
export const UPDATE_CHART_DASHBOARD_SUCCESS = 'UPDATE_CHART_DASHBOARD_SUCCESS';
export const UPDATE_CHART_DASHBOARD_ERROR = 'UPDATE_CHART_DASHBOARD_ERROR';
export const UPDATE_CHART_DASHBOARD_RESET = 'UPDATE_CHART_DASHBOARD_RESET';

export const DELETE_CHART_DASHBOARD_REQUEST = 'DELETE_CHART_DASHBOARD_REQUEST';
export const DELETE_CHART_DASHBOARD_SUCCESS = 'DELETE_CHART_DASHBOARD_SUCCESS';
export const DELETE_CHART_DASHBOARD_ERROR = 'DELETE_CHART_DASHBOARD_ERROR';
export const DELETE_CHART_DASHBOARD_RESET = 'DELETE_CHART_DASHBOARD_RESET';

export const VIEW_PUBLIC_DASHBOARD_REQUEST = 'VIEW_PUBLIC_DASHBOARD_REQUEST';
export const VIEW_PUBLIC_DASHBOARD_SUCCESS = 'VIEW_PUBLIC_DASHBOARD_SUCCESS';
export const VIEW_PUBLIC_DASHBOARD_ERROR = 'VIEW_PUBLIC_DASHBOARD_ERROR';
export const VIEW_PUBLIC_DASHBOARD_RESET = 'VIEW_PUBLIC_DASHBOARD_RESET';
