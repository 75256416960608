import {
	ACTIVE_MEMBER_ERROR,
	ACTIVE_MEMBER_REQUEST,
	ACTIVE_MEMBER_RESET,
	ACTIVE_MEMBER_SUCCESS,
	ADD_MEMBER_EMAIL_ERROR,
	ADD_MEMBER_EMAIL_REQUEST,
	ADD_MEMBER_EMAIL_RESET,
	ADD_MEMBER_EMAIL_SUCCESS,
	ADD_MEMBER_ERROR,
	ADD_MEMBER_REQUEST,
	ADD_MEMBER_RESET,
	ADD_MEMBER_SUCCESS,
	DELETE_MEMBER_ERROR,
	DELETE_MEMBER_REQUEST,
	DELETE_MEMBER_SUCCESS,
	EDIT_MEMBER_ERROR,
	EDIT_MEMBER_REQUEST,
	EDIT_MEMBER_RESET,
	EDIT_MEMBER_SUCCESS,
	GET_MEMBER_ERROR,
	GET_MEMBER_REQUEST,
	GET_MEMBER_SUCCESS,
	GET_VERIFIED_MEMBER_ERROR,
	GET_VERIFIED_MEMBER_REQUEST,
	GET_VERIFIED_MEMBER_RESET,
	GET_VERIFIED_MEMBER_SUCCESS,
} from './actionTypes';

// Invite Member with email
export const addMemberEmailRequest = (email, history) => {
	return {
		type: ADD_MEMBER_EMAIL_REQUEST,
		payload: { email, history },
	};
};

export const addMemberEmailSuccess = (message) => {
	return {
		type: ADD_MEMBER_EMAIL_SUCCESS,
		payload: message,
	};
};

export const addMemberEmailError = (message) => {
	return {
		type: ADD_MEMBER_EMAIL_ERROR,
		payload: message,
	};
};

export const addMemberEmailReset = () => {
	return {
		type: ADD_MEMBER_EMAIL_RESET,
	};
};

// Create Member
export const addMemberRequest = (member, history) => {
	return {
		type: ADD_MEMBER_REQUEST,
		payload: { member, history },
	};
};

export const addMemberSuccess = (message) => {
	return {
		type: ADD_MEMBER_SUCCESS,
		payload: message,
	};
};

export const addMemberError = (message) => {
	return {
		type: ADD_MEMBER_ERROR,
		payload: message,
	};
};

export const addMemberReset = () => {
	return {
		type: ADD_MEMBER_RESET,
	};
};
// Active Member
export const activeMemberRequest = (member, history) => {
	return {
		type: ACTIVE_MEMBER_REQUEST,
		payload: { member, history },
	};
};

export const activeMemberSuccess = (message) => {
	return {
		type: ACTIVE_MEMBER_SUCCESS,
		payload: message,
	};
};

export const activeMemberError = (message) => {
	return {
		type: ACTIVE_MEMBER_ERROR,
		payload: message,
	};
};

export const activeMemberReset = () => {
	return {
		type: ACTIVE_MEMBER_RESET,
	};
};

// Get Member
export const getMemberRequest = () => {
	return {
		type: GET_MEMBER_REQUEST,
	};
};

export const getMemberSuccess = (message) => {
	return {
		type: GET_MEMBER_SUCCESS,
		payload: message,
	};
};

export const getMemberError = (message) => {
	return {
		type: GET_MEMBER_ERROR,
		payload: message,
	};
};

export const getMemberReset = (message) => {
	return {
		type: GET_MEMBER_RESEt,
	};
};
// Get Verified Member
export const getVerifiedMemberRequest = () => {
	return {
		type: GET_VERIFIED_MEMBER_REQUEST,
	};
};

export const getVerifiedMemberSuccess = (message) => {
	return {
		type: GET_VERIFIED_MEMBER_SUCCESS,
		payload: message,
	};
};

export const getVerifiedMemberError = (message) => {
	return {
		type: GET_VERIFIED_MEMBER_ERROR,
		payload: message,
	};
};

export const getVerifiedMemberReset = () => {
	return {
		type: GET_VERIFIED_MEMBER_RESET,
	};
};

// Delete Member
export const deleteMemberRequest = (member, history) => {
	return {
		type: DELETE_MEMBER_REQUEST,
		payload: { member, history },
	};
};

export const deleteMemberSuccess = (message) => {
	return {
		type: DELETE_MEMBER_SUCCESS,
		payload: message,
	};
};

export const deleteMemberError = (message) => {
	return {
		type: DELETE_MEMBER_ERROR,
		payload: message,
	};
};

// Delete Member
export const editMemberRequest = (member, history) => {
	return {
		type: EDIT_MEMBER_REQUEST,
		payload: { member, history },
	};
};

export const editMemberSuccess = (message) => {
	return {
		type: EDIT_MEMBER_SUCCESS,
		payload: message,
	};
};

export const editMemberError = (message) => {
	return {
		type: EDIT_MEMBER_ERROR,
		payload: message,
	};
};
export const editMemberReset = () => {
	return {
		type: EDIT_MEMBER_RESET,
	};
};
