import { create, get } from "../../common/http.service";

const CREATE_PERMISSION_URL = "/create-permission";
const GET_PERMISSION_URL = "/get-permission";
const UPDATE_PERMISSION_URL = "/update-permission";

export const createPermissionApi = async (payload) => {
  const res = await create(CREATE_PERMISSION_URL, payload, true);

  return res;
};
export const getPermissionApi = async (payload) => {
  const res = await get(GET_PERMISSION_URL, payload, true);

  return res;
};
export const editPermissionApi = async (payload) => {
  const res = await create(UPDATE_PERMISSION_URL, payload, true);

  return res;
};
