import {
  CREATE_QUESTION_BANK_ERROR,
  CREATE_QUESTION_BANK_REQUEST,
  CREATE_QUESTION_BANK_RESET,
  CREATE_QUESTION_BANK_SUCCESS,
  GET_ALL_QUESTION_BANK_ERROR,
  GET_ALL_QUESTION_BANK_REQUEST,
  GET_ALL_QUESTION_BANK_RESET,
  GET_ALL_QUESTION_BANK_SUCCESS,
  GET_ONE_QUESTION_BANK_ERROR,
  GET_ONE_QUESTION_BANK_REQUEST,
  GET_ONE_QUESTION_BANK_RESET,
  GET_ONE_QUESTION_BANK_SUCCESS,
  SEARCH_QUESTION_BANK_ERROR,
  SEARCH_QUESTION_BANK_REQUEST,
  SEARCH_QUESTION_BANK_RESET,
  SEARCH_QUESTION_BANK_SUCCESS,
} from "./actionTypes";

const initialStateAddToQuestionBank = {
  successData: null,
  error: null,
  loading: null,
};
const initialStateAllQuestionBank = {
  successData: null,
  error: null,
  loading: null,
};
const initialStateOneQuestionBank = {
  successData: null,
  error: null,
  loading: null,
};
const initialStateSearchQuestionBank = {
  successData: null,
  error: null,
  loading: null,
};

const addToQuestionBank = (state = initialStateAddToQuestionBank, action) => {
  switch (action.type) {
    case CREATE_QUESTION_BANK_REQUEST:
      state = {
        error: null,
        successData: null,
        loading: true,
      };
      break;
    case CREATE_QUESTION_BANK_SUCCESS:
      state = {
        ...state,
        successData: action.payload,
        loading: false,
      };
      break;
    case CREATE_QUESTION_BANK_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case CREATE_QUESTION_BANK_RESET:
      state = { ...state, successData: null, error: null, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
const completeQuestionBank = (state = initialStateAllQuestionBank, action) => {
  const { keepState } = action.payload || {};
  switch (action.type) {
    case GET_ALL_QUESTION_BANK_REQUEST:
      state = keepState
        ? {
            ...state,
            error: null,
            loading: true,
          }
        : {
            successData: null,
            error: null,
            loading: true,
          };
      break;
    case GET_ALL_QUESTION_BANK_SUCCESS:
      state = {
        ...state,
        successData: action.payload,
        loading: false,
      };
      break;
    case GET_ALL_QUESTION_BANK_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case GET_ALL_QUESTION_BANK_RESET:
      state = { ...state, successData: null, error: null, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
const singleQuestionBank = (state = initialStateOneQuestionBank, action) => {
  switch (action.type) {
    case GET_ONE_QUESTION_BANK_REQUEST:
      state = {
        error: null,
        successData: null,
        loading: true,
      };
      break;
    case GET_ONE_QUESTION_BANK_SUCCESS:
      state = {
        ...state,
        successData: action.payload,
        loading: false,
      };
      break;
    case GET_ONE_QUESTION_BANK_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case GET_ONE_QUESTION_BANK_RESET:
      state = { ...state, successData: null, error: null, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

const searchQuestionBank = (state = initialStateSearchQuestionBank, action) => {
  switch (action.type) {
    case SEARCH_QUESTION_BANK_REQUEST:
      state = {
        error: null,
        successData: null,
        loading: true,
      };
      break;
    case SEARCH_QUESTION_BANK_SUCCESS:
      state = {
        ...state,
        successData: action.payload,
        loading: false,
      };
      break;
    case SEARCH_QUESTION_BANK_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case SEARCH_QUESTION_BANK_RESET:
      state = { ...state, successData: null, error: null, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export {
  addToQuestionBank,
  completeQuestionBank,
  searchQuestionBank,
  singleQuestionBank,
};
