import {
	ADD_USER_PERMISSIONS_ERROR,
	ADD_USER_PERMISSIONS_REQUEST,
	ADD_USER_PERMISSIONS_RESET,
	ADD_USER_PERMISSIONS_SUCCESS,
	DELETE_USER_PERMISSIONS_ERROR,
	DELETE_USER_PERMISSIONS_REQUEST,
	DELETE_USER_PERMISSIONS_SUCCESS,
	EDIT_USER_PERMISSIONS_ERROR,
	EDIT_USER_PERMISSIONS_REQUEST,
	EDIT_USER_PERMISSIONS_RESET,
	EDIT_USER_PERMISSIONS_SUCCESS,
	GET_USER_PERMISSIONS_ERROR,
	GET_USER_PERMISSIONS_REQUEST,
	GET_USER_PERMISSIONS_RESET,
	GET_USER_PERMISSIONS_SUCCESS,
} from './actionTypes';

// Create UserPermissions
export const addUserPermissionsRequest = (user) => {
	return {
		type: ADD_USER_PERMISSIONS_REQUEST,
		payload: user,
	};
};

export const addUserPermissionsSuccess = (message) => {
	return {
		type: ADD_USER_PERMISSIONS_SUCCESS,
		payload: message,
	};
};

export const addUserPermissionsError = (message) => {
	return {
		type: ADD_USER_PERMISSIONS_ERROR,
		payload: message,
	};
};

export const addUserPermissionsReset = () => {
	return {
		type: ADD_USER_PERMISSIONS_RESET,
	};
};

// Get UserPermissions
export const getUserPermissionsRequest = () => {
	return {
		type: GET_USER_PERMISSIONS_REQUEST,
	};
};

export const getUserPermissionsSuccess = (message) => {
	return {
		type: GET_USER_PERMISSIONS_SUCCESS,
		payload: message,
	};
};

export const getUserPermissionsError = (message) => {
	return {
		type: GET_USER_PERMISSIONS_ERROR,
		payload: message,
	};
};

export const getUserPermissionsReset = () => {
	return {
		type: GET_USER_PERMISSIONS_RESET,
	};
};

// Delete UserPermissions
export const deleteUserPermissionsRequest = (role, history) => {
	return {
		type: DELETE_USER_PERMISSIONS_REQUEST,
		payload: { role, history },
	};
};

export const deleteUserPermissionsSuccess = (message) => {
	return {
		type: DELETE_USER_PERMISSIONS_SUCCESS,
		payload: message,
	};
};

export const deleteUserPermissionsError = (message) => {
	return {
		type: DELETE_USER_PERMISSIONS_ERROR,
		payload: message,
	};
};

// Delete UserPermissions
export const editUserPermissionsRequest = (role, history) => {
	return {
		type: EDIT_USER_PERMISSIONS_REQUEST,
		payload: { role, history },
	};
};

export const editUserPermissionsSuccess = (message) => {
	return {
		type: EDIT_USER_PERMISSIONS_SUCCESS,
		payload: message,
	};
};

export const editUserPermissionsError = (message) => {
	return {
		type: EDIT_USER_PERMISSIONS_ERROR,
		payload: message,
	};
};
export const editUserPermissionsReset = () => {
	return {
		type: EDIT_USER_PERMISSIONS_RESET,
	};
};
