import {
  CREATE_QUESTION_BANK_ERROR,
  CREATE_QUESTION_BANK_REQUEST,
  CREATE_QUESTION_BANK_RESET,
  CREATE_QUESTION_BANK_SUCCESS,
  GET_ALL_QUESTION_BANK_ERROR,
  GET_ALL_QUESTION_BANK_REQUEST,
  GET_ALL_QUESTION_BANK_RESET,
  GET_ALL_QUESTION_BANK_SUCCESS,
  GET_ONE_QUESTION_BANK_ERROR,
  GET_ONE_QUESTION_BANK_REQUEST,
  GET_ONE_QUESTION_BANK_RESET,
  GET_ONE_QUESTION_BANK_SUCCESS,
  SEARCH_QUESTION_BANK_ERROR,
  SEARCH_QUESTION_BANK_REQUEST,
  SEARCH_QUESTION_BANK_RESET,
  SEARCH_QUESTION_BANK_SUCCESS,
} from "./actionTypes";

// Add To Question Bank
export const createQuestionBankRequest = (surveyPayload) => {
  return {
    type: CREATE_QUESTION_BANK_REQUEST,
    payload: surveyPayload,
  };
};

export const createQuestionBankSuccess = (message) => {
  return {
    type: CREATE_QUESTION_BANK_SUCCESS,
    payload: message,
  };
};

export const createQuestionBankError = (message) => {
  return {
    type: CREATE_QUESTION_BANK_ERROR,
    payload: message,
  };
};

export const createQuestionBankReset = () => {
  return {
    type: CREATE_QUESTION_BANK_RESET,
  };
};

// get complete question bank
export const getAllQuestionBankRequest = (surveyPayload) => {
  return {
    type: GET_ALL_QUESTION_BANK_REQUEST,
    payload: surveyPayload,
  };
};

export const getAllQuestionBankSuccess = (message) => {
  return {
    type: GET_ALL_QUESTION_BANK_SUCCESS,
    payload: message,
  };
};

export const getAllQuestionBankError = (message) => {
  return {
    type: GET_ALL_QUESTION_BANK_ERROR,
    payload: message,
  };
};

export const getAllQuestionBankReset = () => {
  return {
    type: GET_ALL_QUESTION_BANK_RESET,
  };
};

// get one question from question bank
export const getOneQuestionBankRequest = (surveyPayload) => {
  return {
    type: GET_ONE_QUESTION_BANK_REQUEST,
    payload: surveyPayload,
  };
};

export const getOneQuestionBankSuccess = (message) => {
  return {
    type: GET_ONE_QUESTION_BANK_SUCCESS,
    payload: message,
  };
};

export const getOneQuestionBankError = (message) => {
  return {
    type: GET_ONE_QUESTION_BANK_ERROR,
    payload: message,
  };
};

export const getOneQuestionBankReset = () => {
  return {
    type: GET_ONE_QUESTION_BANK_RESET,
  };
};

// search question from question bank
export const searchFromQuestionBankRequest = (surveyPayload) => {
  return {
    type: SEARCH_QUESTION_BANK_REQUEST,
    payload: surveyPayload,
  };
};

export const searchFromQuestionBankSuccess = (message) => {
  return {
    type: SEARCH_QUESTION_BANK_SUCCESS,
    payload: message,
  };
};

export const searchFromQuestionBankError = (message) => {
  return {
    type: SEARCH_QUESTION_BANK_ERROR,
    payload: message,
  };
};

export const searchFromQuestionBankReset = () => {
  return {
    type: SEARCH_QUESTION_BANK_RESET,
  };
};
