import { predefined_Questions_Personal } from "../../../../common/demoData";
import {
  ADD_QUESTIONS_INITIATE_ASSESSMENT,
  ASSESSMENT_QUESTION_ERROR,
  CURRENT_QUESTION_ID,
  CURRENT_QUESTION_ID_RESET,
  GET_CURRENT_TAB_ID,
  GET_QUESTION_SIZE,
  GET_QUESTION_SIZE_RESET,
  GET_STATS,
  INITIATE_ASSESSMENT_RESET,
  INITIATE_ASSESSMENT_SUCCESS,
  PREDEFINED_QUESTION_ERROR,
  REQUIRED_QUESTION_ERROR,
  SAME_OPTIONS_ERROR,
  UPDATE_INITIATE_ASSESSMENT,
  VALIDATION_ERROR,
} from "./actionTypes";

const initialStateInitiateAssessment = {
  assessment: {
    subscription_id: 1234967,
    user_id: 0,
    category_id: 0,
    name: "",
    start_type: 1,
    ui_type: 2,
    company_name: "",
    company_logo: "",
    introductory_message: "",
    introductory_message_bg: "",
    disqualification_message:
      "This is default demo dis message ,this is quiz demo being created by team of Antlere",
    disqualification_message_bg: "",
    pre_survey_questionnaire: {
      personal: predefined_Questions_Personal,
    },
    template_id: 1,
    survey_questionnaire: [],
    allow_no_of_responses: 1,
    anonymous_respondents: 1,
    feedback_type: 1,
    multi_language: 0,
    no_of_sections: 1,
    section_skipping: 0,
    question_skipping: 0,
    question_randomization: 0,
    publish_status: 0,
    show_stats: 0,
    open_to_response: 0,
    is_favorite: 0,
    is_template: 0,
    is_active: 1,
    number_of_responses: 0,
    recurrence: { recurrence_id: "", status: 0 },
    redirect_url: "0",
    industry_id: 0,
    close_date: "0",
    close_message: "0",
    response_limit: 0,
    cookies: 0,
    show_question_no: 1,
    branding: 1,
    thanks_message_title: "Hooray!",
    thanks_message_body: "You have completed the quiz",
    other_categories: "",
    other_industries: "",
    previous_navigation: 1,
    created_by: 0,
    updated_by: 0,
    deleted_by: 0,
    total_marks: -1,
    passing_marks: -1,
    allowed_time: -1,
  },
  error: null,
  loading: null,
};

const initialQuestionIDState = {
  questionID: null,
  questionData: null,
  questionAnswer: null,
};
const initialQuestionSizeState = { questionSize: 0 };

const initialStats = { estimatedTime: 0, percentageValue: 0 };

const initialTabID = { tabID: { id: 1, name: "" } };

const initiateAssessment = (state = initialStateInitiateAssessment, action) => {
  switch (action.type) {
    case INITIATE_ASSESSMENT_SUCCESS:
      state = {
        ...state,
        assessment: action.payload,
        loading: false,
      };
      break;

    case UPDATE_INITIATE_ASSESSMENT:
      state = {
        ...state,
        assessment: { ...state.assessment, ...action.payload },
        loading: false,
      };
      break;
    case ADD_QUESTIONS_INITIATE_ASSESSMENT:
      state = {
        ...state,
        assessment: {
          ...state.assessment,
          survey_questionnaire: action.payload,
        },
        loading: false,
      };
      break;
    case INITIATE_ASSESSMENT_RESET:
      state = initialStateInitiateAssessment;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

const questionID = (state = initialQuestionIDState, action) => {
  switch (action.type) {
    case CURRENT_QUESTION_ID:
      state = action.payload;

      break;

    case CURRENT_QUESTION_ID_RESET:
      state = initialQuestionIDState;
      break;
    default:
      state;
      break;
  }
  return state;
};

const questionSize = (state = initialQuestionSizeState, action) => {
  switch (action.type) {
    case GET_QUESTION_SIZE:
      state = {
        ...state,
        questionSize: action.payload,
      };
      break;

    case GET_QUESTION_SIZE_RESET:
      state = initialQuestionIDState;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

const questionStats = (state = initialStats, action) => {
  switch (action.type) {
    case GET_STATS:
      state = action.payload;
      break;

    default:
      state = initialStats;
      break;
  }
  return state;
};

const assessmentCurrentActiveTab = (state = initialTabID, action) => {
  switch (action.type) {
    case GET_CURRENT_TAB_ID:
      state = {
        tabID: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

const assessmentQuestionError = (state = { errorMsg: null }, action) => {
  switch (action.type) {
    case ASSESSMENT_QUESTION_ERROR:
      state = {
        errorMsg: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

const sameOptionsError = (state = { errorMsg: null }, action) => {
  switch (action.type) {
    case SAME_OPTIONS_ERROR:
      state = {
        errorMsg: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};
const predefinedQuestionError = (state = [], action) => {
  switch (action.type) {
    case PREDEFINED_QUESTION_ERROR:
      state = action.payload;
      break;

    default:
      state = [...state];
      break;
  }
  return state;
};
const requiredQuestionError = (
  state = { errorMsg: null, qID: null },
  action
) => {
  switch (action.type) {
    case REQUIRED_QUESTION_ERROR:
      state = action.payload;
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

const validationError = (state = { errorMsg: null, validID: null }, action) => {
  switch (action.type) {
    case VALIDATION_ERROR:
      state = action.payload;
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export {
  assessmentCurrentActiveTab,
  assessmentQuestionError,
  initiateAssessment,
  predefinedQuestionError,
  questionID,
  questionSize,
  questionStats,
  requiredQuestionError,
  sameOptionsError,
  validationError,
};
