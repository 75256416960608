import {
  GET_ASSESSMENT_ANALYSIS_ERROR,
  GET_ASSESSMENT_ANALYSIS_REQUEST,
  GET_ASSESSMENT_ANALYSIS_RESET,
  GET_ASSESSMENT_ANALYSIS_SUCCESS,
} from "./actionTypes";

// Assessment Analysis
export const getAssessmentAnalysisRequest = (surveyPayload) => {
  return {
    type: GET_ASSESSMENT_ANALYSIS_REQUEST,
    payload: surveyPayload,
  };
};

export const getAssessmentAnalysisSuccess = (message) => {
  return {
    type: GET_ASSESSMENT_ANALYSIS_SUCCESS,
    payload: message,
  };
};

export const getAssessmentAnalysisError = (message) => {
  return {
    type: GET_ASSESSMENT_ANALYSIS_ERROR,
    payload: message,
  };
};

export const getAssessmentAnalysisReset = () => {
  return {
    type: GET_ASSESSMENT_ANALYSIS_RESET,
  };
};
