export const INITIATE_ASSESSMENT_REQUEST = "INITIATE_ASSESSMENT_REQUEST";
export const INITIATE_ASSESSMENT_SUCCESS = "INITIATE_ASSESSMENT_SUCCESS";
export const INITIATE_ASSESSMENT_ERROR = "INITIATE_ASSESSMENT_ERROR";
export const INITIATE_ASSESSMENT_RESET = "INITIATE_ASSESSMENT_RESET";

export const INITIATE_ASSESSMENT = "INITIATE_ASSESSMENT";

export const ADD_QUESTIONS_INITIATE_ASSESSMENT =
  "ADD_QUESTIONS_INITIATE_ASSESSMENT";
export const UPDATE_INITIATE_ASSESSMENT = "UPDATE_INITIATE_ASSESSMENT";

export const GET_PIPING_ID = "GET_PIPING_ID";
export const GET_PIPING_ID_RESET = "GET_PIPING_ID_RESET";

export const CURRENT_QUESTION_ID = "CURRENT_QUESTION_ID";
export const CURRENT_QUESTION_ID_RESET = "CURRENT_QUESTION_ID_RESET";

export const GET_QUESTION_SIZE = "GET_QUESTION_SIZE";
export const GET_QUESTION_SIZE_RESET = "GET_QUESTION_SIZE_RESET";

export const GET_STATS = "GET_STATS";
export const GET_CURRENT_TAB_ID = "GET_CURRENT_TAB_ID";
export const ASSESSMENT_QUESTION_ERROR = "ASSESSMENT_QUESTION_ERROR";
export const PREDEFINED_QUESTION_ERROR = "PREDEFINED_QUESTION_ERROR";
export const REQUIRED_QUESTION_ERROR = "REQUIRED_QUESTION_ERROR";
export const VALIDATION_ERROR = "VALIDATION_ERROR";
export const SAME_OPTIONS_ERROR = "SAME_OPTIONS_ERROR";
