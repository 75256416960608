import { create } from "../../common/http.service";

const VIEW_SINGLE_QUIZ_URL = "/get-quiz";
const SHARE_SINGLE_QUIZ_URL = "/get-quiz-public";
const QUIZ_SUCCESS_URL = "/submit-quiz-feedback";

const CHANNEL_DETAILS_URL = "/get-channel-details";
const VIEW_ALL_QUIZ_URL = "/list-quiz";
const VIEW_ALL_QUIZ_STATS_URL = "/get-quiz-stats";
const VIEW_ALL_QUIZ_PUBLIC_URL = "/list-public-quiz";

export const viewSingleQuizApi = async (payload) => {
  const res = await create(VIEW_SINGLE_QUIZ_URL, payload, true);

  return res;
};

export const shareSingleQuizApi = async (payload) => {
  const res = await create(SHARE_SINGLE_QUIZ_URL, payload, false);

  return res;
};

export const getChannelDetailsApi = async (payload) => {
  const res = await create(CHANNEL_DETAILS_URL, payload, false);

  return res;
};

export const quizSuccessApi = async (payload) => {
  const res = await create(QUIZ_SUCCESS_URL, payload, false);
  return res;
};

export const viewAllQuizApi = async (payload) => {
  const res = await create(VIEW_ALL_QUIZ_URL, payload, true);

  return res;
};
export const viewAllQuizStatsApi = async (payload) => {
  const res = await create(VIEW_ALL_QUIZ_STATS_URL, payload, true);

  return res;
};
export const viewAllQuizPublicApi = async (payload) => {
  const res = await create(VIEW_ALL_QUIZ_PUBLIC_URL, payload, true);

  return res;
};
