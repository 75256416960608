import React from "react";

import { ToastContainer } from "react-toastify";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/effect-flip";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import "react-virtualized/styles.css";
import "./App.css";
import "./assets/scss/themes.scss";

import "react-phone-number-input/style.css";
import "react-tagsinput/react-tagsinput.css";

import "quill-mention";
import "quill-mention/dist/quill.mention.css";
import "react-quill/dist/quill.snow.css";
import "react-rangeslider/lib/index.css";

import "zingchart/es6";
// EXPLICITLY IMPORT MODULE

import "zingchart/modules-es6/zingchart-wordcloud.min.js";

import "react-rangeslider/lib/index.css";

import Route from "./Routes";

function App() {
  return (
    <>
      <Route />
      <ToastContainer
        position="top-right"
        autoClose="2500"
        newestOnTop={false}
        limit={1}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </>
  );
}

export default App;
