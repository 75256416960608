import {
  ADD_NEW_NODE_ERROR,
  ADD_NEW_NODE_REQUEST,
  ADD_NEW_NODE_RESET,
  ADD_NEW_NODE_SUCCESS,
  DELETE_NODE_ERROR,
  DELETE_NODE_REQUEST,
  DELETE_NODE_SUCCESS,
  EDIT_NODE_ERROR,
  EDIT_NODE_REQUEST,
  EDIT_NODE_RESET,
  EDIT_NODE_SUCCESS,
  GET_FAMILY_TREE_ERROR,
  GET_FAMILY_TREE_REQUEST,
  GET_FAMILY_TREE_RESET,
  GET_FAMILY_TREE_SUCCESS,
} from "./actionTypes";

// Create UserRole
export const addNewNodeRequest = (user) => {
  return {
    type: ADD_NEW_NODE_REQUEST,
    payload: user,
  };
};

export const addNewNodeSuccess = (message) => {
  return {
    type: ADD_NEW_NODE_SUCCESS,
    payload: message,
  };
};

export const addNewNodeError = (message) => {
  return {
    type: ADD_NEW_NODE_ERROR,
    payload: message,
  };
};

export const addNewNodeReset = () => {
  return {
    type: ADD_NEW_NODE_RESET,
  };
};

// Get Complete Family Tree
export const getFamilyTreeRequest = (payload) => {
  return {
    type: GET_FAMILY_TREE_REQUEST,
    payload,
  };
};

export const getFamilyTreeSuccess = (message) => {
  return {
    type: GET_FAMILY_TREE_SUCCESS,
    payload: message,
  };
};

export const getFamilyTreeError = (message) => {
  return {
    type: GET_FAMILY_TREE_ERROR,
    payload: message,
  };
};

export const getFamilyTreeReset = () => {
  return {
    type: GET_FAMILY_TREE_RESET,
  };
};

// Delete Single Node
export const deleteNodeRequest = (role) => {
  return {
    type: DELETE_NODE_REQUEST,
    payload: role,
  };
};

export const deleteNodeSuccess = (message) => {
  return {
    type: DELETE_NODE_SUCCESS,
    payload: message,
  };
};

export const deleteNodeError = (message) => {
  return {
    type: DELETE_NODE_ERROR,
    payload: message,
  };
};

// Edit Single Node
export const editNodeRequest = (role, history) => {
  return {
    type: EDIT_NODE_REQUEST,
    payload: { role, history },
  };
};

export const editNodeSuccess = (message) => {
  return {
    type: EDIT_NODE_SUCCESS,
    payload: message,
  };
};

export const editNodeError = (message) => {
  return {
    type: EDIT_NODE_ERROR,
    payload: message,
  };
};
export const editNodeReset = () => {
  return {
    type: EDIT_NODE_RESET,
  };
};
