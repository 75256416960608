import { create } from '../common/http.service';

const PAYMENT_URL = '/buy-package';

export const paymentApi = async (payload) => {
	const res = await create(PAYMENT_URL, payload, true);

	return res;
};

// External client register on sp after clicking the link in his email
