import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import {
  createRecurrenceApi,
  viewRecurrenceApi,
} from "../../../../../services/surveys/create-survey/createRecurrenceApi";
import {
  addRecurrenceError,
  addRecurrenceSuccess,
  getRecurrencesError,
  getRecurrencesSuccess,
} from "./actions";
import { ADD_RECURRENCE_REQUEST, GET_RECURRENCES_REQUEST } from "./actionTypes";

function* addRecurrence({ payload: segment }) {
  try {
    const response = yield call(createRecurrenceApi, segment);

    if (response && response.success) {
      yield put(addRecurrenceSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(addRecurrenceError(error));
  }
}

function* getRecurrences({ payload: surveyID }) {
  try {
    const response = yield call(viewRecurrenceApi, surveyID);
    if (response && response.success) {
      yield put(getRecurrencesSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(getRecurrencesError(error));
  }
}

function* recurrenceSaga() {
  yield takeEvery(ADD_RECURRENCE_REQUEST, addRecurrence);
  yield takeEvery(GET_RECURRENCES_REQUEST, getRecurrences);
}

export default recurrenceSaga;
