import {
	ADD_EMAIL_TEMPLATE_ERROR,
	ADD_EMAIL_TEMPLATE_REQUEST,
	ADD_EMAIL_TEMPLATE_RESET,
	ADD_EMAIL_TEMPLATE_SUCCESS,
	DELETE_EMAIL_TEMPLATE_ERROR,
	DELETE_EMAIL_TEMPLATE_REQUEST,
	DELETE_EMAIL_TEMPLATE_SUCCESS,
	EDIT_EMAIL_TEMPLATE_ERROR,
	EDIT_EMAIL_TEMPLATE_REQUEST,
	EDIT_EMAIL_TEMPLATE_RESET,
	EDIT_EMAIL_TEMPLATE_SUCCESS,
	EMAIL_TEMPLATE_SHARE_ERROR,
	EMAIL_TEMPLATE_SHARE_REQUEST,
	EMAIL_TEMPLATE_SHARE_RESET,
	EMAIL_TEMPLATE_SHARE_SUCCESS,
	GET_EMAIL_TEMPLATE_ERROR,
	GET_EMAIL_TEMPLATE_LIST_ERROR,
	GET_EMAIL_TEMPLATE_LIST_REQUEST,
	GET_EMAIL_TEMPLATE_LIST_RESET,
	GET_EMAIL_TEMPLATE_LIST_SUCCESS,
	GET_EMAIL_TEMPLATE_REQUEST,
	GET_EMAIL_TEMPLATE_RESET,
	GET_EMAIL_TEMPLATE_SUCCESS,
} from './actionTypes';

const initialStateAddEmailTemplate = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateEmailTemplateShare = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateGetEmailTemplate = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateDeleteEmailTemplate = {
	successData: null,
	error: null,
	loading: null,
};
const initialStateEditEmailTemplate = {
	successData: null,
	error: null,
	loading: null,
};

const addEmailTemplate = (state = initialStateAddEmailTemplate, action) => {
	switch (action.type) {
		case ADD_EMAIL_TEMPLATE_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case ADD_EMAIL_TEMPLATE_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case ADD_EMAIL_TEMPLATE_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case ADD_EMAIL_TEMPLATE_RESET:
			state = {
				...state,
				successData: null,
				error: null,
				loading: false,
			};
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const getEmailTemplate = (state = initialStateGetEmailTemplate, action) => {
	switch (action.type) {
		case GET_EMAIL_TEMPLATE_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case GET_EMAIL_TEMPLATE_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case GET_EMAIL_TEMPLATE_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;

		case GET_EMAIL_TEMPLATE_RESET:
			state = initialStateGetEmailTemplate;
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const getEmailTemplateList = (state = initialStateGetEmailTemplate, action) => {
	switch (action.type) {
		case GET_EMAIL_TEMPLATE_LIST_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case GET_EMAIL_TEMPLATE_LIST_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case GET_EMAIL_TEMPLATE_LIST_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;

		case GET_EMAIL_TEMPLATE_LIST_RESET:
			state = initialStateGetEmailTemplate;
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const deleteEmailTemplate = (
	state = initialStateDeleteEmailTemplate,
	action
) => {
	switch (action.type) {
		case DELETE_EMAIL_TEMPLATE_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case DELETE_EMAIL_TEMPLATE_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case DELETE_EMAIL_TEMPLATE_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
const editEmailTemplate = (state = initialStateEditEmailTemplate, action) => {
	switch (action.type) {
		case EDIT_EMAIL_TEMPLATE_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case EDIT_EMAIL_TEMPLATE_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case EDIT_EMAIL_TEMPLATE_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case EDIT_EMAIL_TEMPLATE_RESET:
			state = {
				...state,
				successData: null,
				error: null,
				loading: false,
			};
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

const emailTemplateShare = (state = initialStateEmailTemplateShare, action) => {
	switch (action.type) {
		case EMAIL_TEMPLATE_SHARE_REQUEST:
			state = {
				...state,
				successData: null,
				loading: true,
			};
			break;
		case EMAIL_TEMPLATE_SHARE_SUCCESS:
			state = {
				...state,
				successData: action.payload,
				loading: false,
			};
			break;
		case EMAIL_TEMPLATE_SHARE_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case EMAIL_TEMPLATE_SHARE_RESET:
			state = {
				...state,
				successData: null,
				error: null,
				loading: false,
			};
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export {
	addEmailTemplate,
	deleteEmailTemplate,
	getEmailTemplate,
	getEmailTemplateList,
	editEmailTemplate,
	emailTemplateShare,
};
