import { create } from '../../common/http.service';

const CREATE_EMAIL_TEMPLATE_URL = '/create-email-template';
const GET_EMAIL_TEMPLATE_URL = '/get-email-template';
const GET_ALL_EMAIL_TEMPLATE_URL = '/get-email-template-list';
const UPDATE_EMAIL_TEMPLATE_URL = '/update-email-template';
const DELETE_EMAIL_TEMPLATE_URL = '/remove-email-template';

const SHARE_EMAIL_TEMPLATE_URL = '/create-email-campaign';

export const createEmailTemplateApi = async (payload) => {
	const res = await create(CREATE_EMAIL_TEMPLATE_URL, payload, true);

	return res;
};
export const getEmailTemplateApi = async (payload) => {
	const res = await create(GET_EMAIL_TEMPLATE_URL, payload, true);

	return res;
};

export const getEmailTemplateListApi = async (payload) => {
	const res = await create(GET_ALL_EMAIL_TEMPLATE_URL, payload, true);

	return res;
};
export const editEmailTemplateApi = async (payload) => {
	const res = await create(UPDATE_EMAIL_TEMPLATE_URL, payload, true);

	return res;
};
export const deleteEmailTemplateApi = async (payload) => {
	const res = await create(DELETE_EMAIL_TEMPLATE_URL, payload, true);

	return res;
};

export const shareEmailTemplateApi = async (payload) => {
	const res = await create(SHARE_EMAIL_TEMPLATE_URL, payload, true);

	return res;
};
