import { call, put, takeEvery } from 'redux-saga/effects';

// Login Redux States
import {
	addUserPermissionsError,
	addUserPermissionsSuccess,
	editUserPermissionsError,
	editUserPermissionsSuccess,
	getUserPermissionsError,
	getUserPermissionsSuccess,
} from './actions';
import {
	ADD_USER_PERMISSIONS_REQUEST,
	EDIT_USER_PERMISSIONS_REQUEST,
	GET_USER_PERMISSIONS_REQUEST,
} from './actionTypes';

//Include Both Helper File with needed methods

import {
	createUserPermissionsApi,
	editUserPermissionsApi,
	getUserPermissionsApi,
} from '../../../services/auth/users/userPermissionsApi';

//If role is send successfully send mail link then dispatch redux action's are directly from here.
function* addUserPermissions({ payload: user }) {
	try {
		const response = yield call(createUserPermissionsApi, user);
		if (response && response.success) {
			yield put(addUserPermissionsSuccess(response));
			// yield put(addUserPermissionsReset());
		} else {
			throw response;
		}
	} catch (error) {
		yield put(addUserPermissionsError(error));
	}
}
function* getUserPermissions() {
	try {
		const response = yield call(getUserPermissionsApi);
		if (response && response.success) {
			yield put(getUserPermissionsSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(getUserPermissionsError(error));
	}
}
function* editUserPermissions({ payload: { role, history } }) {
	try {
		const response = yield call(editUserPermissionsApi, role);
		if (response && response.success) {
			yield put(editUserPermissionsSuccess(response));
			history.push('/view-roles');
		} else {
			throw response;
		}
	} catch (error) {
		yield put(editUserPermissionsError(error));
	}
}

function* userPermissionsSaga() {
	yield takeEvery(ADD_USER_PERMISSIONS_REQUEST, addUserPermissions);
	yield takeEvery(GET_USER_PERMISSIONS_REQUEST, getUserPermissions);
	yield takeEvery(EDIT_USER_PERMISSIONS_REQUEST, editUserPermissions);
}

export default userPermissionsSaga;
