import { create, get } from '../../common/http.service';

const CREATE_ATTRIBUTE_URL = '/create-attribute';
const GET_ATTRIBUTE_URL = '/get-attribute-list';
const UPDATE_ATTRIBUTE_URL = '/update-attribute';
const DELETE_ATTRIBUTE_URL = '/remove-attribute';

export const createAttributesApi = async (payload) => {
	const res = await create(CREATE_ATTRIBUTE_URL, payload, true);

	return res;
};
export const getAttributesApi = async (payload) => {
	const res = await create(GET_ATTRIBUTE_URL, payload, true);

	return res;
};
export const editAttributesApi = async (payload) => {
	const res = await create(UPDATE_ATTRIBUTE_URL, payload, true);

	return res;
};
export const deleteAttributesApi = async (payload) => {
	const res = await create(DELETE_ATTRIBUTE_URL, payload, true);

	return res;
};
