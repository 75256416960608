export const ADD_ATTRIBUTES_REQUEST = 'ADD_ATTRIBUTES_REQUEST';
export const ADD_ATTRIBUTES_SUCCESS = 'ADD_ATTRIBUTES_SUCCESS';
export const ADD_ATTRIBUTES_ERROR = 'ADD_ATTRIBUTES_ERROR';
export const ADD_ATTRIBUTES_RESET = 'ADD_ATTRIBUTES_RESET';

export const GET_ATTRIBUTES_REQUEST = 'GET_ATTRIBUTES_REQUEST';
export const GET_ATTRIBUTES_SUCCESS = 'GET_ATTRIBUTES_SUCCESS';
export const GET_ATTRIBUTES_ERROR = 'GET_ATTRIBUTES_ERROR';
export const GET_ATTRIBUTES_RESET = 'GET_ATTRIBUTES_RESET';

export const DELETE_ATTRIBUTES_REQUEST = 'DELETE_ATTRIBUTES_REQUEST';
export const DELETE_ATTRIBUTES_SUCCESS = 'DELETE_ATTRIBUTES_SUCCESS';
export const DELETE_ATTRIBUTES_ERROR = 'DELETE_ATTRIBUTES_ERROR';
export const DELETE_ATTRIBUTES_RESET = 'DELETE_ATTRIBUTES_RESET';

export const EDIT_ATTRIBUTES_REQUEST = 'EDIT_ATTRIBUTES_REQUEST';
export const EDIT_ATTRIBUTES_SUCCESS = 'EDIT_ATTRIBUTES_SUCCESS';
export const EDIT_ATTRIBUTES_ERROR = 'EDIT_ATTRIBUTES_ERROR';
export const EDIT_ATTRIBUTES_RESET = 'EDIT_ATTRIBUTES_RESET';
