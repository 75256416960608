import { call, put, takeEvery } from 'redux-saga/effects';
import { paymentApi } from '../../services/billing/paymentApi';

import { paymentError, paymentSuccess } from './actions';
import { PAYMENT_REQUEST } from './actionTypes';

//Include Both Helper File with needed methods

function* paymentUser({ payload: billingInfo }) {
	try {
		const response = yield call(paymentApi, billingInfo);

		if (response && response.success) {
			yield put(paymentSuccess(response));
		} else {
			throw response;
		}
	} catch (error) {
		yield put(paymentError(error));
	}
}

function* PaymentSaga() {
	yield takeEvery(PAYMENT_REQUEST, paymentUser);
}

export default PaymentSaga;
