import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { loadState } from "./localStorage";
import rootReducer from "./reducers";
import rootSaga from "./sagas";
import { loadStateFromSession } from "./sessionStorage";

const ReduxStoreConfigurator = () => {
  const persistedState = loadState();
  const persistedStateSession = loadStateFromSession();
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware];
  const composeEnhancers =
    process.env.NODE_ENV === "development"
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : null || compose;

  const configureStore = createStore(
    rootReducer,
    { ...persistedState, ...persistedStateSession },
    composeEnhancers(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(rootSaga);

  return configureStore;
};

export default ReduxStoreConfigurator;
