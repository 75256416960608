import { create, get } from '../../common/http.service';

const CREATE_PEOPLE_ATTRIBUTE_URL = '/create-people-attribute';
const GET_PEOPLE_ATTRIBUTE_URL = '/get-attribute-list';
const UPDATE_PEOPLE_ATTRIBUTE_URL = '/update-attribute';
const DELETE_PEOPLE_ATTRIBUTE_URL = '/remove-attribute';

export const createAudienceAttributesApi = async (payload) => {
	const res = await create(CREATE_PEOPLE_ATTRIBUTE_URL, payload, true);

	return res;
};
export const getAudienceAttributesApi = async (payload) => {
	const res = await create(GET_PEOPLE_ATTRIBUTE_URL, payload, true);

	return res;
};
export const editAudienceAttributesApi = async (payload) => {
	const res = await create(UPDATE_PEOPLE_ATTRIBUTE_URL, payload, true);

	return res;
};
export const deleteAudienceAttributesApi = async (payload) => {
	const res = await create(DELETE_PEOPLE_ATTRIBUTE_URL, payload, true);

	return res;
};
