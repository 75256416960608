import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import _ from "lodash";

//Layouts
import NonAuthLayout from "../Layouts/NonAuthLayout";
import VerticalLayout from "../Layouts/VerticalLayouts";

//routes
import DecryptedUser from "../common/DecryptedUser";
import { assignAllPermissions } from "../store/actions";
import { AccessRoute, AuthProtected } from "./AuthProtected";
import {
  authProtectedRoutes,
  layoutWisedRoutes,
  publicRoutes,
} from "./allRoutes";

const Index = () => {
  const userId = JSON.parse(localStorage.getItem("userID"));
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { decryptUser, userProfile } = DecryptedUser();
  const { data } = userProfile || {};

  const {
    user,
    package: userPackage,
    payment,
    package_permissions,
    permissions,
    all_permissions,
  } = data || {};
  const availablePublicRoutesPaths = publicRoutes.map((r) => r.path);
  const availableAuthRoutesPath = authProtectedRoutes.map((r) => r.path);

  const checkRouteNeedLayout = (link) => {
    let foundElement = false;

    layoutWisedRoutes.forEach((item) => {
      if (link.includes(item)) {
        foundElement = true;
      }
    });

    return foundElement;
  };

  useEffect(() => {
    if (user && !userId) {
      localStorage.setItem("userID", decryptUser.id);
    }

    if (user) {
      dispatch(
        assignAllPermissions(
          _.uniqBy(
            [...package_permissions, ...permissions, ...all_permissions],
            "id"
          )
        )
      );
    }

    if (user && Number(userPackage) > 0 && Number(payment) == 0) {
      history.push("/billing");
    }
  }, [user]);

  return (
    <>
      <Switch>
        {/* public routes */}

        <Route path={availablePublicRoutesPaths}>
          <NonAuthLayout>
            <Switch>
              {publicRoutes.map((route, idx) => (
                <Route
                  path={route.path}
                  component={route.component}
                  key={idx}
                  exact={true}
                />
              ))}
            </Switch>
          </NonAuthLayout>
        </Route>

        {/* 
				<Route path='/create-survey' exact>
					<AuthProtected>
					<SurveyCreation />
					</AuthProtected>
				</Route>
				<Route path='/survey-preview' exact>
					<AuthProtected>
					<CompleteSurveyView />
					</AuthProtected>
				</Route> */}

        {/* private routes */}
        <Route path={availableAuthRoutesPath}>
          <AuthProtected>
            {checkRouteNeedLayout(location.pathname) ? (
              // ||(location.pathname.endsWith('/audience') && location.state)

              <Switch>
                {authProtectedRoutes.map((route, idx) => (
                  <AccessRoute
                    path={route.path}
                    component={route.component}
                    key={idx}
                    exact={true}
                  />
                ))}
              </Switch>
            ) : (
              <VerticalLayout>
                <Switch>
                  {authProtectedRoutes.map((route, idx) => (
                    <AccessRoute
                      path={route.path}
                      component={route.component}
                      key={idx}
                      exact={true}
                    />
                  ))}
                </Switch>
              </VerticalLayout>
            )}
          </AuthProtected>
        </Route>
      </Switch>
    </>
  );
};

export default Index;
